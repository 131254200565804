import * as React from 'react';
import FilterCheckList from './FilterCheckList';

export interface IFilterDeparmentProps {}

export default function FilterDepartment(props: IFilterDeparmentProps) {
  const checkList = [
    {
      label: 'IM-Peds',
      value: 'im-peds'
    }
  ];

  return <FilterCheckList isCollapse title={'Deparment'} checkList={checkList} />;
}
