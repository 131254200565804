import { SVG } from 'assets/imagePath';
import { Breadcrumb } from 'components/breadcums';
import Button from 'components/button';
import { BREADCRUMB } from 'constant/breadcrumbs';
import { FC, Fragment, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IBreadcrumb } from 'types';

type Props = {
  className?: string;
};

const HeaderNotes: FC<Props> = ({ className }) => {
  return (
    <Fragment>
      <ul className="flex items-center list-none text-center gap-2.5">
        <li>
          <Button.Primary className="px-4" label="Add Note" onClick={() => {}} />
        </li>
        <li>
          <Button.White
            className="px-4"
            label="Actions"
            icon={SVG.chevron_down}
            onClick={() => {}}
          />
        </li>
        <li>
          <Button.Icon icon={SVG.search_black} variant="outlined" onClick={() => {}} />
        </li>
        <li>
          <Button.Icon icon={SVG.filter} variant="outlined" onClick={() => {}} />
        </li>

        <li>
          <Button.Icon icon={SVG.setting_table} variant="outlined" onClick={() => {}} />
        </li>
      </ul>
    </Fragment>
  );
};
HeaderNotes.displayName = 'Header.Notes';
export default memo(HeaderNotes);
