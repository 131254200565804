import Button from 'components/button';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterSetupHeader from './RegisterSetupHeader';

const RegisterSetup: FC = () => {
  const navigate = useNavigate();

  const onConfirm = () => {
    navigate('/login', { replace: true });
  };

  const onSkip = () => {
    navigate('/login', { replace: true });
  };

  const data = [
    {
      id: '1',
      command: 'Sinusitis - Viral HPI',
      text: 'Sed dictum tempor...',
      group: 'HPI'
    },
    {
      id: '2',
      command: 'Default ROS',
      text: 'Sed dictum tempor...',
      group: 'ROS'
    }
  ];

  return (
    <div className="w-screen flex flex-col items-center p-8">
      <RegisterSetupHeader
        title="Add Smart Texts!"
        subtitle="In tristique sapien odio duis sed varius vitae. Semper imperdiet pretium massa vitae at ut aliquam vivamus amet."
        canGoBack
      />

      <div className="min-w-full md:min-w-4/5 grow mt-5">
        <table className="min-w-full rounded-lg inner-border">
          <thead>
            <tr className="border-b-2">
              <th className="text-left text-label font-inter font-normal px-4 py-2 w-3">ID</th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">
                Command
              </th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">Text</th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">
                Group
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr className="border-b" key={item.id}>
                <td className="text-label font-inter text-center px-4 py-2">{item.id}</td>
                <td className="text-label font-inter px-4 py-2">{item.command}</td>
                <td className="text-label font-inter px-4 py-2">{item.text}</td>
                <td className="text-label font-inter px-4 py-2">{item.group}</td>
              </tr>
            ))}
            <tr>
              <td className="font-inter text-input cursor-pointer text-center px-4 py-2">+</td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">+ Add Command</td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">+ Add Text</td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">Select Group</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-72 mt-5 md:my-5 flex">
        <Button.White className="mr-2" label="Skip" onClick={onSkip} />

        <Button.Primary className="flex-1 ml-2" label="Confirm" onClick={onConfirm} />
      </div>
    </div>
  );
};

export default memo(RegisterSetup);
