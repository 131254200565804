import { SVG } from 'assets/imagePath';
import { Text } from 'components/text';
import * as React from 'react';
import QuestionBox from './components/QuestionBox';

export interface QuestionProps {}

export default function Question(props: QuestionProps) {
  return (
    <div className="py-[30px] md:py-[60px]">
      <Text weight="300" className="text-[32px] leading-10 mb-5 text-center">
        Frequently Asked Questions
      </Text>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px]">
        <QuestionBox
          title={'What is machine learning powered medical dictation software?'}
          content="Machine learning powered medical dictation software is a type of software that uses machine learning algorithms to automatically transcribe medical dictation. This software can recognize medical terms and phrases, and use them to accurately convert speech into text."
        />
        <QuestionBox
          title={'How does PhysicianSmart® \nNotes work?'}
          content={
            <>
              <strong>PhysicianSmart® Notes </strong>
              works by analyzing large datasets of medical terminology and speech patterns to train
              its algorithms. It can then use this knowledge to accurately transcribe spoken words
              into written text.
            </>
          }
        />
        <QuestionBox
          title={'What are the benefits of using PhysicianSmart® Notes?'}
          content={
            <>
              The benefits of <strong>PhysicianSmart® Notes</strong> include increased accuracy and
              speed in medical transcription, reduced errors, improved workflow efficiency, and
              increased productivity for medical professionals.
            </>
          }
        />
      </div>
    </div>
  );
}
