import { IMAGES } from 'assets/imagePath';
import { Text } from 'components/text';
import * as React from 'react';

export interface FooterProps {}

export default function Footer(props: FooterProps) {
  return (
    <footer className="bg-black py-[60px] px-2.5">
      <div className="max-w-[1280px] mx-auto w-full grid grid-flow-row gap-[30px] md:gap-[60px]">
        <div className="w-full grid grid-flow-row md:grid-cols-[1fr_1.5fr_1fr] gap-[30px] md:gap-[84px] items-center justify-items-center">
          <img
            src={IMAGES.landing_logo_light}
            className="max-w-[420px] md:max-w-[340px] w-full h-auto"
          />
          <Text className="text-center" color="white" size="14" weight="300">
            *We gathered data from actual users to reflect their experiences and opinions, but we
            cannot guarantee that all users will achieve similar results. Individual factors may
            affect outcomes. Our statements do not constitute an offer or contract.
          </Text>
        </div>
        <div className="max-w-[640px] mx-auto flex flex-col sm:flex-row items-center w-full gap-[15px]">
          <img src={IMAGES.app_store} className="max-w-[135px] w-full h-auto" />
          <Text className="text-center sm:text-start" color="white" size="14" weight="300">
            Apple logo, iPhone 13, MacBook Air and App Store are trademarks of Apple Inc.,
            registered in the U.S. and other countries.
          </Text>
        </div>
        <Text className="text-center" color="white" size="14" weight="300">
          Copyright ©{' '}
          <Text Component={'span'} color="white" size="14" weight="700">
            PhysicianSmart®
          </Text>{' '}
          2023
        </Text>
      </div>
    </footer>
  );
}
