import * as React from 'react';
import ClinicianDetail from './ClinicianDetail';
import ClinicianFilter from './ClinicianFilter';
import ClinicianSummary from './ClinicianSummary';

export interface IClinicianProps {}

function Clinician(props: IClinicianProps) {
  return <React.Fragment />;
}

Clinician.Detail = ClinicianDetail;
Clinician.Summary = ClinicianSummary;
Clinician.Filter = ClinicianFilter;

export default Clinician;
