import { SVG } from 'assets/imagePath';
import Button from 'components/button';
import { FC, Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
};

const HeaderNote: FC<Props> = ({ className }) => {
  return (
    <Fragment>
      <div className="w-full h-14 bg-white flex justify-between items-center border border-nav px-5 py-2.5">
        <ul className="flex items-center list-none text-center">
          <li className="px-2.5">
            <Button.Icon icon={SVG.filter} color="white" variant="contained" onClick={() => {}} />
          </li>
          <li className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label="Notes – Lead"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </li>
        </ul>

        <ul className="flex items-center list-none text-center">
          <li className="px-2.5">
            <Button.Icon icon={SVG.list} variant="outlined" onClick={() => {}} />
          </li>
          <li className="px-2.5">
            <Button.Primary className="px-4" label="Create Note" onClick={() => {}} />
          </li>
          <li className="px-2.5">
            <Button.White
              className="px-4"
              label="Actions"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </li>
          <li className="px-2.5">
            <Button.Icon icon={SVG.more_horizontal} variant="outlined" onClick={() => {}} />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
HeaderNote.displayName = 'Header.Note';
export default memo(HeaderNote);
