import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const useRegisterStep1Schema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    firstName: Yup.string()
      .nullable()
      .trim('First Name is required')
      .required('First Name is required'),
    lastName: Yup.string()
      .nullable()
      .trim('Last Name is required')
      .required('Last Name is required'),
    organizationName: Yup.string()
      .nullable()
      .trim('Organization Name is required')
      .required('Organization Name is required'),
    streetAddress: Yup.object().nullable().required('Street Address is required'),
    city: Yup.string().nullable().trim('City is required').required('City is required'),
    zipCode: Yup.string().nullable().trim('Zip Code is required').required('Zip Code is required'),
    phoneNumber: Yup.string()
      .nullable()
      .trim('Phone Number is required')
      .required('Phone Number is required')
      .matches(phoneRegExp, 'Phone Number is not valid')
  });
};

export const useRegisterStep2Schema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .nullable()
      .trim('Password is required')
      .required('Password is required')
      .min(8, 'Password must be over 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string()
      .nullable()
      .trim('Confirm Password is required')
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), ''], 'Confirm Password does not match')
  });
};

export const useRegisterStep3Schema = () => {
  return Yup.object().shape({
    code: Yup.string()
      .nullable()
      .trim('Confirmation Code is required')
      .required('Confirmation Code is required')
  });
};
