import { SVG } from 'assets/imagePath';
import Button from 'components/button';
import { Dropdown } from 'components/dropdown';
import { useAuth } from 'hooks/useAuth';
import { FC, Fragment, memo } from 'react';

type Props = {
  className?: string;
};

const HeaderSettings: FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();
  return (
    <Fragment>
      <ul className="flex justify-between items-center list-none text-center gap-2.5">
        <li className="flex-1 flex">
          <Dropdown.Standard
            CustomButton={(item) => (
              <div className="flex gap-[5px] items-center hover:bg-transparent px-[15px] py-[7.5px] border border-nav rounded-[5px]">
                <div className="">
                  <p className="text-sm">Settings - MGR</p>
                </div>
                <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
              </div>
            )}
            options={[
              {
                label: 'Settings - MGR',
                value: 'settings_mgr'
              }
            ]}
          />
        </li>
        <li className="py-[7.5px] px-2.5">
          <p className="text-xs font-bold">{currentUser?.username}</p>
        </li>
        <li className="flex-1 flex justify-end">
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </Fragment>
  );
};
HeaderSettings.displayName = 'Header.Settings';
export default memo(HeaderSettings);
