import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import { Dropdown } from 'components/dropdown';
import DropdownText from 'components/dropdown/DropdownText';
import { IFilterCheckListProps } from 'components/filterSide/FilterCheckList';
import FilterForm from 'components/filterSide/FilterForm';
import Footer from 'components/footer/Footer';
import { GridTable } from 'components/gridTable';
import Header from 'components/header/Header';
import { Text } from 'components/text';
import { SET_LOADING_MODAL } from 'constant';
import { APP_ROLE } from 'constant/auth';
import { PAGE_SIZE_OPTIONS } from 'constant/pagination';
import { DEFAULT_LIMIT } from 'constant/pagination';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getUserListAPI } from 'services';
import { checkPermission, getFormatedDate, getRowsByPage } from 'utils';

export interface ICliniciansListPageProps {}

const filters: IFilterCheckListProps[] = [
  { title: 'Organization', checkList: [{ value: 'org_wc', label: 'WC' }], isCollapse: true },
  { title: 'Time Zone', checkList: [{ value: 'tz_est', label: 'EST' }], isCollapse: true },
  { title: 'Degree', checkList: [{ value: 'degree_md', label: 'MD' }], isCollapse: true },
  { title: 'Specialty', checkList: [{ value: 'special_fm', label: 'FM' }], isCollapse: true }
];

export default function CliniciansListPage({}: ICliniciansListPageProps) {
  const form = useForm();
  const [fetchClinician, loading, clinicianList] = useApiCall(getUserListAPI);
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState();
  const [toggle, setToggle] = useState(false);

  const columns = [
    {
      field: 'clinician',
      headerName: 'Clinician',
      headerStyle: {
        minWidth: '300px'
      },
      renderCell: (row) => {
        return [row?.firstName, row?.middleName, row?.lastName].join(" ");
      }
    },
    {
      field: 'initials',
      headerName: 'Deg'
    },
    {
      field: 'organization',
      headerName: 'Org'
    },
    {
      field: 'specialty',
      headerName: 'Specialty',
    },
    {
      field: 'cellPhone',
      headerName: 'Cell Phone'
    },
    // {
    //   field: 't_zone',
    //   headerName: 'T Zone'
    // },
    // {
    //   field: 'notes',
    //   headerName: 'Notes',
    //   renderCell: (row) => {
    //     return (
    //       <Text color="blue" weight="700">
    //         ({row?.notes})
    //       </Text>
    //     );
    //   }
    // },
    {
      field: 'degree',
      headerName: 'Degree',
    },
    {
      field: 'department',
      headerName: 'Department',
      // renderCell: (row) => {
      //   const options = [{ value: row?.co, label: row?.co }];
      //   const isPermission = checkPermission([APP_ROLE.DS]);
      //   return <DropdownText options={options} />;
      // }
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'locations',
      headerName: 'Locations',
    },
    // {
    //   field: 'dsQ',
    //   headerName: 'DS?',
    //   renderCell: (row) => {
    //     return (
    //       <Text color="success" weight="700">
    //         ({row?.dsQ})
    //       </Text>
    //     );
    //   }
    // },
    // {
    //   field: 'important',
    //   headerStyle: {
    //     minWidth: '300px'
    //   },
    //   headerName: 'Important',
    //   renderCell: (row) => {
    //     return (
    //       <Text className="" color="error">
    //         {row?.important}
    //       </Text>
    //     );
    //   }
    // },
    {
      field: 'priority',
      headerName: 'Priority'
    },
    {
      field: 'dateStarted',
      headerName: 'Date Started',
      renderCell: (row) => {
        return getFormatedDate(row?.dateStarted)
      }
    },
    {
      field: 'actions',
      headerName: ' ',
      headerStyle: {
        minWidth: '50px'
      },
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-gray-active w-4 h-4 cursor-pointer"
            onClick={() => {
              navigate(`/clinician/${row?.userRecordId}`);
            }}
          />
        );
      }
    }
  ];

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(clinicianList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, clinicianList]);

  useEffect(() => {
    if (!userInfo) return;
    fetchClinician({ primaryAccountId: userInfo?.['custom:superId'], userRole: 'Clinician'});
  }, [userInfo]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    })
  }, [loading]);


  return (
    <DefaultLayout>
      <div className="flex flex-col flex-1">
        <div className="">
          <Header.Clinician toggle={toggle} setToggle={setToggle} />
        </div>
        <div className="flex flex-col flex-1 p-2.5 gap-2.5">
          <div className="flex items-center justify-between">
            <Text>{pageSize} Records</Text>
            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
                  <div className="">
                    <p className="text-xs">{item.label} Records per page</p>
                  </div>
                  <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
                </div>
              )}
              options={PAGE_SIZE_OPTIONS}
              onChangeValue={(item: any) => {
                handleChangePageSize(item?.value);
              }}
            />
          </div>
          <div className="flex flex-1 gap-2.5">
            <Box.Standard
              className={`max-w-[180px] h-auto w-full transition-all ${
                toggle ? '' : '-translate-x-[150%] fixed'
              }`}
            >
              <FormProvider {...form}>
                <FilterForm filters={filters} title={{ text: 'Filter Clinicians by' }} />
              </FormProvider>
            </Box.Standard>
            <Box.Standard className="flex-1 h-auto p-0 overflow-hidden">
              <div className="w-full overflow-auto">
                <GridTable checkboxes={false} row={rows} column={columns} />
              </div>
            </Box.Standard>
          </div>
        </div>
        <Footer
          pagination={{
            total: clinicianList?.length,
            pageSize: pageSize,
            onChangeCurrentPage: handleChangePage,
            onChangePageSize: handleChangePageSize
          }}
        />
      </div>
    </DefaultLayout>
  );
}
