import { SVG } from 'assets/imagePath';
import { Collapse } from 'components/collapse';
import Form from 'components/form/Form';
import { Search } from 'components/search';
import { useDebounce } from 'hooks/useDebounce';
import { SMARTS_NOTES } from 'pages/builder/data';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface IBuilderSmartNoteProps {
  onGetData: (dataInsight, dataSmart) => void;
}

export default function BuilderSmartNote({ onGetData }: IBuilderSmartNoteProps) {
  const form = useForm();

  const searchVal = form.watch('search_box');
  const debounceVal = useDebounce(searchVal);

  React.useEffect(() => {
    form.handleSubmit((data) => {
      onGetData(null, data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceVal]);

  return (
    <FormProvider {...form}>
      <form className="flex flex-col right-0 gap-y-2.5 ">
        <h3 className="font-bold text-sm mx-2.5 py-2.5  text-center uppercase">Smart Notes</h3>
        <div className="">
          <Form.SearchBox
            name="search_box"
            className="w-full border-[1px] border-nav border-solid  bg-transparent"
            clearInput={() => form.reset((currentValues) => ({ ...currentValues, search_box: '' }))}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' }
            }}
          />
        </div>
        {SMARTS_NOTES.map(
          (insight_it, idx) =>
            insight_it?.children && (
              <ul key={insight_it.id} className="px-2.5">
                <li className="w-full">
                  <Collapse.Text
                    disabled={insight_it.disabled}
                    defaultOpen={insight_it.defaultOpen}
                    button={(open) => (
                      <Collapse.Button
                        className={`text-black font-bold !justify-start ${
                          insight_it.disabled ? 'py-2.5' : 'py-[5px]'
                        }`}
                        classNameIcon={'w-[15px] h-[15px]'}
                        classNameText={insight_it.disabled ? 'text-sm' : ''}
                        label={insight_it.label}
                        icon={insight_it.disabled ? '' : SVG.arrow_down}
                        open={open}
                      />
                    )}
                    collapseContent={
                      <Collapse.List
                        collapseList={insight_it.children}
                        LabelComp={(item) => {
                          return (
                            <div className="flex justify-between items-center">
                              <Form.CheckboxSingle
                                name={
                                  item?.name ||
                                  `smart_${item.label.split(' ').join('_')}_${insight_it.label
                                    .split(' ')
                                    .join('_')}_${idx}`
                                }
                                label={item?.label}
                                onChangeCustom={form.handleSubmit((data) => onGetData(null, data))}
                                classNameText="!text-xs"
                              />
                              <p>{item?.note}</p>
                            </div>
                          );
                        }}
                      />
                    }
                  />
                </li>
              </ul>
            )
        )}
        <h3 className="font-bold text-sm m-2.5 text-center ">STATS-DS</h3>
        <div className="w-full grid grid-cols-3 gap-y-2.5 px-2.5 text-xs items-center">
          <div className="col-span-2 text-dark-silver">Characters</div>
          <div className="col-span-1 text-right  text-[#383838]">134</div>
          <div className="col-span-2 text-dark-silver">Smart Texts</div>
          <div className="col-span-1 text-right  text-[#383838]">3</div>
          <div className="col-span-2 text-dark-silver">Smart Phrases</div>
          <div className="col-span-1 text-right  text-[#383838]">3</div>
          <div className="col-span-2 text-dark-silver">Requests</div>
          <div className="col-span-1 text-right  text-[#383838]">6</div>
          <div className="col-span-2 text-dark-silver">QA Checks</div>
          <div className="col-span-1 text-right  text-[#383838]">1</div>
          <div className="col-span-2 text-dark-silver">EMR Transfer</div>
          <div className="col-span-1 text-right  text-[#383838]">1</div>
        </div>
      </form>
    </FormProvider>
  );
}
