import Button from 'components/button/Button';
import Form from 'components/form/Form';
import SelectMulti from 'components/input/SelectMulti';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface IConfirmDetails {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

const optionsTimeZone = [
  {
    label: 'EST',
    value: 'est'
  },
  {
    label: 'EST 1',
    value: 'est_1'
  }
];
const degreeOptions = [
  {
    label: 'MD',
    value: 'md'
  },
  {
    label: 'MD 2',
    value: 'md_2'
  }
];
const specialtyOptions = [
  {
    label: 'P - FM',
    value: 'p_fm'
  },
  {
    label: 'P - FM 2',
    value: 'p_fm2'
  }
];

export default function ConfirmDetails({ step, onClick }: IConfirmDetails) {
  const { onAccept, onDecline } = onClick;

  const form = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {}, []);

  return (
    <div className="max-w-[280px] w-full h-auto">
      <div className="w-full h-full flex flex-col  gap-[30px]">
        <h2 className="text-center text-xl font-bold">Confirm Details</h2>
        <FormProvider {...form}>
          <form className="w-full h-auto grid  grid-flow-row gap-y-5">
            <Form.TextInput name="first_name" label="First Name (Goes by)" />
            <Form.TextInput name="last_name" label="Last Name" />
            <Form.TextInput name="email" label="Email" />
            <Form.TextInput name="cell_phone" label="Cell Phone" />
            <Form.TextInput name="service_phone" label="Service Phone" />
            <Form.SelectInput options={optionsTimeZone} name="time_zone" label="Time zone" />
            <Form.SelectMulti options={degreeOptions} name="degree" label="Degree" />
            <Form.SelectMulti options={specialtyOptions} name="specialty" label="Specialty" />
          </form>
        </FormProvider>
        <div className="flex flex-row gap-5">
          <Button.White color="white" className="flex-1 py-3" label="Decline" onClick={onDecline} />

          <Button.Primary
            className="flex-1 py-3"
            label={'Accept'}
            onClick={form.handleSubmit((data) => {
              console.log(data);
              onAccept(step + 1);
            })}
          />
        </div>
      </div>
    </div>
  );
}
