import { IMAGES, SVG } from 'assets/imagePath';
import { Text } from 'components/text';
import * as React from 'react';
import cn from 'classnames';

export interface HeroProps {}

export default function Hero(props: HeroProps) {
  const hero__item =
    'flex flex-col gap-5 aspect-square max-w-[830px] xl:max-w-[630px] w-full items-center';

  return (
    <section id="hero" className="w-full pt-5 pb-2.5">
      <div className="grid w-full grid-cols-1 gap-5 mx-auto justify-items-center xl:grid-cols-2">
        <div className={hero__item}>
          <div className="p-[30px] xs:p-[45px] flex flex-col gap-[30px]  sm:gap-[45px] bg-white-light rounded-[30px]">
            <Text weight="300" className="text-lg leading-10 xs:text-2xl sm:text-3xl">
              Machine Learning Powered <br />
              Medical Dictation Software
            </Text>

            <Text
              Component={'span'}
              size="18"
              className="max-xs:text-sm max-sm:text-base leading-[30px]"
            >
              <Text
                Component={'span'}
                weight="700"
                size="18"
                className="max-xs:text-sm max-sm:text-base leading-[30px]"
              >
                PhysicianSmart® Notes{' '}
              </Text>
              is designed to accurately transcribe medical notes, enabling providers to spend more
              time with their patients and less time on administrative tasks.{' '}
            </Text>
          </div>

          <div className="w-full h-auto">
            <img className="w-full h-" src={IMAGES.hero_image} />
          </div>
        </div>

        <div className={hero__item}>
          <div className="relative w-full h-full">
            <img className="absolute bottom-0 right-0" src={IMAGES.hero_image1} />
          </div>
        </div>

        <div className={hero__item}>
          <div className="relative w-full h-full">
            <img className="w-full h-auto" src={IMAGES.hero_image2} />
          </div>
        </div>

        <div className={cn(hero__item, 'aspect-[unset] xl:aspect-square')}>
          <div className="p-[30px] lg:px-[60px] lg:py-20 flex flex-col gap-[45px] rounded-[30px]">
            <img
              className="max-w-[80px]  lg:max-w-[100px] w-full h-auto"
              src={IMAGES.fontisto_quote_left}
            />

            <div className="flex flex-col gap-5">
              <Text
                Component={'p'}
                weight="200"
                size="20"
                className="max-xxs:text-sm max-xs:text-base max-sm:text-lg sm:leading-[35px]"
              >
                I use PhysicianSmart Notes in my practice and it has helped me to capture more
                accurate patient records that have helped me to increase my billing rates by
                <Text
                  className="max-xxs:text-sm max-xs:text-base max-sm:text-lg  sm:leading-[35px]"
                  Component={'span'}
                  weight="700"
                  color="primary"
                  size="20"
                >
                  {' '}
                  40%
                </Text>{' '}
                this year. I no longer spend endless nights reviewing EMR records.
              </Text>

              <div className="flex flex-row gap-[5px]">
                {Array.from({ length: 5 }).map((_, idx) => (
                  <img key={idx} src={SVG.icon_star_gold} />
                ))}
              </div>
              <div className="flex flex-row gap-[15px] mt-2.5 items-center">
                <img className="w-[50px]" src={IMAGES.avatar_doctor} />
                <div>
                  <Text size="16" weight="700">
                    Scarlet Wood
                  </Text>
                  <Text size="14" color="secondary">
                    Scarlet Wood
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
