import * as React from 'react';

export interface IDocAssistantSidebarProps {}

export default function DocAssistantSidebar(props: IDocAssistantSidebarProps) {
  return (
    <div>
      <div className="p-2.5">
        <h3 className="font-bold text-xs mb-[5px]">Clinician Information</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p>Contact Info</p>
          <p>Contact Details</p>

          <p>
            Comments <strong>(1)</strong>
          </p>

          <p>
            Attachments <strong>(3)</strong>
          </p>

          <p>
            Open Notes<strong>(12)</strong>
          </p>
          <p>
            Done Notes<strong>(25)</strong>
          </p>
        </div>
      </div>
      <div className="p-2.5">
        <h3 className="font-bold text-xs mb-[5px]">Links</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p>SC – EMR</p>
          <p>PA – EMR</p>

          <p>HHS – EMR</p>
          <p>My Email</p>
          <p>Coding Asst </p>
          <p>Transcribe</p>
        </div>
      </div>
    </div>
  );
}
