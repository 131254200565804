import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { ButtonIconProps } from 'components/button/ButtonIcon';
import * as React from 'react';
import { HiPause, HiPlay } from 'react-icons/hi2';

export interface IPlayPauseProps {
  defaultPlay?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  buttonStyle?: ButtonIconProps;
  isPlaying: boolean;
}

function PlayPause({
  isPlaying,
  onPlay = () => {},
  onPause = () => {},
  buttonStyle
}: IPlayPauseProps) {
  const handleChangePlay = () => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  };
  return (
    <Button.Icon
      variant="outlined"
      classNameIcon="w-[15px] h-[15px] object-cover"
      className={`!py-[5px] !px-[12.5px] hover:bg-grey ${isPlaying ? 'bg-grey' : ''}`}
      {...buttonStyle}
      icon={isPlaying ? HiPause : HiPlay}
      onClick={handleChangePlay}
    />
  );
}

export default React.memo(PlayPause);
