import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import * as React from 'react';
import SelectedButton from './components/SelectedButton';
import { Text } from 'components/text';
import { RouterPath } from 'routes/router.constant';
import { useNavigate } from 'react-router-dom';

export interface IHeaderClinicianProps {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
}

export default function HeaderClinician({ toggle, setToggle }: IHeaderClinicianProps) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between">
      <ul className="flex max-w-[400px] w-full items-center list-none justify-between text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="max-w-[15px] "
            icon={toggle ? SVG.filter : SVG.unfilter}
            variant="outlined"
            className="bg-[#f3f3f3] p-[7.5px]"
            // onClick={() => {}}
            onClick={() => setToggle(!toggle)}
          />
        </li>
        <li className="flex items-center gap-2.5">
          <div>
            <SelectedButton
              propsButton={{ variant: 'outlined', color: 'secondary', className: 'px-[15px]' }}
              options={[
                { value: 'mrg', label: 'Clinician List - MGR' },
                { value: 'cod', label: 'Clinician List - COD' }
              ]}
            />
          </div>
          <Text
            Component="button"
            size="12"
            weight="700"
            onClick={() => navigate(RouterPath.INQUIRIES)}
          >
            Inquires (3)
          </Text>
        </li>
      </ul>
      <div className="items-center justify-center flex-1 hidden text-xs font-bold text-black lg:flex">
        <Text weight="700">Betty MacDonald – All Clinicians</Text>
      </div>

      <ul className="flex max-w-[400px] w-full items-center justify-end list-none text-center gap-2.5">
        <li>
          <Text className="whitespace-nowrap">Betty MacDonald – MRG</Text>
        </li>

        <li>
          <Button.Common variant="outlined" color="secondary" onClick={() => navigate('/clinician/edit/new')}>
            Add Clinician
          </Button.Common>
        </li>

        <li>
          <SelectedButton
            propsButton={{ variant: 'outlined', color: 'secondary', className: 'px-[15px]' }}
            options={[
              { value: 'actions', label: 'Actions' },
              { value: 'inquires1', label: 'Inquires - MRG1' }
            ]}
          />
        </li>
        <li>
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </div>
  );
}
