import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { IButtonCommonProps } from 'components/button/ButtonCommon';
import { ButtonWhiteType } from 'components/button/ButtonWhite';
import { Dropdown } from 'components/dropdown';
import * as React from 'react';

export interface ISelectedButtonProps {
  options: any[];
  propsButton?: object;
}

export default function SelectedButton({ options, propsButton }: ISelectedButtonProps) {
  return (
    <Dropdown.Standard
      options={options}
      CustomButton={(val) => (
        <Button.Common {...propsButton}>
          <div className="flex flex-start gap-[5px] items-center">
            <p>{val.label}</p>
            <img src={SVG.chevron_down} />
          </div>
        </Button.Common>
      )}
    />
  );
}
