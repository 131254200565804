import * as React from 'react';

export interface IClinicianFilterProps {}

function ClinicianFilter(props: IClinicianFilterProps) {
  return (
    <div className="flex flex-col gap-y-2.5">
      <div className="px-2.5">
        <h3 className="font-bold text-xs py-[5px]">Clinician information</h3>
        <div className="flex flex-col text-xs gap-y-[5px]">
          <p>Clinician Details</p>
          <p>
            Comments <strong>(1)</strong>
          </p>

          <p>
            Attachments <strong>(3)</strong>
          </p>

          <p>Smart Text</p>
          <p>Standard ? Answers</p>
          <p>Staff Assignments</p>

          <p>
            Open Notes <strong>(12)</strong>
          </p>
          <p>
            Done Notes <strong>(25)</strong>
          </p>
        </div>
      </div>

      <div className="px-2.5">
        <h3 className="font-bold text-xs py-[5px]">Settings</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p>Device Settings</p>
          <p>App Settings</p>

          <p>Audio Settings</p>
        </div>
      </div>

      <div className="px-2.5">
        <h3 className="font-bold text-xs py-[5px]">Analytics</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p>Dashboard Stats</p>
          <p>Pattern of Practice</p>

          <p>Productivity Stats</p>
          <p>Quality Issues</p>
        </div>
      </div>

      <div className="px-2.5">
        <h3 className="font-bold text-xs py-[5px]">Links</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p>SC – EMR</p>
          <p>PA – EMR</p>

          <p>HHS – EMR</p>
          <p>My Email</p>
          <p>Coding Asst </p>
          <p>Transcribe</p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ClinicianFilter);
