import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import { Note } from 'components/note';
import NoteComment from 'components/note/NoteComment';
import NoteSideBar from 'components/note/NoteSideBar';
import Organization from 'components/organizations';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { getOrganizationAPI } from 'services';

export interface IOrganizationDetailProps {}

export default function OrganizationDetail({}: IOrganizationDetailProps) {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const { organizationId } = useParams();
  const [fetchOrg, loading, orgData] = useApiCall(getOrganizationAPI);

  useEffect(() => {
    if (!organizationId || !userInfo) return;
    fetchOrg({ primaryAccountId: userInfo?.['custom:superId'], corporateRecordId: organizationId });
  }, [userInfo, organizationId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  console.log('orgData', orgData);

  const [orgDetail, setOrgDetail] = React.useState<object | any>({});
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: `${orgData?.orgFullName || ''} - All Org Details`,
          addLabel: 'Add Clinician',
          labelDetail: 'Org Details - MGR'
        }}
        onEdit={() => navigate(`/organizations/edit/${organizationId}`)}
        onAdd={() => navigate(`/clinician/edit/new`)}
      />

      <div className="w-full flex flex-col flex-1 justify-center items-center p-2.5">
        <div className="w-full flex justify-between">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.ORGANIZATIONS, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
            <p>{orgData?.orgFullName || ''}</p>
          </div>
          <p className="text-right self-end text-xs">Last Update: 23 hrs – 40 Min Ago</p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <NoteSideBar />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full  flex-1 gap-2.5">
            <Box.Standard className="h-auto">
              <Organization.Summary orgData={orgData}/>
            </Box.Standard>
            <Box.Standard className="h-full w-full px-[60px] py-[30px]">
              <Organization.Detail orgData={orgData} />
            </Box.Standard>
            <Box.Standard className="h-full p-[15px]">
              <NoteComment title="Org Comments MGRS" />
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
}
