import React from 'react';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import SelectInput from './SelectInput';
import SelectMulti from './SelectMulti';
import TextInput from './TextInput';

const Input = () => {
  return <React.Fragment />;
};

Input.Text = TextInput;

Input.Email = EmailInput;

Input.Phone = PhoneInput;

Input.Password = PasswordInput;

Input.Select = SelectInput;

Input.SelectMulti = SelectMulti;

export default Input;
