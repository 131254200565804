import * as React from 'react';
import FilterCheckList from './FilterCheckList';

export interface IFilterOtherDepartmentProps {}

export default function FilterOtherDepartment(props: IFilterOtherDepartmentProps) {
  const checkList = [
    {
      label: 'Orthobiologics',
      value: 'orthobiologics'
    }
  ];

  return <FilterCheckList isCollapse title={'Other Department'} checkList={checkList} />;
}
