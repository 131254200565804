import type { FC } from "react";
import cn from "classnames";
import LoadingGif from 'assets/images/load.gif';

interface LoadingProps {
  className?: string;
}

const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <div
      role="status"
      className={cn(
        "flex items-center justify-center w-full h-full",
        className
      )}
    >
      <img src={LoadingGif} className="w-full h-full"/>
    </div>
  );
};

export default Loading;
