import { IMAGES } from 'assets/imagePath';
import Button from 'components/button/Button';
import { Text } from 'components/text';
import * as React from 'react';
import ButtonRequest from './components/ButtonRequest';

export interface IntroduceProps {}

export default function Introduce(props: IntroduceProps) {
  return (
    <section id="introduce" className="w-full">
      <div className="bg-black rounded-[30px] py-[45px] px-5 sm:px-[45px] lg:px-[90px]">
        <div className="flex flex-col md:flex-row items-center gap-[30px]">
          <div className="max-w-full md:max-w-[300px] xl:max-w-[600px] w-full h-auto">
            <img src={IMAGES.request_demo_laptop} className="w-full h-auto" />
          </div>
          <div className="flex-1 p-[30px] flex flex-col gap-5">
            <Text color="white" className="text-[32px] leading-10">
              Cross-platform tool tailored for healthcare professionals
            </Text>
            <Text className="leading-[30px]" color="white" size="18">
              With a user-friendly interface and customizable options, our platform is tailored to
              meet the specific needs of healthcare professionals. Our commitment to data security
              and privacy ensures that sensitive patient information is protected at all times.
              Experience the benefits of our machine learning-powered medical dictation software and
              streamline your medical documentation process today.
            </Text>

            <ButtonRequest className="mt-2.5" />
          </div>
        </div>
      </div>
    </section>
  );
}
