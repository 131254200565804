import { SVG } from 'assets/imagePath';
import Button from 'components/button';
import { useNavigateSearch } from 'hooks';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../auth';

const RegisterStep4: FC = () => {
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();

  const onConfirm = () => {
    navigateSearch('/register', { step: '4', setup: 'true' });
  };

  const onSkip = () => {
    navigate('/login', { replace: true });
  };

  return (
    <div className="max-w-[280px] w-full h-full  mx-auto flex flex-col justify-center items-center ">
      <Auth.Header
        icon={SVG.register_smart_texts}
        title="Setup SmartNotes!"
        subtitle="Dignissim amet faucibus non diam nisi ridiculus risus eget. Sodales ipsum donec morbi non facilisis. Aliquam morbi est."
        canGoBack
      />
      <div className="w-full mt-5 flex">
        <Button.White className="mr-2" label="Skip" onClick={onSkip} />
        <Button.Primary className="flex-1 ml-2" label="Confirm" onClick={onConfirm} />
      </div>
    </div>
  );
};

export default memo(RegisterStep4);
