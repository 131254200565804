import { SVG } from 'assets/imagePath';
import * as React from 'react';
import cn from "classnames";

export interface IButtonRender {
  className?: string;
  classNameText?: string;
  classNameIcon?: string;
  icon?: string;

  label: React.ReactNode;
  open?: boolean;
  positionIcon?: 'start' | 'end';
}

const ButtonText = ({
  icon,
  positionIcon = 'end',
  className,
  classNameIcon,
  label,
  open,
  classNameText
}: IButtonRender) => {
  return (
    <h3 className={cn('flex justify-between items-center', className)}>
      {icon && positionIcon === 'start' && (
        <img
          src={SVG.chevron_down}
          className={cn(
            'object-contain  ui-open:transform',
            open ? '' : '-rotate-180',
            classNameIcon
          )}
        />
      )}

      <p className={cn('font-semibold text-xs', classNameText)}>{label}</p>

      {icon && positionIcon === 'end' && (
        <img
          src={SVG.chevron_down}
          className={cn(
            'object-contain  ui-open:transform',
            open ? '' : '-rotate-180',
            classNameIcon
          )}
        />
      )}
    </h3>
  );
};

export interface ICollapseButtonProps {
  type?: 'text';
  open?: boolean;
}

export default function CollapseButton({
  type = 'text',
  open,
  ...rest
}: ICollapseButtonProps & IButtonRender) {
  let RenderButton = ButtonText;
  switch (type) {
    case 'text':
      RenderButton = ButtonText;
      break;
    default:
      RenderButton;

    // code block
  }
  return <RenderButton open={open} {...rest} />;
}
CollapseButton.displayName = 'Collapse.Button';
