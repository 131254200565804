import { Text } from 'components/text';
import * as React from 'react';
import ButtonRequest from './components/ButtonRequest';

export interface NoteProps {}

export default function Note(props: NoteProps) {
  return (
    <div className="p-[30px] md:py-[60px] bg-white-light md:px-5 my-2.5 rounded-[30px]">
      <div className="max-w-[640px] w-full mx-auto text-center">
        <Text className="text-center text-xl sm:text-3xl md:text-[32px]">
          Try <strong className="block md:inline-block">PhysicianSmart® Notes</strong>
        </Text>
        <Text responsive className="text-center my-[15px] md:mt-[20px] md:mb-[30px]" size="18">
          Say goodbye to tedious documentation tasks and hello to more time with your patients. Sign
          up for a FREE Demo and see how our software can streamline your medical documentation
          process, save you time, and improve the accuracy of your patient records.
        </Text>
        <ButtonRequest className="mx-auto max-md:w-full" />
      </div>
    </div>
  );
}
