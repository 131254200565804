import Button from 'components/button/Button';
import Form from 'components/form/Form';
import SelectMulti from 'components/input/SelectMulti';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface ICreatePassword {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

export default function CreatePassword({ step, onClick }: ICreatePassword) {
  const { onAccept, onDecline } = onClick;

  const form = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {}, []);

  return (
    <div className="max-w-[280px] w-full h-auto">
      <div className="w-full h-full flex flex-col  gap-[30px]">
        <h2 className="text-center text-xl font-bold">Create Password</h2>
        <FormProvider {...form}>
          <form className="w-full h-auto grid  grid-flow-row gap-y-5">
            <Form.TextInput name="password" inputProps={{ placeholder: 'Password' }} />
            <Form.TextInput name="confirm_pw" inputProps={{ placeholder: 'Confirm password' }} />
          </form>
        </FormProvider>
        <div className="flex flex-row gap-5">
          <Button.Primary
            className="flex-1 py-3"
            label={'Create'}
            onClick={form.handleSubmit((data) => {
              console.log(data);
              onAccept(step + 1);
            })}
          />
        </div>
      </div>
    </div>
  );
}
