import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import EditableVerticalTable from 'components/gridTable/EditableVerticalTable';
import { HeaderApp } from 'components/header';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { fakeDataOrganization } from './data';
import { ITableColumn } from 'types';
import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'components/dropdown';
import Form from 'components/form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GeoSuggest } from 'components/geoSuggestion';
import cn from 'classnames';
import { useApiCall, useSelectBillToSuggestion } from 'hooks';
import { GlobalContext } from 'context/GlobalContext';
import { SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import { createOrganizationAPI, getOrganizationAPI, updateOrganizationAPI } from 'services';

export interface IOrganizationEditProps {}

export default function OrganizationEdit({}: IOrganizationEditProps) {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  // const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [fetchOrg, loading, orgDetail] = useApiCall(getOrganizationAPI);
  const isNew = organizationId?.toLowerCase() === 'new' ? true : false;
  const form = useForm({});
  const userBillingAddress = useSelectBillToSuggestion(form.watch('addressSuggestion')?.label);

  useEffect(() => {
    if (!organizationId || organizationId?.toLowerCase() === 'new') return;
    fetchOrg({ primaryAccountId: userInfo?.['custom:superId'], corporateRecordId: organizationId });
  }, [organizationId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  useEffect(() => {
    form.setValue('streetAddress', userBillingAddress?.streetAddress);
    form.setValue('addressLine2', userBillingAddress?.streetAddressLine2);
    form.setValue('stateOf', userBillingAddress?.stateOf);
    form.setValue('city', userBillingAddress?.city);
    form.setValue('zipCode', userBillingAddress?.zipCode);
    form.setValue('country', userBillingAddress?.country);
  }, [userBillingAddress]);

  useEffect(() => {
    if (!orgDetail) return;
    Object.keys(orgDetail).forEach((key) => {
      if (key === 'dateStarted') {
        form.setValue(key, new Date(orgDetail?.[key]));
        return;
      }
      form.setValue(key, orgDetail?.[key]);
    });
  }, [orgDetail]);

  const ColumnOrgDetail: ITableColumn[] = [
    {
      field: 'orgFullName',
      headerName: 'Org Full Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'primaryOrgName',
      headerName: 'Primary Organization Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
      // renderCell(row) {
      //   return (
      //     <Form.SelectInput
      //       defaultValue={{ label: 'SC', value: 'sc' }}
      //       options={[{ label: 'SC', value: 'sc' }]}
      //       name="primary_org_name"
      //       text
      //     />
      //   );
      // }
    },
    {
      field: 'orgShortName',
      headerName: 'Org Short Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'orgInitials',
      headerName: 'Org Initials',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'orgNumber',
      headerName: 'Org #',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'department',
      headerName: 'Department',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
      // renderCell(row) {
      //   return (
      //     <Form.SelectInput
      //       defaultValue={{ label: row?.department, value: 'sc' }}
      //       options={[{ label: row?.department, value: 'sc' }]}
      //       name="primary_org_name"
      //       text
      //     />
      //   );
      // }
    }
  ];
  const ColOrgAddress: ITableColumn[] = [
    {
      field: 'orgLocation',
      headerName: 'Org Location',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'primaryLocation',
      headerName: 'Primary Location',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
      //       renderCell(row) {
      //         return (
      //           <Form.SelectInput
      //             defaultValue={{ label: row?.primary_location, value: 'sc' }}
      //             options={[{ label: row?.primary_location, value: 'sc' }]}
      //             name="primary_location;
      // "
      //             text
      //           />
      //         );
      //       }
    },
    {
      field: 'billing_address',
      headerName: 'Billing address',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell(row) {
        return (
          <GeoSuggest
            placeholder="Street Address"
            inputClassName={cn(
              'test z-0 text-sm font-inter text-input placeholder:text-slate-400 bg-inherit bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-inherit ring-nav outlined-none !p-1 border-none ring-1 w-full h-full rounded-[0px] !shadow-none'
            )}
            onSuggestSelect={(suggestion) => {
              form.setValue('addressSuggestion', suggestion);
            }}
            name="billing_address"
            id="billing_address"
          />
        );
      }
    },
    {
      field: 'orgAdminContact',
      headerName: 'Administrator name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'adminPhone',
      headerName: 'Admin phone',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'adminEmail',
      headerName: 'Admin email',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    }
  ];
  const ColOther: ITableColumn[] = [
    {
      field: 'otherOrgName',
      headerName: 'Other Organization Names',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'otherDepartment',
      headerName: 'Other Department',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
      // renderCell(row) {
      //   return (
      //     <Form.SelectInput
      //       defaultValue={{ label: row?.other_department, value: 'sc' }}
      //       options={[{ label: row?.other_department, value: 'sc' }]}
      //       name="other_department"
      //       text
      //     />
      //   );
      // }
    },
    {
      field: 'emrSystem',
      headerName: '*EMR System',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
      // renderCell(row) {
      //   return (
      //     <Form.SelectInput
      //       defaultValue={{ label: row?.emr_system, value: 'sc' }}
      //       options={[{ label: row?.emr_system, value: 'sc' }]}
      //       name="emr_system"
      //       text
      //     />
      //   );
      // }
    },
    {
      field: 'dateStarted',
      headerName: '*Date Started',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell(row) {
        return (
          <Form.DatePicker
            name="dateStarted"
            inputProps={{ id: 'dateStarted', placeholder: '' }}
            date={form?.watch('dateStarted')}
            onSelect={(value: Date) => {
              form.setValue('dateStarted', value);
            }}
          />
        );
      }
    },
    {
      field: 'timeZone',
      headerName: '*Time Zone UTC',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell(row) {
        return (
          <Form.SelectInput
            defaultValue={TIMEZONE_OPTIONS[0]}
            options={TIMEZONE_OPTIONS}
            name="timeZone"
            className="w-full"
            text
          />
        );
      }
    }
    // {
    //   field: 'attach_func',
    //   headerName: 'Attach function',
    //   headerStyle: { textAlign: 'center' },
    //   rowStyle: {
    //     justifyContent: 'center'
    //   }
    // }
  ];

  const [isEdit, setIsEdit] = useState(isNew ? true : false);
  const titleOpts = [
    { label: `${isNew ? 'New' : 'Edit'} Organization Details - MGR`, value: 'title-1' }
  ];
  const actionOpts = [
    { label: 'Edit', value: 'edit', onClick: () => setIsEdit(true) },
    { label: 'Delete', value: 'delete', onClick: () => setIsEdit(true) }
  ];

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true
      });
      if (isNew) {
        await createOrganizationAPI(data);
      } else {
        await updateOrganizationAPI({
          body: data,
          organizationId: organizationId,
          primaryAccountId: userInfo?.['custom:superId']
        });
      }
      // setIsEdit(false);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
      navigate(`/organization/${organizationId}`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
    }
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
        saveDisabled={!isEdit}
      />
      <Box.Standard className="flex-1 my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <EditableVerticalTable
              isEdit={isEdit}
              columns={ColumnOrgDetail}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={isEdit}
              columns={ColOrgAddress}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={isEdit}
              columns={ColOther}
              row={!isNew && form.getValues()}
            />
          </form>
        </FormProvider>
      </Box.Standard>

      <Footer />
    </DefaultLayout>
  );
}
