import { Tooltip } from 'react-tooltip';
import { ITableColumn } from 'types';

export const ORGANIZATION_COLUMNS: ITableColumn[] = [
  {
    field: 'orgFullName',
    headerName: 'Organization',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'orgInitials',
    headerName: 'Org Initials',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'orgShortName',
    headerName: 'Org Short Name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'department',
    headerName: 'Department',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  
  {
    field: 'orgLocation',
    headerName: 'Location',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'orgAdminContact',
    headerName: 'Admin name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'adminPhone',
    headerName: 'Admin Phone',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  {
    field: 'adminEmail',
    headerName: 'Admin Email',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center'
    }
  },
  // {
  //   field: 'actions',
  //   headerName: ' '
  //   //   renderCell: (row) => {
  //   //     return (
  //   //       <>
  //   //         <img
  //   //           id={`tooltip_plus_${row.id}`}
  //   //           src={SVG.plus}
  //   //           alt="co-icon"
  //   //           className="icon-app cursor-pointer min-w-[17px]"
  //   //           // onClick={() => {
  //   //           //   navigate(`/note/${row?.id}`);
  //   //           // }}
  //   //         />
  //   //         <Tooltip anchorId={`tooltip_plus_${row.id}`} content="Note Detail" />
  //   //       </>
  //   //     );
  //   //   }
  // }
];
