import { Drawer } from 'components/drawer';
import { BREAKPOINT } from 'constant/breakpoints';
import { NAV_LINKS } from 'constant/navbar';
import { useViewport } from 'context/ViewportContext';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import cn from "classnames";
import { IDrawerState } from 'types';
import NavBarLink from './NavBarLink';
import NavBarMore from './NavBarMore';

export interface INavNavigationProps {}

const NavNavigationDesktop = () => {
  return (
    <>
      {NAV_LINKS.map((nav, idx) => {
        return <NavBarLink key={idx} to={nav.to} label={nav.label} />;
      })}
      <NavBarMore />
    </>
  );
};
const NavNavigationMobile = ({ setDrawer }) => {
  return (
    <>
      <NavBarMore
        onClick={() =>
          setDrawer(() => ({
            Component: (
              <div className="flex flex-col  mt-14 -mx-2.5 min-w-[300px]">
                {NAV_LINKS.map((nav, idx) => {
                  return (
                    <NavLink
                      to={nav.to}
                      className={({ isActive }) =>
                        cn(
                          'h-full p-2.5 flex items-center text-black text-sm font-semibold font-inter hover:bg-nav',
                          isActive ? 'bg-nav' : ''
                        )
                      }
                    >
                      {nav.label}
                    </NavLink>
                  );
                })}
              </div>
            ),
            open: true,
            position: 'left'
          }))
        }
      />
    </>
  );
};

export default function NavNavigation({ ...props }: INavNavigationProps) {
  const { width } = useViewport();

  const [drawer, setDrawer] = React.useState<IDrawerState>({
    Component: <></>,
    position: 'left',
    open: false
  });

  const renderNavTools = () => {
    if (width < BREAKPOINT['xl']) {
      return <NavNavigationMobile setDrawer={setDrawer} {...props} />;
    } else {
      return <NavNavigationDesktop {...props} />;
    }
  };

  return (
    <>
      {renderNavTools()}
      <Drawer
        handleClose={() => setDrawer((prev) => ({ ...prev, open: false }))}
        open={drawer.open}
        position={drawer.position}
        DrawerContent={drawer.Component}
      />
    </>
  );
}
