import InlineHtml from 'components/inlineHtml/InlineHtml';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { useClickOutside } from 'hooks';
import Button from 'components/button/Button';
export interface IBuilderContentProps {
  html: string;
  onSave?: (html) => void;
}

export default function BuilderContent({ html, onSave }: IBuilderContentProps) {
  const [isEditor, setIsEditor] = React.useState(false);
  const ref = React.useRef();
  const lastHtml = React.useRef(html);
  const [htmlQuill, setHtmlQuill] = React.useState<string>(html);

  return (
    <div>
      {isEditor ? (
        <div ref={ref}>
          <ReactQuill
            theme="snow"
            value={htmlQuill}
            onChange={(string) => {
              setHtmlQuill(string);
            }}
          />
          <div className="flex gap-x-2 mt-2 justify-end">
            <Button.White
              className="w-fit"
              label="Cancel"
              onClick={() => {
                setIsEditor(false);
                setHtmlQuill(lastHtml.current);
              }}
            />
            <Button.Primary
              className="w-fit"
              label="Save"
              onClick={() => {
                onSave && onSave(htmlQuill);
                setIsEditor(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            lastHtml.current = htmlQuill;
            setIsEditor(true);
          }}
        >
          <InlineHtml html={htmlQuill} />
        </div>
      )}
    </div>
  );
}
