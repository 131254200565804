import { Auth } from "aws-amplify";
import { SET_USER_INFO } from "constant";
import { GlobalContext } from "context/GlobalContext";
import Login from "pages/auth/login";
import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export const RequiredAuth: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [isAuth, setIsAuth] = useState(false);

  const checkAuth = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      console.log("authenticatedUser", authenticatedUser?.attributes)
      if (authenticatedUser) {
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes
        })
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      setIsAuth(false);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  if (isAuth) {
    return (
      <>
        <Outlet />
      </>
    ); 
  }

  return <Login />;
};
