import { ErrorMessage } from '@hookform/error-message';
import { DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ObjectUtils } from 'utils';
import { Input } from '../input';

type Props = {
  className?: string;
  name: string;
  note?: string;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};

const FormPasswordInput: FC<Props> = ({ className, name, note, inputProps }) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: ''
  });

  return (
    <div className={className}>
      <Input.Password
        {...inputProps}
        error={!ObjectUtils.isEmpty(ObjectUtils.get(formState.errors, name))}
        value={field?.value}
        onChange={field?.onChange}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => <p className="text-error text-xs font-inter my-1">{message}</p>}
      />

      {!ObjectUtils.isEmpty(note) && (
        <p className="w-full text-input text-xs font-inter my-1">{note}</p>
      )}
    </div>
  );
};

FormPasswordInput.displayName = 'Form.PasswordInput';

export default memo(FormPasswordInput);
