import { SVG } from 'assets/imagePath';
import Form from 'components/form/Form';
import { Text } from 'components/text';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import FilterCheckList, { IFilterCheckListProps } from './FilterCheckList';

export interface IFilterFormProps {
  title: {
    className?: string;
    text: string;
  };

  formProps?: {
    search?: {
      name?: string;
      inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    };
  };
  filters: IFilterCheckListProps[];
}

const defaultSearch = {
  name: 'search_box',
  inputProps: {
    placeholder: 'Search',
    style: { borderStyle: 'solid' }
  }
};

// Please pass this component into a <FormProvider />
export default function FilterForm({
  formProps = {
    search: defaultSearch
  },
  filters,
  title
}: IFilterFormProps) {
  const form = useFormContext();

  return (
    <form className="flex flex-col gap-2.5">
      <div className="px-2.5 py-[5px] flex items-center justify-between">
        <Text className={title.className} Component="h4" weight="700">
          {title.text}
        </Text>
        <img src={SVG.unfilter} />
      </div>
      <Form.SearchBox
        name={formProps?.search?.name}
        className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
        clearInput={() => form.resetField(formProps?.search?.name)}
        inputProps={formProps?.search?.inputProps}
      />
      {filters.map((filter, idx) => (
        <div className="px-2.5 ">
          <FilterCheckList title={filter.title} checkList={filter.checkList} {...filter} />
        </div>
      ))}
    </form>
  );
}
