import { IDetailRow } from 'components/detail/DetailBox';

interface Detail {
  [key: string]: (arg: any) => IDetailRow[];
}

export const DC_DETAILS: Detail = {
  detailInfo: (data) => [
    {
      subTitle: 'First Name',
      className: {
        classNameContent: 'font-bold'
      },
      content: data?.firstName || '-'
    },
    {
      subTitle: 'Last Name',
      className: {
        classNameContent: 'font-bold'
      },
      content: data?.lastName || '-'
    },
    {
      subTitle: 'Initials',
      className: {
        classNameContent: 'font-bold'
      },
      content: data?.initials || '-'
    },
    {
      subTitle: 'Nickname',
      className: {
        classNameContent: 'font-bold'
      },
      content: data?.nickName || '-'
    }
  ]
};
