import { IBreadcrumb } from 'types';
export const BREADCRUMB: any[] = [
  {
    url: '/notes',
    breadcrumbs: [{ id: 'notes', url: '/notes', label: 'Note List' }]
  },
  {
    url: '/note',
    breadcrumbs: [
      { id: 'notes', url: '/notes', label: 'Notes' },
      { id: 'note', url: '/note', label: 'Note #1257' }
    ]
  },
  {
    url: '/doc-assistants',
    breadcrumbs: [{ id: 'doc-assistants', url: '/doc-assistants', label: 'Doc Assistants' }]
  },
  {
    url: '/doc-assistant',
    breadcrumbs: [{ id: 'doc-assistant', url: '/doc-assistant', label: 'Doc Assistant' }]
  },
  {
    url: '/coders',
    breadcrumbs: [{ id: 'coders', url: '/coders', label: 'Coders' }]
  },
  {
    url: '/builder',
    breadcrumbs: [{ id: 'builder', url: '/builder', label: 'Note Builder' }]
  }
];
