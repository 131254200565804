import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { API } from 'aws-amplify';
import Button from 'components/button';
import Form from 'components/form';
import { GeoSuggest } from 'components/geoSuggestion';
import { config } from 'config';
import { SET_REGISTER_FORM } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useNavigateSearch, useSelectBillToSuggestion } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { useRegisterStep1Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cn from "classnames";
import { IRegisterStep1FormValues } from 'types';
import { regexAccountId } from 'utils';
import { capitalizeName } from 'utils/text';
import { Auth } from '../auth';

const RegisterStep1: FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const form = useForm<IRegisterStep1FormValues>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      approvalCode: ''
    },
    resolver: FormResolver(useRegisterStep1Schema())
  });

  const navigateSearch = useNavigateSearch();

  const isExistingAccountId = async (primaryAccountId, approvalCode) => {
    let userRole = '';
    try {
      const apiName = config.API_NAME;
      const apiPath = `/users/validate/${primaryAccountId}`;
      const result = await API.get(apiName, apiPath, {});
      if (approvalCode !== config.APPROVAL_CODE) {
        if (result.data.primaryAccountId === primaryAccountId) {
          // return true if entry found that matches account id in state for new user
          // then role is employee
          userRole = 'Clinician';
        } else {
          // return false if no entry found that matches account id in state for new user
          // if False then this is an Admin for first account
          // reality will fail and set state in catch
          userRole = 'Organization_Admin';
        }
      } else {
        if (result.data.primaryAccountId === primaryAccountId) {
          // return true if entry found that matches account id in state for new user
          // then role is employee
          userRole = 'Manager';
        } else {
          // return false if no entry found that matches account id in state for new user
          // if False then this is an Admin for first account
          // reality will fail and set state in catch
          userRole = 'System_Admin';
        }
      }
      return userRole;
    } catch (err) {
      console.log(err);
      userRole = 'System_Admin';
      return userRole;
    }
  };

  const onSubmit = async (values: IRegisterStep1FormValues) => {
    const primaryAccountId = (regexAccountId(values.organizationName) + userBillingAddress?.zipCode)
      .replace(/[ ,.:;'"`~$%^&*()!@#/?><|{}]/g, '')
      .toLowerCase();
    const userRole = await isExistingAccountId(
      primaryAccountId,
      values.approvalCode
    );
    dispatch({
      type: SET_REGISTER_FORM,
      payload: {
        username: values.email.toLowerCase(),
        userBillingAddress: userBillingAddress,
        attributes: {
          'custom:userRole': userRole,
          'custom:superId': primaryAccountId,
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName,
          'custom:middleName': values.firstName,
          'custom:initials': capitalizeName(values.firstName, values.lastName),
          'custom:organization': values.organizationName,
          'custom:title': 'Admin',
          email: values.email,
          locale: 'miami'
        }
      }
    });
    navigateSearch('/register', { step: '2' });
  };

  const userBillingAddress = useSelectBillToSuggestion(form.watch('streetAddress')?.label);

  useEffect(() => {
    form.setValue('city', userBillingAddress?.city);
    form.setValue('zipCode', userBillingAddress?.zipCode);
  }, [userBillingAddress]);

  return (
    <div className="max-w-[280px] w-full h-full  mx-auto grid place-items-center ">
      <div className="flex flex-col justify-center items-center py-[30px]">
        <Auth.Header
          icon={SVG.register_account}
          title="Join us!"
          subtitle="Dignissim amet faucibus non diam nisi ridiculus risus eget. Sodales ipsum donec morbi non facilisis. Aliquam morbi est."
        />

        <FormProvider {...form}>
          <form>
            <Form.EmailInput
              className="mt-[30px]"
              name="email"
              inputProps={{ id: 'email', placeholder: 'E-mail Address' }}
            />
            <div className="flex mt-5">
              <Form.TextInput
                className="flex-1 mr-5"
                name="firstName"
                inputProps={{ id: 'firstName', placeholder: 'First Name' }}
              />

              <Form.TextInput
                className="flex-1 "
                name="lastName"
                inputProps={{ id: 'lastName', placeholder: 'Last Name' }}
              />
            </div>
            <Form.TextInput
              className="mt-5 "
              name="organizationName"
              inputProps={{ id: 'organizationName', placeholder: 'Organization Name' }}
            />
            {/* <Form.TextInput
              className="my-2.5"
              name="streetAddress"
              inputProps={{ id: 'streetAddress', placeholder: 'Street Address' }}
            /> */}
            <GeoSuggest
              placeholder="Street Address"
              inputClassName={cn(
                'w-full ring-none  !shadow-none px-4 py-2 text-sm font-inter text-input placeholder:text-slate-400 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-white  !focus:border-primary border border-nav rounded-[5px]'
              )}
              onSuggestSelect={(suggestion) => form.setValue('streetAddress', suggestion)}
              // location={new google.maps.LatLng(53.558572, 9.9278215)}
              // radius="20"
              name="streetAddress"
              id="streetAddress"
            />

            <Form.TextInput
              className="mt-5"
              name="apartment"
              inputProps={{ id: 'suiteOrApartment', placeholder: 'Suite or Apartment' }}
            />
            <div className="flex mt-5">
              <Form.TextInput
                className="flex-1 mr-5"
                name="city"
                inputProps={{ id: 'city', placeholder: 'City' }}
              />

              <Form.TextInput
                className={`flex-1  ${!!userBillingAddress?.zipCode ? 'opacity-50' : ''}`}
                name="zipCode"
                inputProps={{
                  id: 'zipCode',
                  placeholder: 'Zip Code',
                  disabled: !!userBillingAddress?.zipCode
                }}
              />
            </div>
            <Form.PhoneInput
              className="mt-5"
              name="phoneNumber"
              inputProps={{ id: 'phoneNumber', placeholder: 'Phone Number' }}
            />

            <Form.PhoneInput
              className="mt-5"
              name="approvalCode "
              inputProps={{ id: 'approvalCode', placeholder: 'Approval Code' }}
            />

            <Button.Primary
              className="mt-[30px] py-[15px]"
              label="Continue"
              onClick={form.handleSubmit(onSubmit)}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default memo(RegisterStep1);
