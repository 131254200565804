import * as React from 'react';
import cn from "classnames";

export interface IButtonCommonProps {
  className?: string;
  children?: React.ReactNode;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'whiteLight';
  type?: 'button' | 'submit' | 'reset'; // button type
  textClass?: string;
}

const styleVariant = (variant) => {
  switch (variant) {
    case 'contained':
      return 'bg-current';
    case 'outlined':
      return 'bg-white ring-1 ring-current';
      break;

    default:
      break;
  }
};

const styleColor = (color) => {
  switch (color) {
    case 'primary':
      return 'text-primary';
    case 'secondary':
      return 'text-nav';
    case 'whiteLight':
      return 'text-white-light ring-1 ring-nav';
    default:
      break;
  }
};

export default function ButtonCommon({
  variant = 'contained',
  color = 'primary',

  className,
  textClass,
  children,
  type = 'button',
  ...rest
}: IButtonCommonProps & React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      type={type}
      className={cn(
        'w-full inline-block px-[15px] py-2  font-inter text-xs leading-snug rounded-md   hover:bg-blue-700 hover:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out',
        styleVariant(variant),
        styleColor(color),
        className
      )}
      {...rest}
    >
      <p className={cn(textClass, {
        'text-white': variant === 'contained',
        'text-black': variant !== 'contained'
      })}>
        {children}
      </p>
    </button>
  );
}
ButtonCommon.displayName = 'Button.Common';
