// export const config = {
//   apiKey: 'AIzaSyCDwdWtACa6bVuWWQf1IwSKIqwFrDVNLJc'
// };

export const config = {
  s3: {
    REGION: process.env.REACT_APP_S3_REGION,
    BUCKET: process.env.REACT_APP_S3_BUCKET
  },

  apiGateway: {
    REGION: process.env.REACT_APP_GATEWAY_REGION,
    URL: process.env.REACT_APP_GATEWAY_URL
  },

  cognito: {
    REGION: process.env.REACT_APP_COGNITOR_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITOR_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITOR_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITOR_IDENTITY_POOL_ID
  },

  PLACES_API_KEY: process.env.REACT_APP_PLACES_API_KEY,

  APPROVAL_CODE: process.env.REACT_APP_APPROVAL_CODE,

  API_NAME: process.env.REACT_APP_API_NAME
};

// const prod = {
//   s3: {
//     REGION: "us-east-1",
//     BUCKET: "smart-notes-uploads-api-smartnotesattachmentsbuc-1fcjrex7zelqs",
//   },

//   apiGateway: {
//     REGION: "us-east-1",
//     URL: "https://6u9nyvwyhd.execute-api.us-east-1.amazonaws.com/dev",
//   },

//   cognito: {
//     REGION: "us-east-1",
//     USER_POOL_ID: "us-east-1_x9XMpRzE1",
//     APP_CLIENT_ID: "7ncf1ovl92nddpq6b25no5fccu",
//     IDENTITY_POOL_ID: "us-east-1:b6a51acc-0f1a-49ba-9db9-e26ee68bd65c",
//   },

//   Places_API_KEY: "xyzabcde",
// };
