import { SVG } from 'assets/imagePath';
import { Breadcrumb } from 'components/breadcums';
import Button from 'components/button';
import { BREADCRUMB } from 'constant/breadcrumbs';
import { useAuth } from 'hooks/useAuth';
import { FC, Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { IBreadcrumb } from 'types';

type Props = {
  className?: string;
  detailProps: {
    detailName: string;
    addLabel: string;
    actionLabel?: string;
    labelDetail: string;
  };
  onEdit?: () => {};
  onAdd?: () => {};
  onMore?: () => {};
};

const HeaderDetail: FC<Props> = ({ className, detailProps, onEdit, onAdd, onMore }) => {
  const { CURRENT_ROLE, currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex justify-between">
      <ul className="flex items-center  list-none text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.filter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={() => {}}
          />
        </li>
        {/* <li></li> */}
      </ul>
      <Button.White
        className="!p-default mr-[35px]"
        color="white"
        label={detailProps.labelDetail}
        icon={SVG.chevron_down}
        onClick={() => {}}
      />

      <div className="flex items-center text-xs text-black font-bold gap-x-[5px] cursor-pointer">
        <img src={SVG.chevron_left} />
        <p>{detailProps?.detailName || ''}</p>
      </div>
      <ul className="flex items-center list-none text-center gap-2.5">
        <li>
          <p className="hidden lg:flex items-center text-sm text-black font-bold">
            {currentUser?.username} - {currentUser?.shortType}
          </p>
        </li>
        {!!detailProps?.addLabel?.length && <li>
          <Button.Primary className="!p-default" label={detailProps.addLabel} onClick={onAdd} />
        </li>}
        <li>
          <Button.White className="!p-default" color="white" label={detailProps?.actionLabel || 'Edit'} onClick={onEdit} />
        </li>
        <li>
          <Button.Icon icon={SVG.more_horizontal} variant="outlined" onClick={onMore} />
        </li>
        <li>
          <Button.Icon icon={SVG.navigate} variant="outlined" onClick={() => {}} />
        </li>
      </ul>
    </div>
  );
};
HeaderDetail.displayName = 'Header.Detail';
export default memo(HeaderDetail);
