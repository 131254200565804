import { SET_LOADING_MODAL, SET_REGISTER_FORM, SET_USER_INFO } from 'constant';
import { useReducer, createContext } from 'react';

const initialState: any = {
  userInfo: null,
  registerForm: null,
  loadingModalOpen: false
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_REGISTER_FORM:
      return {
        ...state,
        registerForm: action?.payload
      }
    case SET_LOADING_MODAL: 
      return {
        ...state,
        loadingModalOpen: action?.payload
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action?.payload
      }
    default:
      return {
        state
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>{props.children}</GlobalContext.Provider>
  );
};
