import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { Auth } from 'aws-amplify';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Form from 'components/form';
import { SET_REGISTER_FORM } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useNavigateSearch } from 'hooks';
import { useRegisterStep2Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IRegisterStep2FormValues } from 'types';
import { Auth as AuthLayout } from '../auth';

const RegisterStep2: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!registerForm) {
      navigateSearch('/register', { step: '1' });
    }
  }, []);

  const form = useForm<IRegisterStep2FormValues>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: FormResolver(useRegisterStep2Schema())
  });

  const navigateSearch = useNavigateSearch();

  const onSubmit = async (values: IRegisterStep2FormValues) => {
    try {
      const payload = {
        ...registerForm,
        password: values.password
      }
      const authUser = await Auth.signUp(payload);
      // if (authUser) {
      //   sessionStorage.setItem('registerForm', JSON.stringify(payload));
      // }
      navigateSearch('/register', { step: '3' });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="max-w-[280px] w-full h-full   mx-auto flex flex-col justify-center items-center ">
      <AuthLayout.Header
        icon={SVG.register_security}
        title="Complete registration!"
        subtitle="Password must consist of at least eight characters, including at least one number & use of both lower and uppercase letters, and special characters, for example #, ?, !."
        canGoBack
      />

      <FormProvider {...form}>
        <form className="flex flex-col gap-[20px] w-full mt-[30px]">
          <Form.PasswordInput
            className="w-full"
            name="password"
            inputProps={{ id: 'password', placeholder: 'Password' }}
          />

          <Form.PasswordInput
            className="w-full"
            name="confirmPassword"
            inputProps={{ id: 'confirmPassword', placeholder: 'Confirm Password' }}
          />

          <div className="w-full">
            <Checkbox.Single
              classNameBox="justify-start mx-2.5"
              label="Accept Terms & Conditions"
              value={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </div>

          <Button.Primary
            className="mt-2.5 py-3"
            label="Register"
            onClick={form.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(RegisterStep2);
