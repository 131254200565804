import { STORAGE } from 'constant/auth';
import { ICurrentUser } from 'types/auth.interface';

export const useAuth = () => {
  const currentUser: ICurrentUser = JSON.parse(localStorage.getItem(STORAGE.APP_AUTH));
  const CURRENT_ROLE = currentUser?.type;
  // const CURRENT_ROLE = 'SYSTEM_ADMIN';

  return { currentUser, CURRENT_ROLE };
};
