import { ErrorMessage } from '@hookform/error-message';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useController, useFormContext } from 'react-hook-form';
import cn from "classnames";

interface IFormDatePicker {
  value?: any;
  date?: any;
  onSelect?: any;
  onChange?: any;
  error?: any;
  className?: string;
  name: string;
  label?: string;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

const FormDatePicker: React.FC<IFormDatePicker> = ({
  date,
  onSelect,
  onChange,
  value,
  className,
  error,
  label,
  inputProps,
  name
}) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: inputProps.defaultValue
  });

  return (
    <div className="w-full relative bg-inherit">
      {label && (
        <p className="absolute text-[10px] leading-[10px] left-[10px] px-[5px] top-[-5px] bg-pearl z-10">
          {label}
        </p>
      )}
      <DatePicker
        selected={date}
        onSelect={onSelect}
        onChange={onChange}
        value={value}
        className={cn(
          'z-0 text-sm font-inter text-input placeholder:text-slate-400 bg-inherit bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-inherit ring-nav outlined-none p-1 border-none ring-1 w-full h-full rounded-[0px]',
          error ? 'ring-1 ring-red-500 focus:ring-1 focus:ring-red-500' : '',
          className
        )}
      />
      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => <p className="text-error text-xs font-inter my-1">{message}</p>}
      />
    </div>
  );
};

export default FormDatePicker;
