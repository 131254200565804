import { SVG } from 'assets/imagePath';
import { CheckboxSingleProps } from 'components/checkbox/CheckboxSingle';
import { Collapse } from 'components/collapse';
import Form from 'components/form/Form';
import { FormCheckboxProps } from 'components/form/FormCheckboxSingle';
import React from 'react';
import { IListInsight } from 'types/builder.interface';

interface InsightCheckboxProps {
  propsCheckBox: FormCheckboxProps & CheckboxSingleProps;
  isCollapse?: boolean;
  childItem: IListInsight;
}

function BuilderInsightCheckbox({
  isCollapse = false,
  propsCheckBox,
  childItem
}: InsightCheckboxProps) {
  const collapseList = childItem.children;
  return !isCollapse ? (
    <li className={`flex justify-between items-center ${childItem.level > 0 ? 'mx-2.5' : ''}`}>
      <Form.CheckboxSingle
        classNameText="!text-xs"
        {...propsCheckBox}
        value={propsCheckBox.name}
        // onChange={() => {}}
      />
    </li>
  ) : (
    <Collapse.Text
      defaultOpen={false}
      button={(open, close) => (
        <li className={`flex justify-between items-center h-full`}>
          {/* {open ? (
            <p className="text-xs cursor-default">{childItem?.label}</p>
          ) : (
           
          )} */}
          <div className="w-full relative flex">
            <Form.CheckboxSingle
              classNameText="!text-xs"
              {...propsCheckBox}
              value={propsCheckBox.name}
              // onChange={() => {}}
            />
            <div className="flex w-fit  gap-x-[5px] absolute right-0 top-0">
              <img src={open ? SVG.minus : SVG.plus} alt="minus" className="w-[15px] h-[15px]" />
            </div>
          </div>
        </li>
      )}
      collapseContent={
        <ul className="flex flex-col">
          {collapseList?.map((collapse_it) => {
            const collapseChild = collapse_it?.children;

            return (
              <BuilderInsightCheckbox
                propsCheckBox={{
                  name: `${collapse_it.id}_${collapse_it?.parent_id}`,
                  label: collapse_it?.label,
                  onChangeCustom: propsCheckBox?.onChangeCustom,
                  value: `${collapse_it.id}_${collapse_it?.parent_id}`
                }}
                isCollapse={!!collapseChild}
                childItem={collapse_it}
              />
            );
          })}
        </ul>
      }
    />
  );
}

export default BuilderInsightCheckbox;
