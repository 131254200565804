import { ErrorMessage } from '@hookform/error-message';
import { clippingParents } from '@popperjs/core';
import Checkbox from 'components/checkbox';
import { CheckboxSingleProps } from 'components/checkbox/CheckboxSingle';
import { DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export type FormCheckboxProps = {
  className?: string;
  name: string;
  label?: string;
  onChangeCustom?: () => void;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};

const FormCheckboxSingle: FC<FormCheckboxProps & CheckboxSingleProps> = ({
  className,
  name,
  label,
  onChangeCustom,
  inputProps,
  ...rest
}) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: false
  });

  return (
    <div className={className}>
      <Checkbox.Single
        {...inputProps}
        label={label}
        value={field.value}
        onChange={(e) => {
          field?.onChange(e.target.checked);
          onChangeCustom && onChangeCustom();
        }}
        {...rest}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => <p className="text-error text-xs font-inter my-1">{message}</p>}
      />
    </div>
  );
};

FormCheckboxSingle.displayName = 'Form.CheckboxSingle';

export default memo(FormCheckboxSingle);
