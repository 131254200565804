import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import {
  FilterDepartment,
  FilterLocation,
  FilterOtherDepartment,
  FilterSystem
} from 'components/filterSide';
import Form from 'components/form';
import { CONTENT_LIST } from 'pages/notes/data';
import { memo } from 'react';

export interface IOrganizationFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

function OrganizationFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox
}: IOrganizationFilterProps) {
  return (
    <Box.Standard className="w-full h-full">
      <div className="p-2.5 flex flex-col">
        <h4 className="font-bold text-xs tracking-tighter flex justify-between items-center">
          <span>Filter Organizations by</span>
          <img src={SVG.unfilter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' }
            }}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <FilterSystem />
          <FilterDepartment />
          <FilterOtherDepartment />
          <FilterLocation />
        </div>
      </div>
      {/* <div className="mt-10 w-full">
        <div className="flex w-full flex-row gap-2.5">
          <Button.Primary className="flex-1 !p-default" label="Apply" onClick={onSubmitFilter} />
          <Button.White
            className="flex-1 !p-default"
            color="white"
            label="Clear"
            onClick={onClearFilter}
          />
        </div>
      </div> */}
    </Box.Standard>
  );
}

OrganizationFilter.displayName = 'Organization.Filter';

export default memo(OrganizationFilter);
