import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { ButtonIconProps } from 'components/button/ButtonIcon';
import * as React from 'react';

export interface IRecordProps {
  defaultRecord?: boolean;
  onRecord?: () => void;
  buttonStyle?: ButtonIconProps;
}

export default function Record({
  onRecord = () => {},
  defaultRecord = false,
  buttonStyle
}: IRecordProps) {
  const [isPlaying, setIsPlaying] = React.useState(defaultRecord);
  const handleChangePlay = () => {
    // if (isPlaying) {
    //   onPause();
    // } else {
    //   onPlay();
    // }
    setIsPlaying(!isPlaying);
  };
  return (
    <Button.Icon
      variant="outlined"
      classNameIcon="w-[15px] h-[15px]"
      className={`!py-[5px] !px-[12.5px]  hover:bg-grey ${isPlaying ? 'bg-grey' : ''}`}
      icon={isPlaying ? SVG.pause : SVG.record}
      {...buttonStyle}
      onClick={handleChangePlay}
    />
  );
}
