import { SVG } from 'assets/imagePath';
import Attachments from 'components/attachments';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import Footer from 'components/footer/Footer';
import { GridTable } from 'components/gridTable';
import { HeaderApp } from 'components/header';
import { DEFAULT_LIMIT } from 'constant/pagination';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { ITableColumn } from 'types';
import { getRowsByPage } from 'utils';
import { fakeColAttach } from './data';

export interface IAttachmentsPageProps {}

export default function AttachmentsPage({}: IAttachmentsPageProps) {
  const fakeColumn: ITableColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'format',
      headerName: 'Format',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'author',
      headerName: 'Author',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },

    {
      field: 'actions',
      headerName: ' ',
      headerStyle: { textAlign: 'center', width: '50px' },
      rowStyle: {
        justifyContent: 'center',
        width: '50px'
      },

      renderCell: (row) => {
        return (
          <>
            <img
              id={`tooltip_plus_${row?.id}`}
              src={SVG.plus}
              alt="co-icon"
              className="icon-app cursor-pointer min-w-[17px]"
              // onClick={() => {
              //   navigate(`/note/${row?.id}`);
              // }}
            />
            <Tooltip anchorId={`tooltip_plus_${row?.id}`} content="Note Detail" />
          </>
        );
      }
    }
  ];
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState(getRowsByPage(fakeColAttach, pageSize, currentPage));

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(fakeColAttach, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage]);

  const form = useForm({
    defaultValues: {},
    mode: 'onChange'
    // resolver: FormResolver(useLoginSchema())
  });

  const handleFilter = (data) => {
    console.log(data);
  };

  return (
    <DefaultLayout>
      <HeaderApp.Inquires toggle={toggle} setToggle={setToggle} />
      <div className="w-full h-full flex flex-col flex-1 items-center  p-2.5">
        <div className="w-full flex justify-between items-center">
          <p className="font-normal">
            {pageSize} Records
            {/* <span className="font-bold">{pageSize}</span> */}
          </p>
          <div className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label="10 Records per page"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="flex gap-2.5 flex-1 items-stretch h-full w-full mt-2.5">
          <div className={`w-[180px]  transition-all ${toggle ? '' : '-translate-x-[150%] fixed'}`}>
            <FormProvider {...form}>
              <form className="h-full">
                <Attachments.Filter
                  onClearFilter={() => form.reset({})}
                  onSubmitFilter={form.handleSubmit(handleFilter)}
                  onClearSearchBox={() =>
                    form.reset((currentValues) => ({ ...currentValues, search_box: '' }))
                  }
                />
              </form>
            </FormProvider>
          </div>
          <div className="flex-1 h-full overflow-auto relative">
            <Box.Standard className="flex w-full h-full overflow-auto flex-col  p-0 ">
              <div className="!overflow-auto  w-full max-h-full z-0">
                <GridTable
                  className="table-fixed"
                  checkboxes={false}
                  column={fakeColumn}
                  row={rows}
                />
              </div>
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer
        pagination={{
          total: fakeColAttach?.length,
          pageSize: pageSize,
          onChangeCurrentPage: handleChangePage,
          onChangePageSize: handleChangePageSize
        }}
      />
    </DefaultLayout>
  );
}
