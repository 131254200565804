import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import FilterCheckList from 'components/filterSide/FilterCheckList';
import Form from 'components/form';
import { memo } from 'react';

export interface IStandardQnaFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

const checkListItem = [
  {
    label: 'Item',
    value: 'item'
  }
];
const checklistAuthor = [
  {
    label: 'Marcus Welby',
    value: 'marcus'
  }
];

function StandardQnaFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox
}: IStandardQnaFilterProps) {
  return (
    <Box.Standard className="w-full h-full">
      <div className="p-2.5 flex flex-col">
        <h4 className="font-bold text-xs flex tracking-[-0.03em] justify-between items-center">
          <span>Filter Standard Q/A by</span>
          <img src={SVG.unfilter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' }
            }}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <FilterCheckList isCollapse title="Item" checkList={checkListItem} />
        </div>
      </div>
    </Box.Standard>
  );
}

StandardQnaFilter.displayName = 'StandardQna.Filter';

export default memo(StandardQnaFilter);
