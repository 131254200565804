import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import { PAGE_SIZE_OPTIONS } from 'constant/pagination';
import React, { Fragment, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type PaginationProps = {
  pageSize?: number;
  total: number;
  onChangePageSize?: (pageSize: number) => void;
  onChangeCurrentPage?: (current: number) => void;
};

function Pagination({
  pageSize = 10,
  total,
  onChangePageSize,
  onChangeCurrentPage
}: PaginationProps) {
  const calTotalPage = (count, pSize) => {
    return Math.ceil(count / pSize);
  };

  const [totalPage, setTotalPage] = useState(calTotalPage(total, pageSize));

  const [currentPage, setCurrentPage] = useState(1);

  const arrNumber = Array.from({ length: totalPage });

  useEffect(() => {
    const totalPage = calTotalPage(total, pageSize);
    setTotalPage(totalPage);
  }, [pageSize, total]);

  const changeCurrentPage = (page) => {
    onChangeCurrentPage && onChangeCurrentPage(page);
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-start flex-wrap gap-2.5">
      {onChangePageSize && (
        <div className="flex items-center">
          <span className="mr-[5px]">Page size:</span>

          <Dropdown.Standard
            CustomButton={(item) => (
              <div className="flex gap-2.5 items-center hover:bg-transparent p-5-10 border border-nav rounded-[5px]">
                <div className="">
                  <p className="text-xs">{item.label}</p>
                </div>
                <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
              </div>
            )}
            options={PAGE_SIZE_OPTIONS}
            onChangeValue={(item: any) => {
              onChangePageSize(item?.value);
            }}
          />
        </div>
      )}
      <div className="flex gap-2.5">
        <Button.White
          className="!p-5-10"
          color="white"
          label="Previous"
          onClick={() => {
            changeCurrentPage(currentPage - 1);
          }}
          disabled={currentPage === 1}
        />

        {arrNumber.map((_, idx) => (
          <Button.White
            key={idx}
            className={`!p-5-10 ${currentPage === idx + 1 ? 'bg-grey' : ''}`}
            color="white"
            label={(idx + 1).toString()}
            onClick={() => {
              const page = idx + 1;
              changeCurrentPage(page);
            }}
          />
        ))}
        <Button.White
          className="!p-5-10"
          color="white"
          label="Next"
          onClick={() => {
            changeCurrentPage(currentPage + 1);
          }}
          disabled={currentPage === totalPage}
        />
      </div>
    </div>
  );
}

export default Pagination;
