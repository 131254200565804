import { RouterPath } from 'routes/router.constant';
import { INavLink } from 'types';

export const NAV_LINKS: INavLink[] = [
  {
    label: 'Home',
    to: RouterPath.HOME
    // authPermission: ['SYSTEM_ADMIN', 'CODERS', 'DOCS', 'LEAD']
  },
  {
    label: 'Notes',
    to: RouterPath.NOTES
    // authPermission: ['SYSTEM_ADMIN', 'CODERS', 'DOCS', 'LEAD']
  },

  {
    label: 'Organizations',
    to: RouterPath.ORGANIZATIONS
    // authPermission: ['SYSTEM_ADMIN']
  },
  {
    label: 'Clinicians',
    to: RouterPath.CLINICIANS
    // authPermission: ['SYSTEM_ADMIN']
  },
  {
    label: 'Doc Specialists',
    to: RouterPath.DOC_ASSISTANTS
    // authPermission: ['SYSTEM_ADMIN', 'LEAD']
  },
  {
    label: 'Coders',
    to: RouterPath.CODERS
    // authPermission: ['SYSTEM_ADMIN', 'LEAD']
  },
  {
    label: 'Managers',
    to: RouterPath.LEAD
    // authPermission: ['SYSTEM_ADMIN']
  },
  {
    label: 'System Admins',
    to: RouterPath.SYSTEM_ADMINS
    // authPermission: ['SYSTEM_ADMIN']
  },
  {
    label: 'Settings',
    to: RouterPath.SETTINGS
    // authPermission: ['SYSTEM_ADMIN']
  }
];
