import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import Footer from 'components/footer/Footer';
import { GridTable } from 'components/gridTable';
import { HeaderApp } from 'components/header';
import { Notes } from 'components/notes';
import Pagination from 'components/pagination/Pagination';
import { DEFAULT_LIMIT } from 'constant/pagination';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ITableColumn } from 'types';
import { ObjectUtils, getRowsByPage } from 'utils';
import { fakeRow } from './data';
import { useApiCall } from 'hooks';
import { getTreatmentRecordsAPI } from 'services';
import { GlobalContext } from 'context/GlobalContext';
import { SET_LOADING_MODAL } from 'constant';
import { FiPlus } from 'react-icons/fi';

const statusOptions = [
  { value: 'CODING', label: 'Coding' },
  { value: 'NEW', label: 'New' }
];

const NoteList = () => {
  const { CURRENT_ROLE } = useAuth();
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const [fetchData, loading, noteList = []] = useApiCall(getTreatmentRecordsAPI);
  const [toggle, setToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) return;
    fetchData('all');
  }, [userInfo]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  useEffect(() => {
    const newRows = getRowsByPage(noteList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, noteList]);

  const column: ITableColumn[] = [
    {
      field: 'status_table',
      headerName: 'Status',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      },
      renderCell: (row) => {
        return (
          <div className="w-fit min-w-[118px] text-center mx-auto z-0">
            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex items-center">
                  <div className="border border-nav px-2.5 py-[2px] rounded-full">
                    <p className="text-[10px]">{item.label}</p>
                  </div>

                  <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
                </div>
              )}
              options={statusOptions}
            />
          </div>
        );
      }
    },
    {
      field: 'clinician',
      headerName: 'Clinician',
      rowStyle: {
        minWidth: '136px'
      },
      renderCell: (row) => {
        let data: any = {};
        if (ObjectUtils.isJson(row?.clinician))
        data = JSON.parse(row?.clinician || '');
        return <>{[data?.firstName, data?.middleName, data?.lastName].join(" ")}</>
      }
    },
    {
      field: 'deg',
      headerName: 'Deg',
      hideCol: CURRENT_ROLE === 'CODERS'
    },
    {
      field: 'organization',
      headerName: 'Org',
      renderCell: (row) => <>{row?.organization === 'no_data_yet' ? '' : row?.organization}</>
    },
    {
      field: 'patient',
      headerName: 'Patiant',
      rowStyle: {
        minWidth: '136px'
      }
    },
    {
      field: 'documentSpecialist',
      headerName: 'DS',

      // hideCol: CURRENT_ROLE === 'CODERS',
      // renderCell: (row) => {
      //   return (
      //     <div className="z-0 mx-auto text-center w-max">
      //       <Dropdown.Standard
      //         CustomButton={(item) => (
      //           <div className="flex items-center">
      //             <div className="">
      //               <p className="text-xs">MAC</p>
      //             </div>

      //             <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
      //           </div>
      //         )}
      //         options={statusOptions}
      //       />
      //     </div>
      //   );
      // }
    },
    {
      field: 'medicalCoder',
      headerName: 'CO',
      // renderCell: (row) => {
      //   return (
      //     <div className="z-0 mx-auto text-center w-max">
      //       <Dropdown.Standard
      //         CustomButton={(item) => (
      //           <div className="flex items-center">
      //             <div className="">
      //               <p className="text-xs">JLM</p>
      //             </div>

      //             <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
      //           </div>
      //         )}
      //         options={statusOptions}
      //       />
      //     </div>
      //   );
      // }
    },
    // {
    //   field: 'lead',
    //   headerName: 'Lead',

    //   hideCol: CURRENT_ROLE === 'CODERS' || CURRENT_ROLE === 'DOCS',

    //   renderCell: (row) => {
    //     return CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="z-0 mx-auto text-center w-max">
    //         <Dropdown.Standard
    //           CustomButton={(item) => (
    //             <div className="flex items-center">
    //               <div className="">
    //                 <p className="text-xs">BLM</p>
    //               </div>

    //               <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
    //             </div>
    //           )}
    //           options={statusOptions}
    //         />
    //       </div>
    //     ) : (
    //       <>BLM</>
    //     );
    //   }
    // },
    {
      field: 'dur',
      headerName: 'Dur',
      hideCol: CURRENT_ROLE === 'SYSTEM_ADMIN' || CURRENT_ROLE === 'CODERS'
    },
    // {
    //   field: 'icd',
    //   headerName: 'ICD',
    //   hideCol: CURRENT_ROLE !== 'CODERS'
    // },
    // {
    //   field: 'eNm',
    //   headerName: 'E&M',
    //   hideCol: CURRENT_ROLE !== 'CODERS'
    // },
    // {
    //   field: 'time',
    //   headerName: 'TIME',
    //   hideCol: CURRENT_ROLE !== 'CODERS'
    // },
    // {
    //   field: 'loc',
    //   headerName: 'LOC',
    //   hideCol: CURRENT_ROLE !== 'CODERS'
    // },
    {
      field: 'dsCreateAppointment',
      headerName: 'APPT',
      // hideCol: CURRENT_ROLE === 'CODERS',
      // renderCell: (row) => {
      //   return row?.appt ? (
      //     <img src={SVG.plus_green} alt="appt-icon" className="mx-auto icon-app" />
      //   ) : (
      //     <img src={SVG.plus_red} alt="appt-icon" className="mx-auto icon-app" />
      //   );
      // }
    },
    // {
    //   field: 'dsQ',
    //   headerName: 'DS?',
    //   hideCol: CURRENT_ROLE === 'CODERS',
    //   renderCell: (row) => {
    //     return row?.dsQ ? (
    //       <img src={SVG.mark_question_green} alt="ds-icon" className="mx-auto icon-app" />
    //     ) : (
    //       <img src={SVG.mark_question_red} alt="ds-icon" className="mx-auto icon-app" />
    //     );
    //   }
    // },
    // {
    //   field: 'coQ',
    //   headerName: 'CO?',
    //   hideCol: CURRENT_ROLE === 'DOCS',
    //   renderCell: (row) => {
    //     return row?.coQ ? (
    //       <img src={SVG.mark_question_green} alt="co-icon" className="mx-auto icon-app" />
    //     ) : (
    //       <img src={SVG.mark_question_red} alt="co-icon" className="mx-auto icon-app" />
    //     );
    //   }
    // },

    {
      field: 'msgImportant',
      headerName: 'Important',
      renderCell: (row) => {
        return <p className="text-xs text-error">{row?.important}</p>;
      }
    },
    {
      field: 'msgNote',
      headerName: 'Note Message',
      renderCell: (row) => {
        return <p className="text-xs text-error">{row?.message}</p>;
      }
    },
    {
      field: 'actions',
      headerName: ' ',
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-gray-active w-4 h-4 cursor-pointer"
            onClick={() => {
              navigate(`/note/${row?.treatmentRecordId}`);
            }}
          />
        );
      }
    }
  ];

  //Fake data

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  //Filter
  const form = useForm({
    defaultValues: {}
    // resolver: FormResolver(useLoginSchema())
  });

  const handleFilter = (data) => {
    console.log(data);
  };

  return (
    <DefaultLayout>
      <HeaderApp.List toggle={toggle} setToggle={setToggle} />
      <div className="fixed w-full top-[107px] right-0 left-0 bottom-[50px] flex flex-col flex-1 items-center p-2.5 overflow-y-auto">
        <div className="flex items-center justify-between w-full">
          <p className="font-normal">
            Records:
            <span className="font-bold">{pageSize}</span>
          </p>
          <div className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label="10 Records per page"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="flex gap-2.5 h-full w-full mt-2.5">
          <div className={`w-[180px]  transition-all ${toggle ? '' : '-translate-x-[150%] fixed'}`}>
            <FormProvider {...form}>
              <form>
                <Notes.Filter
                  onClearFilter={() => form.reset({})}
                  onSubmitFilter={form.handleSubmit(handleFilter)}
                  onClearSearchBox={() =>
                    form.reset((currentValues) => ({ ...currentValues, search_box: '' }))
                  }
                />
              </form>
            </FormProvider>
          </div>
          <div className="relative flex-1 overflow-auto">
            <Box.Standard className="flex flex-col w-full p-0 overflow-auto ">
              <div className="!overflow-auto w-full max-h-full z-0">
                <GridTable column={column} row={rows} />
              </div>
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer
        pagination={{
          total: noteList?.length,
          pageSize: pageSize,
          onChangeCurrentPage: handleChangePage,
          onChangePageSize: handleChangePageSize
        }}
      />
    </DefaultLayout>
  );
};

export default NoteList;
