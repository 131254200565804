import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

export const useApiCall = (service: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = useCallback(async (param: any, errorFunc: any = null) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);
      const resData = result?.items || result?.data || result;
      console.log("resData", resData);
      const str = JSON.stringify(resData).replaceAll("empty_flag", "").replaceAll('no_data_yet', '');
      const newData = JSON.parse(str);
      console.log("newData", newData);

      setData(newData);
    } catch (error) {
      setLoading(false);
      setError(error?.message);
      toast.error(error?.message);
    }
  }, []);

  return [fetchData, loading, data, error];
};
