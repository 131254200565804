import { SVG } from 'assets/imagePath';
import Pagination, { PaginationProps } from 'components/pagination/Pagination';
import { fakeRow } from 'pages/notes/data';
import React from 'react';

interface FooterProps {
  pagination?: PaginationProps;
}

function Footer({ pagination }: FooterProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 w-full bg-white border border-grey p-2.5 grid grid-cols-3">
      <div />
      <div className="self-center justify-self-center">
        <img src={SVG.light_bulb} className="object-contain w-5 h-5" />
      </div>
      {pagination && (
        <div className="justify-self-end">
          <Pagination
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            onChangePageSize={pagination?.onChangePageSize}
            onChangeCurrentPage={pagination?.onChangeCurrentPage}
          />
        </div>
      )}
    </div>
  );
}

export default Footer;
