import { SVG } from 'assets/imagePath';
import { Text } from 'components/text';
import * as React from 'react';

export interface QuestionBoxProps {
  title: React.ReactNode;
  content: React.ReactNode;
}

export default function QuestionBox({ title, content }: QuestionBoxProps) {
  return (
    <div className="py-[30px] px-5 question__box flex flex-col gap-[15px]">
      <div className="flex flex-row gap-[15px] items-center">
        <img className="max-w-[40px] w-full h-auto" src={SVG.question_outline_gold} />
        <Text responsive Component={'span'} size="18" weight="500" className="whitespace-pre-line">
          {title}
        </Text>
      </div>
      <Text responsive Component={'p'} size="16">
        {content}
      </Text>
    </div>
  );
}
