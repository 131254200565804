import React, { Fragment, ReactNode } from 'react';

interface IDefaultLayout {
  children: ReactNode;
}

const DefaultLayout: React.FC<IDefaultLayout> = ({ children }) => {
  return (
    <Fragment>
      <div className="w-full h-auto min-h-screen flex flex-col bg-pearl pt-12">{children}</div>
    </Fragment>
  );
};

export default DefaultLayout;
