import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import { Dropdown } from 'components/dropdown';
import QnA, { IQnAProps } from './QnA';
import { IQnAMessageProps } from './QnAMessage';

export interface IQnABoxProps {
  title?: string;
  messages?: IQnAMessageProps[];
}

export default function QnABox({
  title = 'Q&A',
  messages = [
    {
      message: 'What was the Pain Level ?',
      avatar: { character: 'DS', bgColor: 'bg-error' },
      time: '9:32 AM'
    },
    {
      message: 'Pain Level is a 7',
      avatar: { character: 'MD', bgColor: 'bg-success' },
      time: '5 min ago'
    }
  ]
}: IQnABoxProps) {
  return (
    <Box.Standard className="border border-solid border-pearl">
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <p className="font-bold text-xs">{title}</p>
          <p className="flex gap-2.5 items-center">
            {/* <span className="text-light-gray text-[10px]">54 min ago</span> */}
            <img src={SVG.recent_clock} className="object-contain w-[15px] h-[15px]" />
          </p>
        </div>
        <div className="flex flex-col p-[5px] gap-[8.5px]">
          {messages.map((mess, idx) => (
            <QnA.Message message={mess.message} avatar={mess.avatar} time={mess.time} />
          ))}
        </div>
        <Dropdown.Input
          options={[]}
          className={`border-nav border min-w-max hover:bg-transparent py-2.5 px-[15px] mt-[5px] justify-start`}
          label={'Select Question'}
          icon={SVG.arrow_down}
          classNameText="!text-sm"
        />
      </div>
    </Box.Standard>
  );
}
