import { Box } from 'components/box';
import { DocAssistant } from 'components/docAssistant';
import { HeaderApp } from 'components/header';
import { Note } from 'components/note';
import NoteDetail from 'components/note/NoteDetail';
import NoteSideBar from 'components/note/NoteSideBar';
import { BREADCRUMB } from 'constant/breadcrumbs';
import DefaultLayout from 'layouts/DefaultLayout';
import { useState } from 'react';
import { IBreadcrumb } from 'types';

const DocAssistantDetail = () => {
  const [searchVal, setSearchValue] = useState<string>('');
  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };

  const breadcrumbs: IBreadcrumb[] = BREADCRUMB.find(
    (item) => item.url === location.pathname
  )?.breadcrumbs;

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: ` - Details`,
          labelDetail: `DC Details - `
        }}
      />
      <div className="w-full  flex flex-col flex-1 justify-center items-center  p-2.5">
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <DocAssistant.SideBar />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full  flex-1 gap-2.5">
            <Box.Standard className="h-full">
              <DocAssistant.Detail dcData={{}}/>
            </Box.Standard>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DocAssistantDetail;
