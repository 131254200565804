export * from './helpers';
export * from './object.utils';
export * from './table';
export * from './dateTime';

export const regexAccountId = (msg) => {
  const l = parseInt(msg.length);
  let m1 = msg.split(',')?.join('');
  let m2 = m1.split(' ').join('');
  let m3 = m2.split('.').join('');

  const regexFront = /([A-Za-z0-9]+)/g;
  const regexBack = /([A-Za-z0-9]+)$/g;

  let str = 'NativeStack, Inc';

  var resultFront = regexFront.exec(m3);
  var resultBack = regexBack.exec(m3);

  if (resultFront[0] === resultBack[0]) {
    console.log('HERE IS FRONT: ', resultFront[0] + ' back:' + resultBack[0]);

    return resultFront[0].toLowerCase();
  } else {
    console.log('HERE IS FRONT/BACK: ', resultFront[0] + resultBack[0]);

    return resultFront[0] + resultBack[0].toLowerCase();
  }
};
