import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import Checkbox from 'components/checkbox/Checkbox';
import Form from 'components/form';
import { Search } from 'components/search';
import { CONTENT_LIST } from 'pages/notes/data';
import React, { Fragment, memo } from 'react';

export interface INotesFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

function NotesFilter({ onClearFilter, onSubmitFilter, onClearSearchBox }: INotesFilterProps) {
  return (
    <Box.Standard className="w-full">
      <div className="p-2.5 flex flex-col">
        <h4 className="flex items-center justify-between text-sm font-bold">
          <span>Filter Notes By</span>
          <img src={SVG.unfilter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' }
            }}
          />
        </div>
        {CONTENT_LIST.map((content, idx) => {
          return (
            !content.isHide && (
              <div className="mt-2.5" key={idx}>
                <h4 className="text-sm font-bold">{content?.title}</h4>
                <div className="mt-2.5">
                  {content.check_list.map((item, idx) => {
                    return (
                      !item.isHide && (
                        <div
                          key={idx}
                          className="flex justify-between w-full items-center mb-[5px]"
                        >
                          <div className="w-fit">
                            <Form.CheckboxSingle
                              classNameText="text-xs"
                              name={item?.name || ''}
                              label={item?.label}
                              // onChange={() => {}}
                            />
                          </div>
                          {!!item?.collapse && (
                            <img src={SVG.chevron_down} alt="plus-icon" className="icon-app" />
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="w-full mt-10">
        <div className="flex w-full flex-row gap-2.5">
          <Button.Primary className="flex-1 !p-default" label="Apply" onClick={onSubmitFilter} />
          <Button.White
            className="flex-1 !p-default"
            color="white"
            label="Clear"
            onClick={onClearFilter}
          />
        </div>
      </div>
    </Box.Standard>
  );
}

NotesFilter.displayName = 'Notes.Filter';

export default memo(NotesFilter);
