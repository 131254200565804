import { Search } from 'components/search';
import { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react';

export interface INavSearchProps {
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export default function NavSearch({ inputProps }: INavSearchProps) {
  const [searchVal, setSearchVal] = useState('');
  return (
    <Search.Standard
      value={searchVal}
      onChange={(val) => setSearchVal(val)}
      clearInput={() => setSearchVal('')}
      className="w-fit"
      inputProps={inputProps}
    />
  );
}
