import * as React from 'react';
import Slider from 'react-slick';
import SliderItem from './components/SliderItem';
import { IMAGES, SVG } from 'assets/imagePath';
import { Text } from 'components/text';
import SliderText from './components/SliderText';
import { BREAKPOINT } from 'constant/breakpoints';
import { useViewport } from 'context/ViewportContext';
export interface CarouselProps {}

const CAROUSEL_LIST = [
  {
    id: 'question',
    title: 'Question in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_question
  },
  {
    id: 'dashboard',
    title: 'Dashboard in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_dashboard
  },
  {
    id: 'record',
    title: 'Record in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_record
  },
  {
    id: 'paused',
    title: 'Paused in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_paused
  },
  {
    id: 'question',
    title: 'Question in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_question
  },
  {
    id: 'dashboard',
    title: 'Dashboard in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_dashboard
  },
  {
    id: 'record',
    title: 'Record in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_record
  },
  {
    id: 'paused',
    title: 'Paused in the App',
    desc: 'Dictate notes and records directly into the system and radically reduce the need for manual typing and errors.',
    img: IMAGES.carousel_paused
  }
];

export default function Carousel(props: CarouselProps) {
  const { width } = useViewport();

  const [centerActive, setCenterActive] = React.useState(0);

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 5,
    speed: 500,
    // autoplay: true,

    arrows: false,
    responsive: [
      {
        breakpoint: BREAKPOINT.lg,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: BREAKPOINT.xs,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: (oldIndex, newIndex) => {
      console.log(oldIndex, newIndex);
      // let _newIdx = Math.floor(newIndex) + centerIndex;

      // if (newIndex > 4) {
      //   _newIdx = Math.floor((newIndex % 5) + 3);
      // }

      setCenterActive(newIndex);
    }
  };

  const ref = React.useRef(null);

  return (
    <div className="bg-white-light rounded-[30px]   p-[30px] md:p-[60px]">
      <div className=" md:max-w-[960px] w-full mx-auto text-center">
        <Text className="text-center text-xl  sm:text-2xl md:text-[32px]">
          Streamline Your Medical Documentation Process
        </Text>
        <Text responsive className="text-center mt-[20px] mb-[30px] md:px-[80px]" size="18">
          Managing medical documentation can be time-consuming and overwhelming, taking away
          valuable time that could be spent with patients. With{' '}
          <strong>PhysicianSmart® Notes</strong> you can streamline your documentation process and
          increase efficiency.
        </Text>
      </div>
      <Slider ref={ref} {...settings}>
        {CAROUSEL_LIST.map((item, idx) => (
          <SliderItem img={item.img} key={idx} isCenter={centerActive === idx} />
        ))}
      </Slider>

      <div className="max-w-[480px] mx-auto mt-[50px] flex flex-row gap-[15px] sm:gap-[30px] items-center">
        <div
          className="min-w-[30px] sm:min-w-[40px] h-[30px] sm:h-[40px] cursor-pointer hover:opacity-50"
          onClick={() => ref.current.slickPrev()}
        >
          <img src={SVG.back_carousel} />
        </div>
        <SliderText
          title={CAROUSEL_LIST[centerActive]?.title}
          desc={CAROUSEL_LIST[centerActive]?.desc}
        />
        <div
          className="min-w-[30px] sm:min-w-[40px] h-[30px] sm:h-[40px] cursor-pointer hover:opacity-50"
          onClick={() => ref.current.slickNext()}
        >
          <img className="w-full" src={SVG.next_carousel} />
        </div>
      </div>
    </div>
  );
}
