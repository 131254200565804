import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import { Dropdown } from 'components/dropdown';
import { IFilterCheckListProps } from 'components/filterSide/FilterCheckList';
import FilterForm from 'components/filterSide/FilterForm';
import Footer from 'components/footer/Footer';
import { GridTable } from 'components/gridTable';
import Header from 'components/header/Header';
import { Text } from 'components/text';
import { DEFAULT_LIMIT, PAGE_SIZE_OPTIONS } from 'constant/pagination';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getRowsByPage } from 'utils';

export interface ISmartTextsProps {}

const filters: IFilterCheckListProps[] = [
  {
    title: 'Chief complaint',
    checkList: [
      { value: 'ST1', label: 'Sore Throat' },
      { value: 'UD1', label: 'Urinary Discomfort' }
    ],
    isCollapse: true,
    classNameText: 'uppercase'
  },
  {
    title: 'HPI Additions',
    checkList: [
      { value: 'hpi_sinusitis_non', label: 'Sinusitis - Viral HPI' },
      { value: 'hpi_sinusitis', label: 'Sinusitis - Non-Viral HPI' },
      { value: 'uit_viral', label: 'UTI - Viral' },
      { value: 'uti_non_viral', label: 'UTI - Non-Viral' }
    ],
    isCollapse: true,
    classNameText: 'justify-center'
  },
  {
    title: 'Review of Systems',
    checkList: [
      { value: 'ROS1', label: 'Default ROS' },
      { value: 'ROS2', label: 'Observational ROS' },
      { value: 'ROS3', label: 'Comprehensive ROS' }
    ],
    isCollapse: true,
    classNameText: 'uppercase whitespace-nowrap'
  },
  {
    title: 'ROS Additions',
    checkList: [
      { value: 'ros_sinusitis_non', label: 'Sinusitis - Viral HPI' },
      { value: 'ros_sinusitis', label: 'Sinusitis - Non-Viral HPI' },
      { value: 'ros_uit_viral', label: 'UTI - Viral' },
      { value: 'ros_uti_non_viral', label: 'UTI - Non-Viral' }
    ],
    isCollapse: true,
    classNameText: 'justify-center'
  },
  {
    title: 'Exam',
    checkList: [
      { value: 'EX1', label: 'Quick Exam' },
      { value: 'EX2', label: 'Brief Exam (Default)' },
      { value: 'EX3', label: 'Telemedicine Exam' },
      { value: 'EX4', label: 'Pre - Op Exam' }
    ],
    isCollapse: true,
    classNameText: 'uppercase whitespace-nowrap'
  },
  {
    title: 'Exam Additions',
    checkList: [
      { value: 'exam_sinusitis_non', label: 'Sinusitis - Viral HPI' },
      { value: 'exam_sinusitis', label: 'Sinusitis - Non-Viral HPI' },
      { value: 'exam_uit_viral', label: 'UTI - Viral' },
      { value: 'exam_uti_non_viral', label: 'UTI - Non-Viral' }
    ],
    isCollapse: true,
    classNameText: 'justify-center'
  },
  {
    title: 'IMPRESSION / PLAN',
    checkList: [
      { value: 'IP_EX1', label: 'Quick Exam' },
      { value: 'IP_EX2', label: 'Brief Exam (Default)' },
      { value: 'IP_EX3', label: 'Telemedicine Exam' }
    ],
    isCollapse: true,
    classNameText: 'uppercase whitespace-nowrap'
  },
  {
    title: 'Additions',
    checkList: [
      { value: 'sinusitis_non', label: 'Sinusitis - Viral HPI' },
      { value: 'sinusitis', label: 'Sinusitis - Non-Viral HPI' },
      { value: 'uit_viral', label: 'UTI - Viral' },
      { value: 'uti_non_viral', label: 'UTI - Non-Viral' }
    ],
    isCollapse: true,
    classNameText: 'justify-center'
  }
];

const fakeRowClinician = [
  {
    short_code: 'STH',
    smart_insight_key: 'Sore Throat',
    smart_text: 'Sore Throat Phrase',
    parent_category: 'Chief Complaint'
  },
  {
    short_code: 'STH2',
    smart_insight_key: 'Sore Throat Child Option 1',
    smart_text: 'Sore Throat Phrase 1',
    parent_category: 'Sore Throat'
  },
  {
    short_code: 'STH3',
    smart_insight_key: 'Sore Throat Child Option 2',
    smart_text: 'Sore Throat Phrase 2',
    parent_category: 'Sore Throat'
  }
];

export default function SmartTexts({}: ISmartTextsProps) {
  const form = useForm();

  const navigate = useNavigate();

  const columns = [
    {
      field: 'short_code',
      headerName: 'Short Code'
    },
    {
      field: 'smart_insight_key',
      headerName: 'Smart Insight Key'
    },

    {
      field: 'smart_text',
      headerName: 'Smart Text Phrase'
    },
    {
      field: 'parent_category',
      headerName: 'Parent Category'
    },

    {
      field: 'actions',
      headerName: ' ',
      headerStyle: {
        width: '50px'
      },
      renderCell: (row, idx) => {
        return (
          <>
            <img
              id={`tooltip_plus_${idx}`}
              src={SVG.plus}
              alt="co-icon"
              className="icon-app cursor-pointer min-w-[17px]"
              onClick={() => {
                navigate(`/clinicians/1`);
              }}
            />
            <Tooltip anchorId={`tooltip_plus_${idx}`} content="Detail" />
          </>
        );
      }
    }
  ];

  //Fake data

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [toggle, setToggle] = useState(true);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);

  const [rows, setRows] = useState(
    getRowsByPage(fakeRowClinician, pageSize, currentPage)
  );

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(fakeRowClinician, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage]);

  return (
    <DefaultLayout>
      <div className="flex flex-col flex-1">
        <div className="">
          <Header.SmartTexts toggle={toggle} setToggle={setToggle} />
        </div>
        <div className="flex flex-col flex-1 p-2.5 gap-2.5">
          <div className="flex items-center justify-between">
            <Text>{pageSize} Records</Text>
            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
                  <div className="">
                    <p className="text-xs">{item.label} Records per page</p>
                  </div>
                  <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
                </div>
              )}
              options={PAGE_SIZE_OPTIONS}
              onChangeValue={(item: any) => {
                handleChangePageSize(item?.value);
              }}
            />
          </div>
          <div className="flex flex-1 gap-2.5">
            <Box.Standard
              className={`max-w-[180px] h-auto w-full transition-all ${
                toggle ? '' : '-translate-x-[150%] fixed'
              }`}
            >
              <FormProvider {...form}>
                <FilterForm filters={filters} title={{ text: 'Filter Clinicians by' }} />
              </FormProvider>
            </Box.Standard>
            <Box.Standard className="flex-1 h-auto p-0 overflow-hidden">
              <div className="w-full overflow-auto">
                <GridTable checkboxes={false} row={rows} column={columns} />
              </div>
            </Box.Standard>
          </div>
        </div>
        <Footer
          pagination={{
            total: fakeRowClinician?.length,
            pageSize: pageSize,
            onChangeCurrentPage: handleChangePage,
            onChangePageSize: handleChangePageSize
          }}
        />
      </div>
    </DefaultLayout>
  );
}
