import { CLINICIAN_DETAIL, CLINICIAN_DETAILS } from 'components/detail/data';
import DetailBox from 'components/detail/DetailBox';
import { FC, memo } from 'react';

interface ClinicianProps {
  clinicianData;
}

const ClinicianSummary: FC<ClinicianProps> = ({ clinicianData }) => {

  return (
    <div className="grid grid-cols-2 w-full">
      <div className="col-span-1 flex flex-col ">
        <DetailBox detailRows={CLINICIAN_DETAILS.left_summary(clinicianData)} />
      </div>
      <div className="col-span-1 flex flex-col">
        <DetailBox detailRows={CLINICIAN_DETAILS.right_summary(clinicianData)} />
      </div>
    </div>
  );
};

export default memo(ClinicianSummary);
