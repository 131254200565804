import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import Settings from 'components/settings/Settings';
import DefaultLayout from 'layouts/DefaultLayout';
import * as React from 'react';
import { ISettingBox } from 'types/setting.interface';
import { SETTINGS_LIST } from './data';

export interface ISettingsPageProps {}

export default function SettingsPage({}: ISettingsPageProps) {
  return (
    <DefaultLayout>
      <HeaderApp.Settings />
      <div className="flex-1 h-full p-2.5 flex flex-col">
        <div className="flex-1 grid grid-cols-5 grid-rows-2 gap-2.5 h-full">
          {SETTINGS_LIST.map((item: ISettingBox, idx) => (
            <div className="row-span-1 h-full" key={item.id}>
              <Settings.Box itemSetting={item} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
}
