import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import {
  FilterDepartment,
  FilterLocation,
  FilterOtherDepartment,
  FilterSystem
} from 'components/filterSide';
import FilterCheckList from 'components/filterSide/FilterCheckList';
import Form from 'components/form';
import { CONTENT_LIST } from 'pages/notes/data';
import { memo } from 'react';

export interface IAttachmentsFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

const checkListFormat = [
  {
    label: 'PDF',
    value: 'pdf'
  }
];
const checklistAuthor = [
  {
    label: 'Marcus Welby',
    value: 'marcus'
  }
];

function AttachmentsFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox
}: IAttachmentsFilterProps) {
  return (
    <Box.Standard className="w-full h-full">
      <div className="p-2.5 flex flex-col">
        <h4 className="font-bold text-xs flex tracking-[-0.03em] justify-between items-center">
          <span>Filter Attachments by</span>
          <img src={SVG.unfilter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' }
            }}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <FilterCheckList title="Format" checkList={checkListFormat} />
          <FilterCheckList title="Author" checkList={checklistAuthor} />
        </div>
      </div>
    </Box.Standard>
  );
}

AttachmentsFilter.displayName = 'Attachments.Filter';

export default memo(AttachmentsFilter);
