import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import { Note } from 'components/note';
import NoteComment from 'components/note/NoteComment';
import NoteDetail from 'components/note/NoteDetail';
import NoteSideBar from 'components/note/NoteSideBar';
import Staff from 'components/staffs';
import { BREADCRUMB } from 'constant/breadcrumbs';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import { fakeRow } from 'pages/notes/data';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { IBreadcrumb } from 'types';

const StaffDetail = () => {
  const navigate = useNavigate();

  const { CURRENT_ROLE } = useAuth();

  const { staffId } = useParams();

  const [staff, setStaff] = useState();

  // const noteDetail = fakeRow.find((item) => item.id === +noteId);

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: 'First Name Last Name - CO',
          addLabel: 'Start',
          labelDetail: 'CO Details - SA'
        }}
      />

      <div className="w-full flex flex-col flex-1 justify-center items-center p-2.5">
        <div className="w-full flex justify-between">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.STAFFS, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
            <p>{'Marcus Welby Jr. MD'}</p>
          </div>
          <p className="text-right self-end text-xs">Last Update: 23 hrs – 40 Min Ago</p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <NoteSideBar />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full  flex-1 h-auto gap-2.5">
            <Box.Standard className="px-[60px] py-[30px]">
              <Staff.Detail />
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default StaffDetail;
