import { DETAIL_INFO } from 'components/detail/data';
import DetailBox from 'components/detail/DetailBox';
import * as React from 'react';

export interface INoteCoderProps {
  notesData;
}

export default function NoteCoder({ notesData }: INoteCoderProps) {
  return (
    <div className="w-full flex gap-x-[30px]">
      <div className="flex flex-col flex-1 justify-between">
        <DetailBox title="Organization" detailRows={DETAIL_INFO.organization(notesData)} />
        <DetailBox title="Status CO" detailRows={DETAIL_INFO.status_co(notesData)} />
        <DetailBox title="Stats CO" detailRows={DETAIL_INFO.stats_co(notesData)} />
      </div>
      <div className="flex flex-co flex-1 justify-between">
        <DetailBox
          title="Clinician Messages"
          detailRows={DETAIL_INFO.clinician_message(notesData)}
        />
        <DetailBox title="Note Specifics" detailRows={DETAIL_INFO.note_specifics(notesData)} />
        <DetailBox
          title="Clinician Specifics"
          detailRows={DETAIL_INFO.clinician_specific(notesData)}
        />
      </div>
    </div>
  );
}
