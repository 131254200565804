import Checkbox from 'components/checkbox/Checkbox';
import { IGridTableProps } from 'types/gridTable.interface';
import { memo, useEffect, useRef, useState } from 'react';
import cn from "classnames";

const _ = require('lodash');

function GridTable({ column, row, checkboxes = true, className }: IGridTableProps) {
  //Checked Logic
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const handleCheckRows = (rows) => {
    if (!checkBoxes.find((item) => item === rows?.id)) {
      setCheckBoxes([...checkBoxes, rows?.id]);
    } else {
      const updateCheckboxes = checkBoxes.filter((item) => item !== rows.id);
      setCheckBoxes(updateCheckboxes);
    }
  };

  if (checkboxes) {
    column.unshift({
      field: 'check',
      headerName: (
        <Checkbox.Single
          className="mx-auto"
          value={checkAll}
          onChange={(checked) => {
            if (checked) {
              const _checkboxes = row.map((item) => item.id);
              setCheckBoxes(_checkboxes);
            } else {
              setCheckBoxes([]);
            }
          }}
        />
      ),
      renderCell: (rows) => {
        return (
          <Checkbox.Single
            className="mx-auto"
            value={checkBoxes.includes(rows.id)}
            onChange={(e) => handleCheckRows(rows)}
          />
        );
      }
    });
  }

  useEffect(() => {
    if (checkBoxes?.length === row?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxes]);

  column = _.uniqBy(column, 'field');

  return (
    <table className={cn('table-auto w-full h-full z-0', className)}>
      <thead>
        <tr>
          {column.map((column, idx) => {
            return (
              !column?.hideCol && (
                <th
                  key={column.field}
                  className="p-table text-center border font-[600] text-xs  whitespace-nowrap  min-w-fit"
                  style={column?.headerStyle}
                >
                  {column.headerName}
                </th>
              )
            );
          })}
        </tr>
      </thead>
      <tbody>
        {row?.map((row, idx) => {
          return (
            <tr key={idx}>
              {column?.map((column) => {
                return (
                  !column?.hideCol && (
                    <td
                      key={column.field}
                      className="h-full text-xs text-center border-b p-table whitespace-nowrap min-w-fit"
                      style={column?.rowStyle}
                    >
                      {column?.renderCell && !!column?.renderCell(row, idx)
                        ? column?.renderCell(row, idx)
                        : row[column?.field]}
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default memo(GridTable);
