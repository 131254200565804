import { API, Auth } from 'aws-amplify';
import { config } from 'config';

export const createNewUser = async (user) => {
  const response = await API.post(config.API_NAME, '/users/add', {
    body: user
  });
  return response;
};

export const createNewCorp = async (corp) => {
  return await API.post(config.API_NAME, '/corporate-record', {
    body: corp
  });
};

export const getOrganizationListAPI = async (primaryAccountId) => {
  return await API.get(config.API_NAME, `/corporate-records/${primaryAccountId}`, {});
};

export const getOrganizationAPI = async ({ primaryAccountId, corporateRecordId }) => {
  return await API.get(
    config.API_NAME,
    `/corporate-record/${primaryAccountId}/${corporateRecordId}`,
    {}
  );
};

export const createOrganizationAPI = async (body) => {
  const response = await API.post(config.API_NAME, `/corporate-record`, {
    body
  });
  return response;
};

export const updateOrganizationAPI = async ({ body, primaryAccountId, organizationId}) => {
  const response = await API.put(config.API_NAME, `/corporate-record/${primaryAccountId}/${organizationId}`, {
    body
  });
  return response;
};

export const getUserListAPI = async ({ primaryAccountId, userRole }) => {
  return await API.post(config.API_NAME, `/users/${primaryAccountId}/${userRole}`, {});
};

export const getUserAPI = async ({ primaryAccountId, userRecordId }) => {
  return await API.get(config.API_NAME, `/user/${primaryAccountId}/${userRecordId}`, {});
};

export const createUserAPI = async (body) => {
  const response = await API.post(config.API_NAME, `/users/add`, {
    body
  });
  return response;
};

export const updateUserAPI = async ({ body, primaryAccountId, userRecordId }) => {
  const response = await API.put(config.API_NAME, `/user/${primaryAccountId}/${userRecordId}`, { body });
  return response;
};

// Tread Record
export const getTreatmentRecordsAPI = async (primaryAccountId) => {
  const res = await API.get(config.API_NAME, `/treatment-records/${primaryAccountId}`, {});
  return res;
};
