import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { useAuth } from 'hooks/useAuth';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';

export interface IHeaderBuilderProps {
  toggle: boolean;
  setToggle?: (toggle: any) => boolean;
}

export default function HeaderBuilder({ toggle, setToggle }: IHeaderBuilderProps) {
  const { CURRENT_ROLE, currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex justify-between">
      <ul className="flex items-center list-none text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={toggle ? SVG.filter : SVG.unfilter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={() => setToggle((prev) => !prev)}
          />
        </li>
        <li>
          <Button.White
            className="!p-default"
            color="white"
            label="Notes List - Mgr."
            icon={SVG.chevron_down}
            onClick={() => {}}
          />
        </li>
      </ul>
      <div className="flex-1 flex justify-center">
        <div
          className="flex items-center  text-sm text-black font-bold gap-x-[5px] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
          <p>Marcus Welby Jr. MD</p>
        </div>
      </div>
    </div>
  );
}
