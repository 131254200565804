import { SVG } from 'assets/imagePath';
import { DETAIL_INFO } from 'components/detail/data';
import DetailBox from 'components/detail/DetailBox';
import { Dropdown } from 'components/dropdown';
import * as React from 'react';
import { getFormatedDate } from 'utils';

export interface IOrganizationDetailProps {
  orgData: any
}

export default function OrganizationDetail({orgData}: IOrganizationDetailProps) {
  const ORGANIZATION = [
    {
      subTitle: '*Organization',
      className: {
        classNameContent: 'font-bold'
      },
      content: `${orgData?.orgFullName || '-'}`
    },
    {
      subTitle: '*Org Short',
      content: `${orgData?.orgShortName || '-'}`
    },
    {
      subTitle: '*Org. Initials',
      content: `${orgData?.orgInitials || '-'}`
    },
    {
      subTitle: 'Site Names',
      content: 'Site Names'
    }
  ];
  const KEY_INFO = [
    {
      subTitle: 'Important',
      content: 'Text - Do not Call',
      className: {
        classNameContent: 'font-bold text-error'
      }
    },
    {
      subTitle: 'Organization Time Zone',
      className: {
        classNameContent: 'font-bold '
      },
      content: `${orgData?.timeZone || '-'}`
    },
    {
      subTitle: '*Service Phone #',
      content: '1-800-538-4244'
    }
  ];

  const IDENTIFICATION = [
    {
      subTitle: '*Start Date',
      content: `${getFormatedDate(orgData?.dateStarted)}`
    },
    {
      subTitle: '*Organization ID',
      content: `${orgData?.corporateRecordId || '-'}`
    },

    {
      subTitle: 'Service Level',
      className: {
        classNameContent: 'font-bold mr-[24px] md:text-left'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex justify-end">
          <Dropdown.Input
            label="Full Service"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Full Service',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] font-normal text-xs"
            classNameIcon="!m-0"
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'Priority',
      className: {
        classNameContent: 'font-bold mr-[24px] md:text-left'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex justify-end">
          <Dropdown.Input
            label="Neutral"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Neutral',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] font-normal text-xs"
            classNameIcon="!m-0"
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'Ranking',
      className: {
        classNameContent: 'font-bold mr-[24px] md:text-left'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex justify-end">
          <Dropdown.Input
            label="Top 10%"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Top 10%',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] font-normal text-xs"
            classNameIcon="!m-0"
            classNameMenu="absolute"
          />
        </div>
      )
    }
  ];

  const ADDRESS = [
    {
      subTitle: 'Street',
      content: `${orgData?.streetAddress || '-'}`
    },
    {
      subTitle: 'City',
      content: `${orgData?.city || '-'}`
    },
    {
      subTitle: 'State',
      content: `${orgData?.stateOf || '-'}`
    },
    {
      subTitle: 'Postal Code',
      content: `${orgData?.zipCode || '-'}`
    }
  ];

  return (
    <div className="w-full flex gap-x-[30px]">
      <div className="flex w-full flex-col">
        <DetailBox title="Organization" detailRows={ORGANIZATION} />
        <DetailBox title="Key Info" detailRows={KEY_INFO} />
        <DetailBox title="Identification" detailRows={IDENTIFICATION} />
      </div>
      <div className="flex w-full flex-col">
        <div className="flex flex-col mb-[25px]">
          <DetailBox title="Address" detailRows={ADDRESS} />
        </div>
      </div>
    </div>
  );
}
