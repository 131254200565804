import OrganizationDetail from './OrganizationDetail';
import OrganizationSummary from './OrganizationSummary';
import OrganizationsFilter from './OrganizationsFilter';

const Organization = {
  Filter: OrganizationsFilter,
  Summary: OrganizationSummary,
  Detail: OrganizationDetail
};

export default Organization;
