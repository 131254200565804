import React from 'react';
import NoteComment from './NoteComment';
import NoteManager from './NoteManger';
import NoteSideBar from './NoteSideBar';
import NoteSummary from './NoteSummary';

const Note = () => {
  return <React.Fragment />;
};

Note.Summary = NoteSummary;
Note.SideBar = NoteSideBar;
Note.Comment = NoteComment;
Note.Manager = NoteManager;

export default Note;
