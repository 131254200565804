import { useAuth } from 'hooks/useAuth';
import { useId } from 'react';
import { CURRENT_ROLE } from '../../../constant/auth';
export const CONTENT_LIST = [
  {
    title: 'System Filters',
    check_list: [
      {
        label: 'Oldest First',
        name: 'odest_frist'
      },
      {
        label: 'Order of Arrival',
        name: 'order_arrival'
      },
      {
        label: 'Priority',
        name: 'priority'
      },
      {
        label: 'Untouched Records',
        name: 'untouch_record'
      },
      {
        label: 'Touched Records',
        name: 'touch_record'
      }
    ]
  },
  {
    title: 'Clinician Order',
    check_list: [
      {
        label: 'By Clinician',
        name: 'by_clinician',

        collapse: true
      },
      {
        label: 'By Location',
        name: 'by_location',

        collapse: true
      },
      {
        label: 'By Site',
        name: 'by_site',

        collapse: true
      },
      {
        label: 'By Organization',
        name: 'by_organization',

        collapse: true
      }
    ]
  },
  {
    title: 'Staff Order',
    check_list: [
      {
        label: 'By Doc Spec',
        name: 'by_doc'
      },
      {
        label: 'By Coder',
        name: 'by_coder',

        isHide: (() => {
          const { CURRENT_ROLE } = useAuth();

          return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
        })()
      }
    ]
  },
  {
    title: 'Doc Specialist',
    isHide: (() => {
      const { CURRENT_ROLE } = useAuth();

      return ['CODERS'].includes(CURRENT_ROLE);
    })(),

    check_list: [
      {
        label: 'DS – New',
        name: 'doc_new'
      },
      {
        label: 'DS – ? Answered',
        name: 'doc_ans'
      },
      {
        label: 'DS – Overdue All',
        name: 'doc_overdue'
      },
      {
        label: 'DS – 1 Hr Before',
        name: 'doc_1hr'
      },
      {
        label: 'DS – 2 Hr Before',
        name: 'doc_2hr'
      },
      {
        label: 'DS – 4 Hr Before',
        name: 'doc_4hr'
      },
      {
        label: 'DS – 8 Hr Before',
        name: 'doc_8hr'
      }
    ]
  },
  {
    title: 'CO – Overdue',
    isHide: (() => {
      const { CURRENT_ROLE } = useAuth();

      return ['DOCS'].includes(CURRENT_ROLE);
    })(),
    check_list: [
      {
        label: 'CO – New',
        name: 'co_new'
      },
      {
        label: 'CO – ? Answered',
        name: 'co_ans'
      },
      {
        label: 'CO – Overdue All',
        name: 'co_all'
      },
      {
        label: 'CO – 1 Hr Before',
        name: 'co_1hr'
      },
      {
        label: 'CO – 2 Hr Before',
        name: 'co_2hr'
      },
      {
        label: 'CO – 4 Hr Before',
        name: 'co_4hr'
      },
      {
        label: 'CO – 8 Hr Before',
        name: 'co_8hr'
      }
    ]
  }
];

export const fakeRow = [
  // {
  //   status_table: 'NEW',
  //   clinician: 'Welby, Marcus Jr',
  //   deg: 'MD',
  //   org: 'WC',
  //   patiant: 'Langley, Robert L',
  //   ds: 'MAC',
  //   co: 'JLM',
  //   lead: 'BLM',
  //   dur: '2:32',
  //   icd: '536.80',
  //   eNm: '3',
  //   time: '30:00',
  //   loc: 'HOSP',
  //   appt: true,
  //   qnA: [
  //     {
  //       id: 1,
  //       isNotAnswer: true,
  //       chatBox: [
  //         {
  //           userName: 'DS',
  //           mess: 'What was the Pain Level ?',
  //           type: 'Q',
  //           time: '9:32 PM'
  //         },
  //         { userName: 'MD', mess: 'Pain Level is a 7', type: 'A', time: '5 min ago' }
  //       ]
  //     },
  //     {
  //       id: 2,
  //       chatBox: [
  //         { userName: 'DS', mess: 'Please set the Appointment!', type: 'Q', time: '9:32 PM' },
  //         { userName: 'MD', mess: 'Appointment set in EMR', type: 'A', time: '5 min ago' }
  //       ]
  //     }
  //   ],
  //   important: 'No Calling!',
  //   message: ''
  // },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 1,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 2,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 3,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 4,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 5,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 6,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 7,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 8,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 9,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 10,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 11,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 12,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 13,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 14,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 15,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 16,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 17,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 18,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 19,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 20,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 21,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 22,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 23,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 24,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 25,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 26,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 27,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 28,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 29,
    status_table: 'CODING',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',

    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: true,
    dsQ: false,
    coQ: true,

    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 30,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  },
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id: 31,
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L',
    ds: 'MAC',
    co: 'JLM',
    lead: 'BLM',
    dur: '2:32',
    icd: '536.80',
    eNm: '3',
    time: '30:00',
    loc: 'HOSP',
    appt: false,
    dsQ: true,
    coQ: false,
    important: 'Text – Do Not Call!',
    message: 'Need By 3:00 pm'
  }
];
