import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import { Builder } from 'components/builder';
import BuilderPlayer from 'components/builder/BuilderPlayer';
import Button from 'components/button/Button';
import Checkbox from 'components/checkbox/Checkbox';
import { Collapse } from 'components/collapse';
import { Dropdown } from 'components/dropdown';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import { Search } from 'components/search';
import DefaultLayout from 'layouts/DefaultLayout';
import * as React from 'react';
import { CONTENT_LIST, INSIGHT_LIST, SMARTS_NOTES } from './data';

export interface IBuilderPageProps {}

export default function BuilderPage({}: IBuilderPageProps) {
  const [content, setContent] = React.useState(CONTENT_LIST[0]);

  const handleSaveContent = (html) => {
    setContent(html);
  };

  const [toggle, setToggle] = React.useState(false);

  const [filterData, setFilterData] = React.useState({
    dataInsight: {},
    dataSmart: {}
  });
  const handleSubmit = React.useCallback((dataInsight, dataSmart) => {
    if (dataInsight) {
      // setFilterData((prev) => ({
      //   ...prev,
      //   dataInsight
      // }));
      console.log(dataInsight);
    }
    if (dataSmart) {
      setFilterData((prev) => ({
        ...prev,
        dataSmart
      }));
    }
  }, []);

  return (
    <DefaultLayout>
      <HeaderApp.Builder toggle={toggle} setToggle={setToggle} />
      <div className="fixed w-full top-[107px] right-0 left-0 bottom-[50px] flex-1 flex p-2.5 mx-auto gap-2.5 overflow-y-auto">
        <Box.Standard
          className={`max-w-[280px] h-fit transition-all  ${
            toggle ? 'fixed -translate-x-[150%] ' : ''
          }`}
        >
          <Builder.SmartInsight onGetData={handleSubmit} />
        </Box.Standard>
        <div className="flex-1 flex flex-col items-stretch gap-y-2.5  w-full relative">
          <Box.Standard className="h-auto px-5">
            <div
              className={`w-full grid grid-cols-2 text-xs gap-y-2.5 gap-x-5  items-center ${
                toggle ? 'md:grid-cols-4' : '2xl:grid-cols-4'
              }`}
            >
              <div className="col-span-1 flex justify-between   text-dark-silver">
                <div className="col-span-1">Patiant</div>
                <div className="col-span-2 font-bold">Robert L. Langley</div>
              </div>
              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">DOS :</div>
                <div className="col-span-2 font-bold">Mon 7/25/2022</div>
              </div>
              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">DOS</div>
                <div className="col-span-2 font-bold text-success">What is Pain Level ?</div>
              </div>
              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">Important:</div>
                <div className="col-span-2 font-bold text-error">Text – Do Not Call!</div>
              </div>
              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">Status</div>
                <div className="col-span-2">
                  <div className="w-max text-left ml-auto z-0">
                    <Dropdown.Input
                      label="New - To Transcribe"
                      icon={SVG.arrow_down}
                      options={[
                        {
                          label: 'New - To Transcribe',
                          value: 'New'
                        }
                      ]}
                      className={`p-0 hover:bg-transparent text-2.5`}
                      classNameText="px-[10px] py-[0px] font-bold text-xs"
                      classNameIcon="!m-0 w-5 h-3"
                      classNameMenu="absolute"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">Nurse Appt</div>
                <div className="col-span-1 font-bold">
                  <span className="text-error">Y</span>/N
                </div>
              </div>
              <div className="col-span-1 flex justify-between  text-dark-silver">
                <div className="col-span-1">CLIN?</div>
                <div className="col-span-2 font-bold text-error">Pain Level is a 7</div>
              </div>
              <div className="col-span-1 flex justify-between   text-dark-silver">
                <div className="col-span-1">Note Message</div>
                <div className="col-span-1 font-bold text-error">Need By 3:00 pm</div>
              </div>
            </div>
          </Box.Standard>

          <Box.Standard className="h-full">
            <div className="w-full h-auto flex flex-col gap-y-[30px] px-[65px] py-[50px]">
              {/* {contentList.map((content, idx) => ( */}
                <div className="w-full flex-1 h-full flex flex-col justify-between">
                  <Builder.Content
                    html={content.html}
                    onSave={(html) => handleSaveContent(html)}
                  />
                </div>
              {/* ))} */}
            </div>
          </Box.Standard>
          <Box.Standard
            className={`w-full sticky bottom-[0] h-auto flex justify-center gap-2.5 items-center flex-col ${
              toggle ? 'lg:flex-row' : 'xl:flex-row'
            }`}
          >
            <Box.Standard className="w-fit">
              <BuilderPlayer toggle={toggle} />
            </Box.Standard>
            <div className="flex flex-row gap-x-2.5">
              <Button.White
                color="white"
                className="!p-default text-sm h-fit"
                label="Edit"
                onClick={() => {}}
              />

              <Button.White
                color="white"
                className="!p-default text-sm h-fit"
                label="Save"
                onClick={() => {}}
              />
              <Button.Primary
                className="w-fit !p-default text-sm"
                label="Done"
                onClick={() => {}}
              />
            </div>
          </Box.Standard>
        </div>

        <Box.Standard
          className={`max-w-[280px] h-fit transition-transform right-0  ${
            toggle ? 'fixed translate-x-[150%] ' : ''
          }`}
        >
          <Builder.SmartNote onGetData={handleSubmit} />
        </Box.Standard>
      </div>
      <Footer />
    </DefaultLayout>
  );
}
