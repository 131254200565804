import { RouterPath } from 'routes/router.constant';
import { ISettingBox } from 'types/setting.interface';

export const SETTINGS_LIST: ISettingBox[] = [
  {
    id: 'general',
    title: 'General',
    settings: [
      { id: 'personal', label: 'Personal Settings', url: '' },
      { id: 'organization', label: 'Organization Details', url: '' },
      { id: 'work_calendar', label: 'Work Calendar', url: '' },
      { id: 'service_phone', label: 'Service Phone #', url: '' }
    ]
  },
  {
    id: 'user_control',
    title: 'USERS AND CONTROL',
    settings: [
      { id: 'user_status', label: 'Users - User Status', url: '' },
      { id: 'security', label: 'Security Control', url: '' },
      { id: 'time_zones', label: 'Time Zones', url: '' },
      { id: 'clinician_staff_assignments', label: 'Clinician Staff Assignments', url: '' },
      { id: 'link_default', label: 'Links Default', url: '' }
    ]
  },
  {
    id: 'notes',
    title: 'NOTES',
    settings: [
      { id: 'patient_data', label: 'Patient - Data', url: '' },
      { id: 'clinician_recorded_notes', label: 'Clinician - Recorded Notes', url: RouterPath?.NOTES },
      { id: 'sla_deadline', label: 'SLA - Deadlines', url: '' },
      { id: 'place_of_service', label: 'Place of Service', url: '' },
      { id: 'recording_stat', label: 'Recording Stats', url: '' },
      { id: 'notes_app', label: 'Notes App Stats', url: '' },
      { id: 'worklist_status', label: 'Worklist Status - DS CO', url: '' },
      { id: 'request', label: 'Requests', url: '' }
    ]
  },
  {
    id: 'ORGANIZATIONS',
    title: 'ORGANIZATIONS',
    settings: [
      { id: 'Organizations', label: 'Organizations', url: RouterPath.ORGANIZATIONS },
      { id: 'locations', label: 'Locations', url: '' }
    ]
  },
  {
    id: 'CLINICIANS',
    title: 'CLINICIANS',
    settings: [
      { id: 'clinicians', label: 'Clinicians', url: RouterPath.CLINICIANS },
      { id: 'CL_Stats', label: 'CL - Stats', url: '' },
      { id: 'degree', label: 'Degree', url: '' },
      { id: 'other_degrees', label: 'Other Degrees', url: '' },
      { id: 'specialty', label: 'Specialty', url: '' },
      { id: 'specialties_other', label: 'Specialties - Other', url: '' },
      { id: 'department', label: 'Department', url: '' },
      { id: 'location', label: 'Location', url: '' },
      { id: 'clinician_places', label: 'Clinician Places', url: '' },
      { id: 'priority', label: 'Priority', url: '' },
      { id: 'service_level', label: 'Service Level', url: '' },
      { id: 'clinician_record_status', label: 'Clinician Record Status', url: '' },
      { id: 'clinician_ranking', label: 'Clinician Ranking', url: '' },
      { id: 'app_settings', label: 'APP Settings', url: '' },
      { id: 'clinician_email_templates', label: 'Clinician Email Templates', url: '' }
    ]
  },
  {
    id: 'DOCUMENT SPECIALISTS',
    title: 'DOCUMENT SPECIALISTS',
    settings: [
      { id: 'DS_stats', label: 'DS - Stats', url: '' },
      { id: 'DS_Clarify_Questions', label: 'DS - Clarify Questions', url: '' },
      { id: 'DS_Message_Email_Templates', label: 'DS - Message / Email Templates', url: '' },
      { id: 'DS_Reports', label: 'DS - Reports', url: '' },
      { id: 'DS - Command List', label: 'DS - Command List', url: '' }
    ]
  },
  {
    id: 'CODERS',
    title: 'CODERS',
    settings: [
      { id: 'CO_stats', label: 'CO - Stats', url: '' },
      { id: 'CO_Clarify_Questions', label: 'CO - Clarify Questions', url: '' },
      { id: 'CO_Message_Email_Templates', label: 'CO - Message / Email Templates', url: '' },
      { id: 'CO_Reports', label: 'CO - Reports', url: '' }
    ]
  },
  {
    id: 'Manager',
    title: 'Manager',
    settings: [
      { id: 'MGR_stats', label: 'MGR - Stats', url: '' },
      { id: 'MGR_Clarify_Questions', label: 'MGR - Clarify Questions', url: '' },
      { id: 'MGR_Message_Email_Templates', label: 'MGR - Message / Email MGR -', url: '' },
      { id: 'MGR_Templates', label: 'MGR - Templates', url: '' },
      { id: 'LE_Reports', label: 'LE - Reports', url: '' }
    ]
  },
  {
    id: 'System Admins',
    title: 'System Admins',
    settings: [
      { id: 'Roles_Permissions', label: 'Roles & Permissions', url: '' },
      { id: 'Data_Administration', label: 'Data Administration', url: '' },
      { id: 'Mobile_App_Dictionary', label: 'Mobile App Dictionary', url: '' },
      { id: 'Admin_Panel_Dictionary', label: 'Admin Panel Dictionary', url: '' },
      { id: 'Clinician_Database', label: 'Clinician Database', url: '' },
      { id: 'Doc_Spec_Database', label: 'Doc Spec Database', url: '' },
      { id: 'Coder_Database', label: 'Coder Database', url: '' },
      { id: 'Manager Database', label: 'Manager Database', url: '' }
    ]
  },
  {
    id: 'OTHER',
    title: 'OTHER',
    settings: [
      { id: 'Personal_Settings', label: 'Personal Settings', url: '' },
      { id: 'Company Details', label: 'Company Details', url: '' }
    ]
  }
];
