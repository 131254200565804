export const fakeColAttach = [
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  },
  {
    name: 'Attachments 1',
    format: 'PDF',
    author: 'Marcus Welby'
  }
];
