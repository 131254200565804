import { SVG } from 'assets/imagePath';
import Button from 'components/button';
import { useNavigateSearch } from 'hooks';
import { useCallback } from 'react';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  icon: string;
  title: string;
  subtitle: string;
  canGoBack?: boolean;
};

const AuthHeader: FC<Props> = ({ icon, title, subtitle, canGoBack }) => {
  const navigateSearch = useNavigateSearch();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="w-full mx-auto">
      <div>
        <div className="relative">
          <img
            src={icon}
            className="max-w-[94px] max-h-[94px] w-full h-full m-auto object-contain"
            alt="logo"
          />
          {canGoBack && (
            <div className="absolute top-[50%] left-0  translate-y-[-50%] flex content-center border border-nav p-[5px] rounded-[5px]">
              <Button.Icon icon={SVG.back_icon} onClick={handleClick} />
            </div>
          )}
        </div>
        <p className="text-label text-xl text-center font-inter font-bold my-5 w-full">{title}</p>
      </div>
      <p className="w-full text-input text-xs text-center font-inter mx-auto">{subtitle}</p>
    </div>
  );
};

AuthHeader.displayName = 'Auth.Header';

export default memo(AuthHeader);
