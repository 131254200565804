import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import { Note } from 'components/note';
import NoteComment from 'components/note/NoteComment';
import NoteDetail from 'components/note/NoteDetail';
import NoteSideBar from 'components/note/NoteSideBar';
import { BREADCRUMB } from 'constant/breadcrumbs';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import { fakeRow } from 'pages/notes/data';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { IBreadcrumb } from 'types';

const NoteDetailPage = () => {
  const [searchVal, setSearchValue] = useState<string>('');
  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };

  const breadcrumbs: IBreadcrumb[] = BREADCRUMB.find(
    (item) => item.url === location.pathname
  )?.breadcrumbs;
  const navigate = useNavigate();

  const { CURRENT_ROLE } = useAuth();
  // useEffect(() => {
  //   if (CURRENT_ROLE === 'CODERS') {
  //     navigate(RouterPath.NOTES);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { noteId } = useParams();

  const noteDetail = fakeRow[0];

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: noteDetail.clinician,
          addLabel: 'Start',
          labelDetail: 'Notes List - MGR'
        }}
      />

      <div className="fixed w-full top-[107px] right-0 left-0 bottom-[50px] flex flex-col flex-1 justify-center items-center p-2.5 overflow-y-auto">
        <div className="w-full flex justify-between">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.NOTES, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
            <p>{noteDetail?.patiant || ''}</p>
          </div>
          <p className="text-right self-end text-xs">Last Update: 23 hrs – 40 Min Ago</p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <NoteSideBar />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full  flex-1 gap-2.5">
            <Box.Standard className="h-auto">
              <Note.Summary notesData={noteDetail} />
            </Box.Standard>
            <Box.Standard className="h-full w-full px-[60px] py-[30px]">
              <NoteDetail notesData={noteDetail} />
            </Box.Standard>
            <Box.Standard className="h-full p-[15px]">
              <NoteComment />
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default NoteDetailPage;
