import { Box } from 'components/box';
import Footer from 'components/footer/Footer';
import EditableVerticalTable from 'components/gridTable/EditableVerticalTable';
import { HeaderApp } from 'components/header';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { createUserAPI, getUserAPI, updateUserAPI } from 'services';
import { ITableColumn } from 'types';
import { capitalizeName } from 'utils/text';

interface IDocAssistantEditPage {}

const DocAssistantEditPage: React.FC<IDocAssistantEditPage> = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { userInfo } = state;
  const { dcId } = useParams();
  const form = useForm({});
  const [fetchDC, loading, dcDetail] = useApiCall(getUserAPI);
  const [createUser] = useApiCall(createUserAPI);
  const [updateUser] = useApiCall(updateUserAPI);
  const isNew = dcId?.toLowerCase() === 'new' ? true : false;
  const titleOpts = [
    { label: `${isNew ? 'Add' : 'Edit'} Clinician Details - MGR`, value: 'title-1' }
  ];
  const actionOpts = [{ label: 'Action', value: 'edit', onClick: () => {} }];

  useEffect(() => {
    if (isNew || !userInfo) return;
    fetchDC({ primaryAccountId: userInfo?.['custom:superId'], userRecordId: dcId });
  }, [dcId, userInfo]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  useEffect(() => {
    if (!dcDetail || isNew) return;
    Object.keys(dcDetail).forEach((key) => {
      if (key === 'dateStarted') {
        form.setValue(key, new Date(dcDetail?.[key]));
        return;
      }
      form.setValue(key, dcDetail?.[key]);
    });
  }, [dcDetail]);

  const dcDetailColumn: ITableColumn[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'Initials',
      headerName: 'initials',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'nickName',
      headerName: 'Nickname',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'organiztion',
      headerName: 'Organiztion',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    }
  ];

  const dcContactColumn: ITableColumn[] = [
    {
      field: 'email',
      headerName: 'Email',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'cellPhone',
      headerName: 'Cell phone',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'degree',
      headerName: 'Degree',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'title',
      headerName: 'Staff title',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
    {
      field: 'specialty',
      headerName: 'Specialty Experience',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center'
      }
    },
  ]

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true
      });
      if (isNew) {
        await createUser({
          ...data,
          initials: capitalizeName(data?.firstName, data?.lastName, data?.middleName),
          userRole: 'Document_Specialist',
          primaryAccountId: userInfo?.['custom:superId']
        });
      } else {
        await updateUser({
          body: {
            ...data,
            initials: capitalizeName(data?.firstName, data?.lastName, data?.middleName),
            userRole: 'Document_Specialist'
          },
          userRecordId: dcId,
          primaryAccountId: userInfo?.['custom:superId']
        });
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
      // navigate(`/organization/${organizationId}`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false
        });
      }, 100);
    }
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
      />
      <Box.Standard className="flex-1 my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <EditableVerticalTable
              isEdit={true}
              columns={dcDetailColumn}
              row={!isNew && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={dcContactColumn}
              row={!isNew && form.getValues()}
            />
            {/* <EditableVerticalTable
              isEdit={true}
              columns={ColOther}
              row={!isNew && form.getValues()}
            /> */}
          </form>
        </FormProvider>
      </Box.Standard>
      <Footer />
    </DefaultLayout>
  );
};

export default DocAssistantEditPage;
