import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import SelectedButton from './components/SelectedButton';
import { IDropdown } from 'types';

export interface IHederEditProps {
  title: {
    options: IDropdown[];
  };
  actions: {
    options: IDropdown[];
  };
  onSave: () => void;
  saveDisabled?: boolean;
}

export default function HeaderEdit({ title, actions, onSave, saveDisabled }: IHederEditProps) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between">
      <ul className="flex max-w-[400px] w-full items-center list-none  text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.arrow_prev}
            variant="outlined"
            className="bg-[#f3f3f3]"
            // onClick={() => {}}
            onClick={() => navigate(-1)}
          />
        </li>
        <li className="flex">
          <div>
            <SelectedButton
              propsButton={{ variant: 'outlined', color: 'secondary', className: 'px-[15px]' }}
              options={title.options}
            />
          </div>
        </li>
      </ul>
      <div className="hidden lg:flex flex-1 justify-center items-center text-xs text-black font-bold">
        <p>Betty MacDonald – MRG</p>
      </div>

      <ul className="flex  max-w-[400px] w-full items-center justify-end list-none text-center gap-2.5">
        <li>
          <Button.Common disabled={saveDisabled} onClick={onSave}>Save</Button.Common>
        </li>

        <li>
          <SelectedButton
            propsButton={{
              variant: 'contained',
              color: 'whiteLight',
              className: 'px-[15px]',
              textClass: '!text-black'
            }}
            options={actions.options}
          />
        </li>
        <li>
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </div>
  );
}
