import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import Button from 'components/button/Button';
import { FiPlus } from 'react-icons/fi';
import FilterCheckList from 'components/filterSide/FilterCheckList';
import { GridTable } from 'components/gridTable';
import { HeaderApp } from 'components/header';
import Organization from 'components/organizations';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { ITableColumn } from 'types';
import { fakeDataOrganization } from './data';
import { DEFAULT_LIMIT } from 'constant/pagination';
import Footer from 'components/footer/Footer';
import { useApiCall } from 'hooks';
import { getOrganizationListAPI } from 'services';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import { ORGANIZATION_COLUMNS, SET_LOADING_MODAL } from 'constant';
import { getRowsByPage } from 'utils';
import { useNavigate } from 'react-router-dom';

export interface IOrganizationPageProps {}

export default function OrganizationPage({}: IOrganizationPageProps) {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [fetchOrgList, loading, orgList = []] = useApiCall(getOrganizationListAPI);
  const [toggle, setToggle] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState(getRowsByPage(orgList, pageSize, currentPage));

  const newColumns = [
    ...ORGANIZATION_COLUMNS,
    ...[
      {
        field: 'actions',
        headerName: ' ',
        renderCell: (row) => {
          return (
            <FiPlus
              className="text-gray-active w-4 h-4 cursor-pointer"
              onClick={() => {
                navigate(`/organization/${row?.corporateRecordId}`);
              }}
            />
          );
        }
      }
    ]
  ];

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!userInfo) return;
    fetchOrgList(userInfo?.['custom:superId']);
  }, [userInfo]);

  useEffect(() => {
    const newRows = getRowsByPage(orgList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, orgList]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    })
  }, [loading]);

  const form = useForm({
    defaultValues: {},
    mode: 'onChange'
    // resolver: FormResolver(useLoginSchema())
  });

  const handleFilter = (data) => {
    console.log(data);
  };

  return (
    <DefaultLayout>
      <HeaderApp.Organization toggle={toggle} setToggle={setToggle} />
      <div className="w-full h-full flex flex-col flex-1 items-center  p-2.5">
        <div className="w-full flex justify-between items-center">
          <p className="font-normal">
            Records:
            {/* <span className="font-bold">{pageSize}</span> */}
          </p>
          <div className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label="10 Records per page"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="flex gap-2.5 items-stretch h-full w-full mt-2.5">
          <div className={`w-[180px]  transition-all ${toggle ? '' : '-translate-x-[150%] fixed'}`}>
            <FormProvider {...form}>
              <form className="h-full">
                <Organization.Filter
                  onClearFilter={() => form.reset({})}
                  onSubmitFilter={form.handleSubmit(handleFilter)}
                  onClearSearchBox={() =>
                    form.reset((currentValues) => ({ ...currentValues, search_box: '' }))
                  }
                />
              </form>
            </FormProvider>
          </div>
          <div className="flex-1 h-full overflow-auto relative">
            <Box.Standard className="flex w-full h-full overflow-auto flex-col  p-0 ">
              <div className="!overflow-auto  w-full max-h-full z-0">
                <GridTable column={newColumns} row={rows} />
              </div>
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer
        pagination={{
          total: orgList?.length,
          pageSize: pageSize,
          onChangeCurrentPage: handleChangePage,
          onChangePageSize: handleChangePageSize
        }}
      />
    </DefaultLayout>
  );
}
