import { Box } from 'components/box';
import Checkbox from 'components/checkbox';
import { Search } from 'components/search';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { CONTENT_LIST, fakeRow } from './data';
import { GridTable } from 'components/gridTable';
import { Dropdown } from 'components/dropdown';
import { ITableColumn } from 'types';
import { SVG } from 'assets/imagePath';
import { useApiCall } from 'hooks';
import { getUserListAPI } from 'services';
import { GlobalContext } from 'context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LIMIT, PAGE_SIZE_OPTIONS, SET_LOADING_MODAL } from 'constant';
import { getRowsByPage } from 'utils';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import { Text } from 'components/text';

const statusOptions = [
  { value: 'CODING', label: 'Coding' },
  { value: 'NEW', label: 'New' }
];

const CoderList = () => {
  const [fetchClinician, loading, assistantList] = useApiCall(getUserListAPI);
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [searchVal, setSearchValue] = useState<string>('');
  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState();
  const [toggle, setToggle] = useState(false);

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(assistantList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, assistantList]);

  useEffect(() => {
    if (!userInfo) return;
    fetchClinician({
      primaryAccountId: userInfo?.['custom:superId'],
      userRole: 'Document_Specialist'
    });
  }, [userInfo]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    });
  }, [loading]);

  const fakeColumn: ITableColumn[] = [
    {
      field: 'status_table',
      headerName: 'Status',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
        paddingLeft: '7px',
        paddingRight: '7px'
      },

      renderCell: (row) => {
        return (
          <div
            className={`w-max text-[10px] text-center mx-auto z-0  border border-light-gray !rounded-full !px-[10px] py-[3px] text-2.5 ${
              row?.status_table === 'CODING' ? '!bg-pearl' : ''
            }`}
          >
            <p>{row?.status_table === 'NEW' ? 'New' : 'Coding'}</p>
          </div>
        );
      }
    },
    {
      field: 'clinician',
      headerName: 'Clinician',
      renderCell: (row) => {
        return <p className="font-[600] text-sm">{row?.clinician}</p>;
      }
    },
    {
      field: 'deg',
      headerName: 'Deg'
    },
    {
      field: 'org',
      headerName: 'Org'
    },
    {
      field: 'patiant',
      headerName: 'Patiant'
    },
    {
      field: 'ds',
      headerName: 'DS',
      renderCell: (row) => {
        return (
          <Dropdown.Input
            options={statusOptions}
            className={`hover:bg-transparent p-0 text-2.5`}
            label={'MAC'}
            classNameText="!text-sm"
          />
        );
      }
    },
    {
      field: 'co',
      headerName: 'CO',
      renderCell: (row) => {
        return (
          <Dropdown.Input
            options={statusOptions}
            className={`hover:bg-transparent p-0 text-2.5`}
            label={'JLM'}
            classNameText="!text-sm"
          />
        );
      }
    },
    {
      field: 'dur',
      headerName: 'Dur'
    },
    {
      field: 'appt',
      headerName: 'Appt.',
      renderCell: (row) => {
        return (
          <div className=" w-full h-full justify-center items-center">
            {row?.appt === undefined ? (
              <></>
            ) : row?.appt ? (
              <img src={SVG.plus_green} className="object-contain mx-auto w-[20px] h-5" />
            ) : (
              <img src={SVG.plus_red} className="object-contain mx-auto w-[20px] h-5" />
            )}
          </div>
        );
      }
    },
    {
      field: 'psQ',
      headerName: 'PS?',
      renderCell: (row) => {
        return (
          <div className="w-full h-full justify-center">
            {row?.psQ === 'SMILE' ? (
              <img src={SVG.smile_face} className="object-contain mx-auto w-[14px]" />
            ) : row?.psQ === 'ANGRY' ? (
              <img src={SVG.angry_face} className="object-contain mx-auto w-[14px]" />
            ) : (
              <></>
            )}
          </div>
        );
      }
    },

    {
      field: 'important',
      headerName: 'Important',
      renderCell: (row) => {
        return <p className="text-error text-sm">{row?.important}</p>;
      }
    },
    {
      field: 'message',
      headerName: 'Message'
    }
  ];

  return (
    <DefaultLayout>
      <div className="">
        <Header.Assistant toggle={false} setToggle={() => {}} />
      </div>
      <div className="flex flex-col flex-1 p-2.5 gap-2.5">
        <div className="flex items-center justify-between">
          <Text>{pageSize} Records</Text>
          <Dropdown.Standard
            CustomButton={(item) => (
              <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
                <div className="">
                  <p className="text-xs">{item.label} Records per page</p>
                </div>
                <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
              </div>
            )}
            options={PAGE_SIZE_OPTIONS}
            onChangeValue={(item: any) => {
              handleChangePageSize(item?.value);
            }}
          />
        </div>
        <div className="flex flex-1 gap-2.5">
            <Box.Standard className={`max-w-[180px] h-auto w-full transition-all ${
                toggle ? '' : '-translate-x-[150%] fixed'
              }`}>
              <div className="p-2.5 flex flex-col">
                <h4 className="font-bold text-sm flex justify-between items-center">
                  <span>Filter Notes By</span>
                  <img src={SVG.unfilter} />
                </h4>
                <div className="mt-2.5 mb-[15px]">
                  <Search.Standard
                    value={searchVal}
                    onChange={handleChangeValue}
                    className="border-[1px] border-nav border-solid !rounded-full bg-transparent"
                    inputProps={{
                      placeholder: 'Search',
                      style: { borderStyle: 'solid' }
                    }}
                  />
                </div>
                {CONTENT_LIST.map((content, idx) => {
                  return (
                    <div className="mt-2.5 mb-[15px]">
                      <h4 className="font-bold text-sm">{content?.title}</h4>
                      <div className="mt-2.5">
                        {content.check_list.map((item, idx) => {
                          return (
                            <div className="flex justify-between w-full items-center">
                              <div className="w-fit">
                                <Checkbox.Single
                                  className="ml-2"
                                  label={item?.label}
                                  onChange={() => {}}
                                />
                              </div>
                              {!!item?.collapse && <img src={SVG.chevron_down} alt="plus-icon" />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Box.Standard>
          <div className="flex-1 z-0 overflow-x-hidden">
            <Box.Standard className="flex w-full  flex-col gap-2 p-0 z-0">
              <div className="!overflow-x-auto w-full h-auto z-0">
                <GridTable column={fakeColumn} row={rows} />
              </div>
            </Box.Standard>
          </div>
        </div>
        <Footer
          pagination={{
            total: assistantList?.length,
            pageSize: pageSize,
            onChangeCurrentPage: handleChangePage,
            onChangePageSize: handleChangePageSize
          }}
        />
      </div>
    </DefaultLayout>
  );
};

export default CoderList;
