import { SVG } from 'assets/imagePath';
import Button from 'components/button';
import { BREAKPOINT } from 'constant/breakpoints';
import { useViewport } from 'context/ViewportContext';
import { useAuth } from 'hooks/useAuth';
import { FC, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';

type Props = {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
};

const HeaderList: FC<Props> = ({ className, toggle, setToggle }) => {
  const navigate = useNavigate();
  const { CURRENT_ROLE, currentUser } = useAuth();
  const { width } = useViewport();

  useEffect(() => {
    // if (width < BREAKPOINT.lg) {
    //   setToggle(false);
    // } else {
    //   setToggle(true);
    // }
  }, [width]);

  return (
    <div className="flex justify-between">
      <ul className="flex flex-1 items-center list-none justify-between text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={toggle ? SVG.filter : SVG.unfilter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            // onClick={() => {}}
            onClick={() => setToggle(!toggle)}
          />
        </li>
        <li className="flex gap-[5px]">
          <div>
            <Button.White
              className="!p-default"
              color="white"
              label="Notes List - Mgr."
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
          {CURRENT_ROLE === 'SYSTEM_ADMIN' && (
            <div>
              <Button.Primary
                className="!p-default"
                label={
                  <p>
                    Inquires <span className="font-bold">(3)</span>
                  </p>
                }
                onClick={() => {}}
              />
            </div>
          )}
        </li>
      </ul>
      <p className="items-center justify-center flex-1 hidden text-xs font-bold text-black lg:flex">
        Betty MacDonald – To Manage
      </p>

      <ul className="flex flex-1 items-center justify-end list-none text-center gap-2.5">
        <li>
          <p className="items-center justify-center flex-1 hidden text-xs font-bold text-black lg:flex">
            {currentUser?.username}
          </p>
        </li>
        <li>
          <Button.Primary className="!p-default" label="Add Note" onClick={() => navigate(`/note/new`)} />
        </li>
        <li>
          <Button.White
            className="!p-default"
            color="white"
            label="Actions"
            icon={SVG.chevron_down}
            onClick={() => {}}
          />
        </li>

        <li>
          <Button.Icon icon={SVG.more_horizontal} variant="outlined" onClick={() => {}} />
        </li>
      </ul>
    </div>
  );
};
HeaderList.displayName = 'Header.List';
export default memo(HeaderList);
