import React from 'react';
import CheckboxSingle from './CheckboxSingle';

const Checkbox = () => {
  return <React.Fragment />;
};

Checkbox.Single = CheckboxSingle;

export default Checkbox;
