import { SVG } from 'assets/imagePath';
import { Player } from 'components/player';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

const { PlayPause, Record, NextPrev, Range } = Player.Controls;

export interface IBuilderPlayerProps {
  toggle: boolean;
}

function BuilderPlayer({ toggle }: IBuilderPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);

  const [progress, setProgress] = useState<number>(0);

  const [volume, setVolume] = useState<number>(50);

  const audioRef = useRef(null);

  const duration = audioRef.current?.getDuration();

  const handleSetTimeVal = (type = null, time = 5) => {
    //Seconds
    const flag = type === 'next' ? 1 : -1;
    let timeSeek = progress + flag * time;
    if (timeSeek <= 0) {
      timeSeek = 0;
    } else if (timeSeek >= duration) {
      timeSeek = duration;
    }
    setProgress(timeSeek);
    audioRef.current?.seekTo(timeSeek, 'seconds');
  };

  return (
    <div
      className={`flex gap-2.5 flex-col ${toggle ? 'sm:flex-row' : '2xl:flex-row'} items-center `}
    >
      <ReactPlayer
        url="https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3"
        width={0}
        height={0}
        ref={audioRef}
        volume={volume / 100}
        playing={isPlaying}
        controls
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onProgress={(e) => setProgress(e.playedSeconds)}
      />
      <div className="flex  gap-x-2.5 items-center">
        <PlayPause
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          isPlaying={isPlaying}
        />
        <Record />
        <NextPrev event="skipPrev" />
        <NextPrev event="prevTime" onClick={() => handleSetTimeVal()} />
        <NextPrev event="nextTime" onClick={() => handleSetTimeVal('next')} />
        <NextPrev event="skipNext" />
      </div>
      <div className="2xl:w-[2px] 2xl:h-[20px] bg-[#727272]" />
      <div className="flex flex-1 gap-x-2.5 items-center">
        <Range
          icon={SVG.sound_black}
          color="black"
          defaultValue={volume}
          setRange={(vol) => setVolume(vol)}
        />
        <Range
          icon={SVG.audio_range}
          duration={duration}
          progress={progress}
          setRange={(val: number) => audioRef.current?.seekTo(val, 'seconds')}
        />
      </div>
    </div>
  );
}

export default BuilderPlayer;
