import { FC, memo } from 'react';
import cn from "classnames";
import { FiPlay } from 'react-icons/fi';

export type ButtonIconProps = {
  className?: string;
  icon: any;
  color?: 'primary' | 'white' | 'silver';
  variant?: 'contained' | 'outlined';
  classNameIcon?: string;
  onClick: () => void;
};

const ButtonIcon: FC<ButtonIconProps> = ({
  className,
  icon,
  classNameIcon,
  color,
  variant,
  onClick
}) => {
  let Icon;
  if (typeof icon !== 'string')
    Icon = icon;
  return (
    <button
      className={cn(
        variant === 'contained' && color === 'primary' ? '' : '',
        variant === 'contained' && color === 'white'
          ? 'inline-block px-4 py-2.5 bg-gradient-to-b from-[#FBFCFF] to-[#EBEFF6] text-input font-inter text-md leading-snug rounded-md shadow-inner inner-border inner-border-pearl hover:bg-grey-lightest-700 hover:shadow-lg focus:bg-grey-lightest-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-grey-lightest-800 active:shadow-lg transition duration-150 ease-in-out'
          : '',
        variant === 'contained' && color === 'silver'
          ? 'inline-block px-4 py-2.5 bg-grey text-input font-inter text-md leading-snug rounded-md shadow-inner inner-border inner-border-pearl hover:bg-grey-lightest-700 hover:shadow-lg focus:bg-grey-lightest-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-grey-lightest-800 active:shadow-lg transition duration-150 ease-in-out'
          : '',
        variant === 'outlined' ? 'px-4 py-2.5 border border-silver rounded-md' : '',
        className
      )}
      onClick={onClick}
    >
      { typeof icon === 'string' ? <img src={icon} alt="button-icon" className={cn('icon-app', classNameIcon)} />:<>
        <Icon className={cn('icon-app', classNameIcon)}/>
      </>}
    </button>
  );
};

ButtonIcon.displayName = 'Button.Icon';

export default memo(ButtonIcon);
