import { Text } from 'components/text';
import * as React from 'react';
import BenefitBox from './components/BenefitBox';

export interface BenefitProps {}

export default function Benefit(props: BenefitProps) {
  return (
    <div className="py-2.5">
      <div className="grid items-center  gap-5 grid-cols-1  md:grid-cols-[2fr_1fr] lg:grid-cols-2 justify-items-center">
        <div className="flex flex-col  gap-5 p-5 lg:p-[45px]">
          <Text Component="p" className="text-2xl xs:text-3xl md:text-[32px] leading-10">
            Benefits of Using <br />{' '}
            <Text
              Component="p"
              weight="700"
              className="text-2xl xs:text-3xl md:text-[32px] leading-10"
            >
              {' '}
              PhysicianSmart® Notes
            </Text>
          </Text>
          <Text size="18" responsive className="leading-[30px]">
            PhysicianSmart® prioritizes the clinician-patient relationship by providing
            top-of-the-line medical documentation solutions to healthcare systems, physician
            practices, and hospitals.
          </Text>
        </div>
        <div className="flex flex-col h-full gap-5 sm:flex-row md:flex-col lg:flex-row">
          <BenefitBox
            percent={80}
            desc="Speed up physicians transcription by up to 80% with our innovative dictation templates.*"
          />
          <BenefitBox
            percent={60}
            desc="Save up to 60% of data storage cost with our secure cloud platform.*"
          />
        </div>
      </div>
    </div>
  );
}
