import React from 'react';

interface IEmptyLayout {
  children: any;
}

const EmptyLayout: React.FC<IEmptyLayout> = ({ children }) => {
  return (
    <div className="w-full h-full min-h-screen bg-pearl ">
      {children}
    </div>
  );
};

export default EmptyLayout;
