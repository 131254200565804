import { IMAGES } from 'assets/imagePath';
import { Box } from 'components/box';
import BoxStandard from 'components/box/BoxStandard';
import Button from 'components/button/Button';
import Form from 'components/form/Form';
import SelectMulti from 'components/input/SelectMulti';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface IGetStarted {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

export default function GetStarted({ step, onClick }: IGetStarted) {
  const { onAccept, onDecline } = onClick;

  const form = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {}, []);

  return (
    <div className="h-full w-full flex flex-col items-center overflow-auto relative">
      <Box.Standard className="max-w-[960px] w-full h-auto flex-1 mt-[30px] overflow-auto p-0">
        <div className="px-[120px] py-[60px] flex flex-col items-center gap-[60px]">
          <div className="title flex flex-col items-center">
            <img
              className="max-w-[100px] h-auto object-cover mb-[30px]"
              src={IMAGES.get_started_img}
              alt="get_stated_medical_app"
            />
            <h2 className="text-xl font-bold mb-2.5">Get Started</h2>
            <p className="text-sm">Below you will find a quick start up guide</p>
          </div>
          <p className="text-sm">
            Lorem ipsum dolor sit amet consectetur. Molestie consequat auctor neque eu lectus.
            Viverra nec vitae id suspendisse lectus. Placerat vel viverra aliquet dignissim
            molestie. Quis ac sed convallis id ornare. Commodo amet volutpat venenatis adipiscing
            egestas elit varius nunc. Augue diam dolor adipiscing elit amet senectus arcu. Enim et
            dui ac nunc volutpat magna senectus. A sed odio viverra lorem donec orci.
          </p>

          <div className="mx-auto aspect-[2/1] max-w-[640px] w-full bg-nav rounded-[10px]" />
          <p className="text-sm">
            Praesent et cras mi diam. Adipiscing nullam nibh mauris non lacus habitant ut viverra.
            Dignissim nisi eu pellentesque quam diam pellentesque enim laoreet feugiat. Nibh sit in
            vitae nulla faucibus. Fusce ornare eget donec sagittis ullamcorper. In pellentesque
            risus vitae donec erat. Elementum vitae adipiscing non tellus pharetra ultricies. Lectus
            posuere eleifend fusce commodo. Nec magna eget dignissim enim. Malesuada scelerisque
            enim integer amet. Enim laoreet arcu amet rhoncus turpis tortor nunc non. Scelerisque
            quam aliquam ultrices nunc sit.
          </p>
        </div>
      </Box.Standard>
    </div>
  );
}
