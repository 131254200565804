import Form from 'components/form/Form';

const EditableVerticalTable = ({ columns, row, isEdit = true }) => {
  return (
    <div className="border border-white-light border-b-0 ">
      {columns.map((col, idx) => {
        return (
          <div key={col?.field} className="flex  border-b h-[30px]  border-white-light">
            <div className="title flex-1 flex items-center px-2.5 justify-end border-r border-white-light">
              <p className="text-xs">{col?.headerName}</p>
            </div>
            <div className="title flex-1 flex items-center px-2.5 justify-end">
              {col?.renderCell ? (
                col?.renderCell(row)
              ) : isEdit ? (
                <Form.TextInput
                  inputProps={{
                    defaultValue: row?.[col?.field],
                    className: 'outlined-none !p-1 border-none ring-1 w-full h-full rounded-[0px] !px-2'
                  }}
                  className="w-full"
                  name={col?.field}
                />
              ) : (
                <p className="text-xs">{row?.[col?.field]}</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EditableVerticalTable;
