import Button from 'components/button/Button';
import { Text } from 'components/text';
import * as React from 'react';
import cn from 'classnames';

export interface ButtonRequestProps {
  className?: string;
}

export default function ButtonRequest({ className, ...rest }: ButtonRequestProps) {
  return (
    <Button.Common
      className={cn(
        'w-fit h-[32px]  xs:h-[40px]  md:h-[48px] rounded-full px-5 md:px-[30px]',
        className
      )}
      {...rest}
    >
      <Text responsive className="whitespace-nowrap" weight="700" size="16" color="white">
        Request Demo
      </Text>
    </Button.Common>
  );
}
