import {
  CLINICIAN_DETAIL,
  CLINICIAN_DETAILS,
  SYSTEM_ADMIN_NOTE_DETAILS
} from 'components/detail/data';
import DetailBox from 'components/detail/DetailBox';
import InlineHtml from 'components/inlineHtml/InlineHtml';
import { FC, memo } from 'react';

interface ClinicianProps {
  clinicianData;
}

const ClinicianDetail: FC<ClinicianProps> = ({ clinicianData }) => {
  return (
    <div className="grid h-full grid-cols-2 gap-x-2.5">
      <div className="col-span-1 h-full flex flex-col justify-between">
        <DetailBox title="Name Info" detailRows={CLINICIAN_DETAILS.name_info(clinicianData)} />
        <DetailBox title="Education" detailRows={CLINICIAN_DETAILS.education(clinicianData)} />
      </div>
      <div className="col-span-1 h-full flex  flex-col justify-between gap-y-2.5">
        <DetailBox
          title="Organization"
          detailRows={CLINICIAN_DETAILS.organization(clinicianData)}
        />
        <DetailBox title="Key Info" detailRows={CLINICIAN_DETAILS.key_info(clinicianData)} />
        <DetailBox
          title="Identification"
          detailRows={CLINICIAN_DETAILS.identification(clinicianData)}
        />
      </div>
    </div>
  );
};

export default memo(ClinicianDetail);
