import { SVG } from 'assets/imagePath';
import Button from 'components/button/Button';
import { Dropdown } from 'components/dropdown';
import { QnA } from 'components/QnA';
import { FC, Fragment, memo } from 'react';

const DocAssistantSummary: FC = () => {
  return (
    <div className="flex flex-start p-[20px]">
      <div className="flex-1 flex flex-start justify-center flex-wrap  py-[30px]">
        <div className="flex gap-[13px] items-center w-[25%]">
          <p className="text-xs">Status</p>
          <Dropdown.Input
            label="NEW"
            options={[]}
            className={`border !rounded-full px-[10px] py-[0px] text-2.5 h-auto`}
            classNameIcon={'!m-0 !h-[12px] !w-[12px]'}
            classNameText="!text-[10px]"
          />
        </div>
        <div className="flex gap-[13px] items-center w-[25%]">
          <p className="text-xs">Patiant</p>
          <p className="text-xs font-bold">Robert L. Langley</p>
        </div>
        <div className="flex gap-[13px] justify-center items-center w-[25%]">
          <p className="text-xs">APPT</p>
          <img src={SVG.checked} className="object-contain h-[15px] w-[15px]" />
        </div>
        <div className="flex gap-[13px]  items-center w-[25%]">
          <p className="text-xs">DOS</p>
          <p className="text-xs font-bold">Mon 7/25/2022</p>
        </div>
        <div className="flex gap-[13px] justify-self-start items-center w-[50%]">
          <p className="text-xs">Important</p>
          <p className="text-xs font-bold text-error">Text–Do Not Call</p>
        </div>
        <div className="flex gap-[13px] justify-center items-center w-[50%]">
          <p className="text-xs">Message</p>
          <p className="text-xs font-bold text-error">Need By 3:00 pm</p>
        </div>
      </div>
      <div className="w-[280px]">
        <QnA />
      </div>
    </div>
  );
};

export default memo(DocAssistantSummary);
