import { SVG } from 'assets/imagePath';
import { IDetailBoxProps, IDetailRow } from 'components/detail/DetailBox';
import { Dropdown } from 'components/dropdown';
import { useAuth } from 'hooks/useAuth';
import { getFormatedDate } from 'utils';

export const CLINICIAN_DETAIL = (clinicianDetail): IDetailBoxProps[] => {
  return [
    {
      title: 'Name Info',
      detailRows: [
        {
          subTitle: '*First Name (Goes by)',
          content: 'Marcus',
          className: {
            classNameContent: 'font-bold'
          }
        },
        {
          subTitle: '*Last Name',
          content: 'Welby',
          className: {
            classNameContent: 'font-bold'
          }
        },
        {
          subTitle: '*Middle Name',
          content: 'Steven',
          className: {
            classNameContent: 'font-bold'
          }
        },
        {
          subTitle: '*Initials',
          content: 'MSW',
          className: {
            classNameContent: 'font-bold'
          }
        },
        {
          subTitle: '*Sort Name - Clin',
          className: {
            classNameContent: 'font-bold mr-[4px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
              <Dropdown.Input
                label="Welby, Marcus MD"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Welby, Marcus MD',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
              <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
            </div>
          )
        },
        {
          subTitle: 'Given Name (First)',
          content: 'LaMarcus',
          className: {
            classNameContent: 'font-bold'
          }
        },
        {
          subTitle: 'Prefix',

          className: {
            classNameContent: 'mr-[24px] font-normal'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Sir"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Sir',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Suffix',

          className: {
            classNameContent: 'mr-[24px] font-normal'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Jr"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Jr',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        }
      ]
    },
    {
      title: 'Education',
      detailRows: [
        {
          subTitle: '*Clinician Degree',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="MD, PHD"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'MD, PHD',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Other Degrees',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="MBA, MPH"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'MBA, MPH',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: '*Specialties',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="PC - FP, UC"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'PC - FP, UC',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        }
      ]
    },
    {
      title: 'Status',
      detailRows: [
        {
          subTitle: 'Service Level',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Full Service"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Full Service',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right "
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },

        {
          subTitle: 'Clinician Record Status',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Active"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Active',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-normal"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        }
      ]
    },
    {
      title: 'Organization',
      detailRows: [
        {
          subTitle: 'Organization',

          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Welby Clinic"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Welby Clinic',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Org Short',
          content: 'Welby C'
        },
        {
          subTitle: 'Org. Initials',
          content: 'WC'
        },
        {
          subTitle: 'Location',

          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Pana"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Pana',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Clinical Departments',

          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Surgery"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Surgery',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        }
      ]
    },

    {
      title: 'Clinician Details',
      detailRows: [
        {
          subTitle: 'Important',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Text - Do not Call"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Text - Do not Call',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold text-error"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Clinician Time Zone',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="EST"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'EST',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right font-bold text-error"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: '*Service Phone #',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="1-800-538-4244"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: '1-800-538-4244',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: '*Clinician ID',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="LSW_SC_09,08,2019"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'LSW_SC_09,08,2019',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: '*Start Date',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="09/08/2019"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: '09/08/2019',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Priority',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Neutral"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Neutral',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Ranking',
          className: {
            classNameContent: ' mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Top 10%"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Top 10%',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px text-right"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        }
      ]
    }
  ];
};

export const SYSTEM_ADMIN_NOTE_DETAILS = (noteDetail): IDetailBoxProps[] => {
  return [
    {
      title: 'Organization',
      detailRows: [
        {
          subTitle: 'Organization',
          content: 'Welby Clinic'
        },
        {
          subTitle: 'Location',
          content: 'Pana'
        },
        {
          subTitle: 'Speciality',
          content: 'PC- FP'
        },
        {
          subTitle: 'Doc Specialist',
          className: {
            classNameContent: 'font-bold mr-[4px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
              <Dropdown.Input
                label="Mary Charles"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Mary Charles',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px] text-xs font-normal"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
              <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
            </div>
          )
        }
      ]
    },
    {
      title: 'Clinician Messages ',
      detailRows: [
        {
          subTitle: 'Important',
          content: noteDetail?.important,

          className: {
            classNameContent: 'font-bold'
          },
          colorContent: 'error'
        },
        {
          subTitle: 'Note Message',
          content: noteDetail?.message,
          className: {
            classNameContent: 'font-bold'
          },
          colorContent: 'error'
        },
        {
          subTitle: 'Lead',
          className: {
            classNameContent: 'font-bold mr-[4px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Bonnie Merritt"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Bonnie Merritt',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
              <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
            </div>
          )
        }
      ]
    },
    {
      title: 'Status DS',
      detailRows: [
        {
          subTitle: 'DS - Deadline',
          content: 'Tues 07/26/2022 05:30 am'
        },

        {
          subTitle: 'DS–Clarify Questions',
          content: 'Did you want to add Pain Level ?',
          colorContent: 'error'
        },
        {
          subTitle: 'DS–Clinician Answers',
          content: 'It is a 7',
          colorContent: 'success'
        },
        {
          subTitle: 'Nurse Create Appt',
          content: (
            <p className="font-bold">
              <span className="text-success">Y</span>/<span className="text-error">N</span>
            </p>
          )
        }
      ]
    },

    {
      title: 'Coder Stats',
      detailRows: [
        {
          subTitle: 'Coder',
          className: {
            classNameContent: 'font-bold mr-[4px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
              <Dropdown.Input
                label="Janice Maird"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Janice Maird',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
              <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
            </div>
          )
        },

        {
          subTitle: 'CO-Deadline',
          content: 'Tues 07/25/2022 11:30 am',

          isHide: (() => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { CURRENT_ROLE } = useAuth();
            return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
          })()
        },
        {
          subTitle: 'CO–Clarify Questions',
          content: 'Did you administer a medication ?',
          className: {
            classNameContent: 'font-bold'
          },
          colorContent: 'success',
          isHide: (() => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { CURRENT_ROLE } = useAuth();
            return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
          })()
        },
        {
          subTitle: 'CO–Clinician Answers',
          content: 'Sertraline 30 MG',
          className: {
            classNameContent: 'font-bold'
          },
          colorContent: 'error',
          isHide: (() => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { CURRENT_ROLE } = useAuth();
            return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
          })()
        },

        {
          subTitle: 'E&M Level',
          isHide: (() => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { CURRENT_ROLE } = useAuth();
            return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
          })(),
          className: {
            classNameContent: 'font-bold mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="3"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: '3',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'ICD 10',
          className: {
            classNameContent: 'font-bold mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="112.3"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: '112.3',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'DS-Clinician Pat. Time Spent',
          className: {
            classNameContent: 'font-bold mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="50 Min"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: '50 Min',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Place of Service',
          className: {
            classNameContent: 'font-bold mr-[24px]'
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
              <Dropdown.Input
                label="Office"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'Office',
                    value: 'New'
                  }
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px font-bold"
                classNameIcon="!m-0 "
                classNameMenu="absolute"
              />
            </div>
          )
        },
        {
          subTitle: 'Clinician Places',
          content: (
            <div className="text-right   flex justify-end  gap-[5px] items-center">
              <p>
                Office, Telemedicine, Hospital,
                <br /> Skilled Nursing,
              </p>
            </div>
          )
        }
      ]
    },
    {
      title: 'Stats DS',
      detailRows: [
        {
          subTitle: 'Characters',
          content: '234'
        },
        {
          subTitle: 'Smart Texts',
          content: '3'
        },
        {
          subTitle: 'Requests',
          content: '6',
          importantRow: true
        },
        {
          subTitle: 'QA Check',
          content: '1',
          importantRow: true
        },
        {
          subTitle: 'EMR Transfer',
          content: '1',
          importantRow: true
        },
        {
          subTitle: 'DS Date - Time Done',
          content: '1'
        }

        // {
        //   subTitle: 'Time',
        //   className: {
        //     classNameContent: 'font-bold mr-[24px]'
        //   },
        //   content: (
        //     <div className="w-full  ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
        //       <Dropdown.Input
        //         label="1:30"
        //         icon={SVG.chevron_down}
        //         options={[
        //           {
        //             label: '1:30',
        //             value: 'New'
        //           }
        //         ]}
        //         className={`p-0 hover:bg-transparent text-2.5`}
        //         classNameText="px-[10px] py-[0px font-bold text"
        //         classNameIcon="!m-0 "
        //         classNameMenu="absolute"
        //       />
        //     </div>
        //   )
        // }
      ]
    },
    {
      title: 'Additional Note Info',
      detailRows: [
        {
          subTitle: 'Duration',

          content: '2:32 Min'
        },
        {
          subTitle: 'Patient Initials',
          content: 'RLL'
        },
        {
          subTitle: 'Note #',
          content: '1257'
        },
        {
          subTitle: 'Audio File',
          content: 'LSW,SC,1245,07,23,5,30,PM,05,23,22'
        },
        {
          subTitle: 'Arrived',
          content: 'Mon 07/25/2022 11:30 am'
        }
      ]
    },
    {
      title: 'Additional Clinician Info',
      detailRows: [
        {
          subTitle: 'Priority',

          content: 'Eastern'
        },
        {
          subTitle: 'Ranking',
          content: 'Top 10%'
        },
        {
          subTitle: 'Clinician Time Zone',
          content: 'Eastern'
        },
        {
          subTitle: 'Service Level',
          content: 'Full Service'
        },
        {
          subTitle: 'Clinician Record  ',
          content: 'Active'
        }
      ]
    }
  ];
};

export const DETAIL_HEADER: IDetailBoxProps[] = [
  {
    detailRows: [
      {
        subTitle: 'Clinician',
        content: 'Welby, Marcus Jr.',
        className: {
          classNameContent: 'font-bold'
        }
      },
      {
        subTitle: 'Status',
        className: {
          classNameContent: 'font-bold mr-[24px]'
        },
        content: (
          <div className="w-full text-left ml-auto z-0 flex justify-end">
            <Dropdown.Input
              label="New - To Transcribe"
              icon={SVG.chevron_down}
              options={[
                {
                  label: 'New - To Transcribe',
                  value: 'New'
                }
              ]}
              className={`p-0 hover:bg-transparent text-2.5`}
              classNameText="px-[10px] py-[0px]"
              classNameIcon="!m-0"
              classNameMenu="absolute"
            />
          </div>
        )
      }
    ]
  },
  {
    detailRows: [
      {
        subTitle: 'Patiant',
        content: 'Langley, Robert L.',
        className: {
          classNameContent: 'font-bold'
        }
      },
      {
        subTitle: 'Date of Service',
        content: 'Mon 7/25/2022',
        className: {
          classNameContent: 'font-bold'
        }
      }
    ]
  }
];

// eslint-disable-next-line react-hooks/rules-of-hooks
const { CURRENT_ROLE } = useAuth();

interface Detail {
  [key: string]: (arg: any) => IDetailRow[];
}

export const DETAIL_INFO: Detail = {
  organization: () => [
    {
      subTitle: 'Organization',
      content: 'Welby Clinic'
    },
    {
      subTitle: 'Location',
      content: 'Pana'
    },
    {
      subTitle: 'Speciality',
      content: 'PC- FP'
    },
    {
      subTitle: 'Doc Specialist',
      className: {
        classNameContent: 'font-bold mr-[4px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
          <Dropdown.Input
            label="Mary Charles"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Mary Charles',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] text-xs font-normal"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
          <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
        </div>
      )
    }
  ],
  status_ds: () => [
    {
      subTitle: 'DS - Deadline',
      content: 'Tues 07/26/2022 05:30 am'
    },

    {
      subTitle: 'DS–Clarify Questions',
      content: 'Did you want to add Pain Level ?',
      colorContent: 'error',
      importantRow: CURRENT_ROLE === 'DOCS'
    },
    {
      subTitle: 'DS–Clinician Answers',
      content: 'It is a 7',
      colorContent: 'success',
      importantRow: CURRENT_ROLE === 'DOCS'
    },
    {
      subTitle: 'Nurse Create Appt',
      importantRow: CURRENT_ROLE === 'DOCS',

      content: (
        <p className="font-bold">
          <span className="text-success">Y</span>/<span className="text-error">N</span>
        </p>
      )
    }
  ],
  stats_ds: () => [
    {
      subTitle: 'Characters',
      content: '234'
    },
    {
      subTitle: 'Smart Texts',
      content: '3'
    },
    {
      subTitle: 'Requests',
      content: '6',
      importantRow: true
    },
    {
      subTitle: 'QA Check',
      content: '1',
      importantRow: true
    },
    {
      subTitle: 'EMR Transfer',
      content: '1',
      importantRow: true
    },
    {
      subTitle: 'DS Date - Time Done',
      content: 'Mon 07/25/2022 1:30'
    }

    // {
    //   subTitle: 'Time',
    //   className: {
    //     classNameContent: 'font-bold mr-[24px]'
    //   },
    //   content: (
    //     <div className="w-full  ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //       <Dropdown.Input
    //         label="1:30"
    //         icon={SVG.chevron_down}
    //         options={[
    //           {
    //             label: '1:30',
    //             value: 'New'
    //           }
    //         ]}
    //         className={`p-0 hover:bg-transparent text-2.5`}
    //         classNameText="px-[10px] py-[0px font-bold text"
    //         classNameIcon="!m-0 "
    //         classNameMenu="absolute"
    //       />
    //     </div>
    //   )
    // }
  ],
  stats_ds_docs: () => [
    {
      subTitle: 'Characters',
      content: '234'
    },
    {
      subTitle: 'Smart Texts',
      content: '3'
    },
    {
      subTitle: 'Requests',
      className: {
        classNameContent: 'mr-[19px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="6"
            icon={SVG.chevron_down}
            options={[
              {
                label: '6',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent  text-xs`}
            classNameText="px-[10px] py-[0px]  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      ),
      importantRow: true
    },
    {
      subTitle: 'QA Check',
      className: {
        classNameContent: 'mr-[19px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="1"
            icon={SVG.chevron_down}
            options={[
              {
                label: '1',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent  text-xs`}
            classNameText="px-[10px] py-[0px]  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      ),
      importantRow: true
    },
    {
      subTitle: 'EMR Transfer',
      importantRow: true,
      className: {
        classNameContent: 'mr-[19px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="1"
            icon={SVG.chevron_down}
            options={[
              {
                label: '1',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent  text-xs`}
            classNameText="px-[10px] py-[0px]  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'DS Date - Time Done',
      content: 'Mon 07/25/2022 1:30'
    },
    {
      subTitle: 'Arrived',
      content: 'Mon 07/25/2022 11:30 am'
    }

    // {
    //   subTitle: 'Time',
    //   className: {
    //     classNameContent: 'font-bold mr-[24px]'
    //   },
    //   content: (
    //     <div className="w-full  ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //       <Dropdown.Input
    //         label="1:30"
    //         icon={SVG.chevron_down}
    //         options={[
    //           {
    //             label: '1:30',
    //             value: 'New'
    //           }
    //         ]}
    //         className={`p-0 hover:bg-transparent text-2.5`}
    //         classNameText="px-[10px] py-[0px font-bold text"
    //         classNameIcon="!m-0 "
    //         classNameMenu="absolute"
    //       />
    //     </div>
    //   )
    // }
  ],
  clinician_message: () => [
    {
      subTitle: 'Important',
      content: 'Text - Do not call!',
      colorContent: 'error'
    },
    {
      subTitle: 'Note Message',
      content: 'Need by 3:00 PM',
      colorContent: 'error'
    },
    {
      subTitle: 'Clinician Time Zone',
      content: 'Eastern'
    },
    {
      subTitle: 'Coder',
      className: {
        classNameContent: 'font-bold mr-[4px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
          <Dropdown.Input
            label="Janice Maird"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Janice Maird',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] text-xs font-normal"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
          <img src={SVG.phone} alt="phone-icon" className="object-contain h-[15px] w-[15px]" />
        </div>
      )
    }
  ],
  status_co: () => [
    {
      subTitle: 'CO - Deadline',
      content: 'Tues 07/25/2022 11:30 am'

      // isHide: (() => {
      //   // eslint-disable-next-line react-hooks/rules-of-hooks
      //   const { CURRENT_ROLE } = useAuth();
      //   return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
      // })()
    },
    {
      subTitle: 'CO–Clarify Questions',
      content: 'Did you administer a medication ?',

      colorContent: 'error'
      // isHide: (() => {
      //   // eslint-disable-next-line react-hooks/rules-of-hooks
      //   const { CURRENT_ROLE } = useAuth();
      //   return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
      // })()
    },
    {
      subTitle: 'CO–Clinician Answers',
      content: 'Sertraline 30 MG',

      colorContent: 'success'
      // isHide: (() => {
      //   // eslint-disable-next-line react-hooks/rules-of-hooks
      //   const { CURRENT_ROLE } = useAuth();
      //   return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
      // })()
    },
    {
      subTitle: 'Service Locations',
      content: 'Office, Telemedicine, Hospital, Skilled Nursing'

      // isHide: (() => {
      //   // eslint-disable-next-line react-hooks/rules-of-hooks
      //   const { CURRENT_ROLE } = useAuth();
      //   return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
      // })()
    }
  ],
  stats_co: () => [
    {
      subTitle: 'E&M Level',
      importantRow: CURRENT_ROLE === 'CODERS',
      // isHide: (() => {
      //   // eslint-disable-next-line react-hooks/rules-of-hooks
      //   const { CURRENT_ROLE } = useAuth();
      //   return ['CODERS', 'DOCS'].includes(CURRENT_ROLE);
      // })(),
      className: {
        classNameContent: ' mr-[24px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="3"
            icon={SVG.chevron_down}
            options={[
              {
                label: '3',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'ICD 10',
      importantRow: CURRENT_ROLE === 'CODERS',

      className: {
        classNameContent: 'mr-[24px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="112.3"
            icon={SVG.chevron_down}
            options={[
              {
                label: '112.3',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent  text-xs`}
            classNameText="px-[10px] py-[0px]  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'Clinician Pat. Time Spent',
      importantRow: CURRENT_ROLE === 'CODERS',

      className: {
        classNameContent: ' mr-[24px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="50 Min"
            icon={SVG.chevron_down}
            options={[
              {
                label: '50 Min',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px]  text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'Place of Service',
      importantRow: CURRENT_ROLE === 'CODERS',

      className: {
        classNameContent: ' mr-[24px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px]">
          <Dropdown.Input
            label="Office"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Office',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[10px] py-[0px] text-xs"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: 'CO Date - Time Done',
      content: 'Mon 07/25/2022 5:30'
    }
  ],
  note_specifics: () => [
    {
      subTitle: 'Duration',
      content: '2:32 Min'
    },
    {
      subTitle: 'Patient Initials',
      content: 'RLL'
    },
    {
      subTitle: 'Note #',
      content: '1257'
    },
    {
      subTitle: 'Audio File',
      content: 'LSW,SC,1245,07,23,5,30,PM,05,23,22'
    },
    {
      subTitle: 'Arrived',
      content: 'Mon 07/25/2022 11:30 am',
      isHide: (() => {
        const { CURRENT_ROLE } = useAuth();

        return ['DOCS', 'CODERS'].includes(CURRENT_ROLE);
      })()
    }
  ],
  clinician_specific: () => [
    {
      subTitle: 'Service Level',
      content: 'Full Service'
    },

    {
      subTitle: 'Clinician Record Status',
      content: 'Active'
    },
    {
      subTitle: 'Manager',
      content: 'Bonnie Merritt'
    },
    {
      subTitle: 'Ranking',
      content: 'Top 10%',
      isHide: (() => {
        const { CURRENT_ROLE } = useAuth();

        return ['DOCS', 'CODERS'].includes(CURRENT_ROLE);
      })()
    },
    {
      subTitle: 'Priority',
      content: '129',
      isHide: (() => {
        const { CURRENT_ROLE } = useAuth();

        return ['DOCS', 'CODERS'].includes(CURRENT_ROLE);
      })()
    }
  ],
  other_note_info: () => [
    {
      subTitle: 'CO Date - Time Due',
      content: 'Tues 07/25/2022 11:30 am'
    },

    {
      subTitle: 'CO Date - Time Due',
      content: ''
    }
  ]
};

export const CLINICIAN_DETAILS: Detail = {
  left_summary: (data) => [
    {
      subTitle: 'Clinician',
      className: {
        classNameContent: 'font-bold'
      },
      content: [data?.firstName, data?.middleName, data?.lastName].join(" ")
    },
    {
      subTitle: 'Organization',
      className: {
        classNameContent: 'font-bold'
      },
      content: data?.orgFullName
    },
    {
      subTitle: '*Cell Phone',
      content: data?.cellPhone
    }
  ],
  right_summary: (data) => [
    {
      subTitle: 'Service Phone',
      content: data?.servicePhone,
      className: {
        classNameContent: 'font-bold'
      }
    },
    {
      subTitle: '*#1 - Doc Specialist',
      className: {
        classNameContent: 'font-bold mr-[24px]'
      },
      content: (
        <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
          <Dropdown.Input
            label="Welby Clinic"
            icon={SVG.chevron_down}
            options={[
              {
                label: 'Welby Clinic',
                value: 'New'
              }
            ]}
            className={`p-0 hover:bg-transparent text-2.5`}
            classNameText="px-[5px] py-[0px] text-xs font-normal"
            classNameIcon="!m-0 "
            classNameMenu="absolute"
          />
        </div>
      )
    },
    {
      subTitle: '*#1 - Coder',
      content: 'Julie Medley'
    }
  ],
  name_info: (data) => [
    {
      subTitle: '*First Name (Goes by)',
      content: data?.firstName || '',
      className: {
        classNameContent: 'font-bold'
      }
    },
    {
      subTitle: '*Last Name',
      content: data?.lastName || '',
      importantRow: CURRENT_ROLE === 'DOCS',
      className: {
        classNameContent: 'font-bold'
      }
    },
    {
      subTitle: '*Middle Name',
      content: data?.middleName || '',
      className: {
        classNameContent: 'font-bold'
      }
    },
    {
      subTitle: '*Initials',
      content: data?.initials || '',
      className: {
        classNameContent: 'font-bold'
      }
    },
    // {
    //   subTitle: '*Sort Name - Clin',
    //   importantRow: CURRENT_ROLE === 'DOCS',

    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="Welby Clinic"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'Welby Clinic',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5`}
    //           classNameText="px-[5px] py-[0px] text-xs"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'Welby, Marcus MD'
    //     ),
    //   className: {
    //     classNameContent: `font-bold ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // },
    // {
    //   subTitle: 'Given Name (First)',
    //   importantRow: CURRENT_ROLE === 'DOCS',

    //   content: 'LaMarcus',
    //   className: {
    //     classNameContent: 'font-bold'
    //   }
    // },
    {
      subTitle: 'Prefix',
      content: data?.prefixName || ''
    },
    {
      subTitle: 'Suffix',
      content: data?.suffixName || ''
    }
  ],
  education: (data) => [
    {
      subTitle: '*Clinician Degree',
      importantRow: CURRENT_ROLE === 'DOCS',
      content: data?.degree || '',
    },
    // {
    //   subTitle: 'Other Degrees',
    //   importantRow: CURRENT_ROLE === 'DOCS',

    //   content: "",
    //   className: {
    //     classNameContent: `${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // },
    {
      subTitle: '*Specialties',
      importantRow: CURRENT_ROLE === 'DOCS',

      content: data?.specialty || "",
    },
    // {
    //   subTitle: '*Fellowships',
    //   importantRow: CURRENT_ROLE === 'DOCS',

    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="PC - FP, UC"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'PC - FP, UC',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5`}
    //           classNameText="px-[5px] py-[0px] text-xs"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'PC - FP, UC'
    //     ),
    //   className: {
    //     classNameContent: `font-bold ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // },
    // {
    //   subTitle: 'Clinician Record Status',
    //   isHide: !(CURRENT_ROLE === 'SYSTEM_ADMIN'),
    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="Active"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'Active',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5`}
    //           classNameText="px-[5px] py-[0px] text-xs"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'Active'
    //     ),
    //   className: {
    //     classNameContent: `font-bold ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // }
  ],
  organization: (data) => [
    {
      subTitle: '*Organization',
      content: data?.organization || '',
      className: {
        classNameContent: `font-bold ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    },
    {
      subTitle: '*Org Short',
      content: 'Welby C'
    },
    {
      subTitle: '*Org. Initials',
      content: 'WC'
    },
    {
      subTitle: 'Location',
      content: data?.locations || '',
      className: {
        classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    },
    {
      subTitle: '*Clinical Departments',
      content: data?.department || '',
      className: {
        classNameContent: `${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    }
  ],
  key_info: (data) => [
    // {
    //   subTitle: 'Important',
    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="Text - Do not Call"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'Text - Do not Call',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5 `}
    //           classNameText="px-[5px] py-[0px] text-xs font-bold text-error"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'Text - Do not Call'
    //     ),
    //   className: {
    //     classNameContent: `font-bold text-error ${
    //       CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''
    //     }`
    //   }
    // },
    {
      subTitle: 'Clinician Time Zone',
      content:
        CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
          <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
            <Dropdown.Input
              label="EST"
              icon={SVG.chevron_down}
              options={[
                {
                  label: 'EST',
                  value: 'New'
                }
              ]}
              className={`p-0 hover:bg-transparent text-2.5`}
              classNameText="px-[5px] py-[0px] text-xs font-bold text-error"
              classNameIcon="!m-0 "
              classNameMenu="absolute"
            />
          </div>
        ) : (
          'EST'
        ),
      className: {
        classNameContent: `font-bold text-error ${
          CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''
        }`
      }
    },
    {
      subTitle: '*Service Phone #',
      className: {
        classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      },
      content: data?.servicePhone || ''
    }
  ],
  identification: (data) => [
    {
      subTitle: '*Start Date',
      content: getFormatedDate(data?.dateStarted) || '',
      className: {
        classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    },
    {
      subTitle: '*Clinician ID',
      content: data?.userRecordId,
      className: {
        classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    },
    // {
    //   subTitle: 'Service Level',
    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="Full Service"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'Full Service',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5`}
    //           classNameText="px-[5px] py-[0px] text-xs font-normal"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'Full Service'
    //     ),
    //   className: {
    //     classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // },
    {
      subTitle: 'Priority',
      content: data?.priority || '',
      className: {
        classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
      }
    },
    // {
    //   subTitle: 'Ranking',
    //   isHide: !(CURRENT_ROLE === 'SYSTEM_ADMIN'),
    //   content:
    //     CURRENT_ROLE === 'SYSTEM_ADMIN' ? (
    //       <div className="w-full text-left ml-auto z-0 flex items-center justify-end gap-x-[5px] ">
    //         <Dropdown.Input
    //           label="Top 10%"
    //           icon={SVG.chevron_down}
    //           options={[
    //             {
    //               label: 'Top 10%',
    //               value: 'New'
    //             }
    //           ]}
    //           className={`p-0 hover:bg-transparent text-2.5`}
    //           classNameText="px-[5px] py-[0px] text-xs font-normal"
    //           classNameIcon="!m-0 "
    //           classNameMenu="absolute"
    //         />
    //       </div>
    //     ) : (
    //       'Top 10%'
    //     ),
    //   className: {
    //     classNameContent: ` ${CURRENT_ROLE === 'SYSTEM_ADMIN' ? 'mr-[24px]' : ''}`
    //   }
    // }
  ]
};
