import { IDropdown } from 'types';

export const PAGE_SIZE_OPTIONS: IDropdown[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
];

export const DEFAULT_LIMIT = 10;
