import * as React from 'react';
import cn from "classnames";
import { colorClasses, styleSize, weightClasses } from './Text.styles';

export interface ITextProps {
  Component?: any;
  children?: React.ReactNode;
  ellipsis?: boolean;
  underline?: boolean;
  size?: '10' | '12' | '14' | '16' | '18' | '20' | '22' | '24';
  weight?: '200' | '300' | '400' | '500' | '600' | '700' | '800';
  color?: 'default' | 'secondary' | 'primary' | 'white' | 'blue' | 'success' | 'error' | 'warning';
  className?: string;
  [rest: string]: any;
}

export default function Text({
  size = '12',
  weight = '400',
  color = 'default',
  children,
  Component = 'p',
  ellipsis = false,
  underline = false,

  className,
  ...rest
}: ITextProps) {
  return (
    <Component
      className={cn(
        'font-inter',
        styleSize[size],
        weightClasses[weight],
        colorClasses[color],
        ellipsis && 'whitespace-nowrap overflow-hidden text-ellipsis',
        underline && 'underline',
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
}
