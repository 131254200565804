import React from 'react';
import FormCheckboxSingle from './FormCheckboxSingle';
import FormDatePicker from './FormDatePicker';
import FormEmailInput from './FormEmailInput';
import FormPasswordInput from './FormPasswordInput';
import FormPhoneInput from './FormPhoneInput';
import FormSearch from './FormSearch';
import FormSelectInput from './FormSelectInput';
import FormSelectMulti from './FormSelectMulti';
import FormTextInput from './FormTextInput';

const Form = () => {
  return <React.Fragment />;
};

Form.TextInput = FormTextInput;

Form.EmailInput = FormEmailInput;

Form.PhoneInput = FormPhoneInput;

Form.PasswordInput = FormPasswordInput;

Form.SelectInput = FormSelectInput;

Form.SelectMulti = FormSelectMulti;

Form.CheckboxSingle = FormCheckboxSingle;

Form.SearchBox = FormSearch;

Form.DatePicker = FormDatePicker;

export default Form;
