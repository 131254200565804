import { AppPopover } from 'components/popover';
import * as React from 'react';
import cn from "classnames";
import { IDropdown } from 'types';

export interface IDropdownStandardProps {
  CustomButton?: (val: any) => React.ReactNode | JSX.Element;
  options: IDropdown[];
  onChangeValue?: (value: any) => void;
}

export default function DropdownStandard({
  CustomButton,
  options,
  onChangeValue
}: IDropdownStandardProps) {
  const [active, setActive] = React.useState(0);

  const [val, setVal] = React.useState(options[active]);

  const handleChange = (val, idx) => {
    setVal(val);
    setActive(idx);
    onChangeValue && onChangeValue(val);
  };

  const Button = () => {
    return <div>{val?.label}</div>;
  };

  return (
    <AppPopover
      ButtonNode={CustomButton ? CustomButton(val) : <Button />}
      Panel={({ onClosePopper }) => {
        return (
          <ul className="">
            {options.map((item, idx) => {
              return (
                <li
                  className={cn(
                    active === idx ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm font-inter hover:bg-gray-100 hover:text-gray-900 cursor-pointer'
                  )}
                  key={item.value}
                  onClick={() => {
                    onClosePopper();
                    handleChange(item, idx);
                    item?.onClick && item?.onClick();
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        );
      }}
    />
  );
}
