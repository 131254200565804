import cn from "classnames";
import { FC, memo, ReactNode } from 'react';

type Props = {
  className?: string;
  label: ReactNode;
  type?: 'button' | 'submit';
  onClick?: () => void;
};

const ButtonPrimary: FC<Props> = ({ className, label, onClick, type = 'button' }) => {
  return (
    <button
      type={type}
      className={cn(
        'w-full inline-block px-6 py-2 bg-primary text-white font-inter text-xs leading-snug rounded-md shadow-inner hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out',
        className
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

ButtonPrimary.displayName = 'Button.Primary';

export default memo(ButtonPrimary);
