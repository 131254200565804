import { IMAGES } from 'assets/imagePath';
import Button from 'components/button/Button';
import { Text } from 'components/text';
import * as React from 'react';

export interface HeaderProps {}

export default function Header({}: HeaderProps) {
  return (
    <header id="header" className="w-full  my-5 xs:mt-[30px] sm:mt-[60px] xs:mb-[30px]">
      <div className="max-w-[1280px] mx-auto px-2.5 flex items-center justify-between w-full gap-5">
        <img
          src={IMAGES.landing_logo}
          className="max-w-[150px] xs:max-w-[270px] md:max-w-[340px] w-full h-auto"
        />

        <Button.Common
          className="w-fit h-[32px]  xs:h-[40px]  md:h-[48px] rounded-full px-5 md:px-[30px]"
          onClick={() => {}}
        >
          <Text responsive className="whitespace-nowrap" weight="700" size="16" color="white">
            Request Demo
          </Text>
        </Button.Common>
      </div>
    </header>
  );
}
