import { SVG } from 'assets/imagePath';
import { STORAGE } from 'constant/auth';
import { useAuth } from 'hooks/useAuth';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarAvatar from './NavBarAvatar';
import NavBarIcon from './NavBarIcon';
import NavBarIconMenu from './NavBarIconMenu';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalContext';

export interface INavToolsMenuProps {}

export default function NavToolsMenu({}: INavToolsMenuProps) {
  const { state } = useContext(GlobalContext);
  const { userInfo } = state;
  const navigate = useNavigate();

  const signOutFunc = async () => {
    try {
      await Auth.signOut({ global: true });
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  
  return (
    <div className="flex flex-col gap-2.5 mt-8">
      <NavBarAvatar
        text={`${[
          userInfo?.['custom:firstName'],
          userInfo?.['custom:middleName'],
          userInfo?.['custom:lastName']
        ].join(' ')}`}
        className="hover:bg-transparent text-black px-2 font-bold text-xs"
        classNameIcon="h-5 w-5"
      />
      <div className="w-full h-[1px] bg-nav" />

      <div className="flex flex-col  mt-8">
        <div className="hover:bg-nav -mx-2.5 py-2.5 cursor-pointer">
          <NavBarIconMenu src={SVG.sound_black} title={'Sound'} />
        </div>
        <div className="hover:bg-nav -mx-2.5 py-2.5 cursor-pointer">
          <NavBarIconMenu src={SVG.add_note_black} title={'Add Note'} />
        </div>
        <div className="hover:bg-nav -mx-2.5 py-2.5 cursor-pointer">
          <NavBarIconMenu src={SVG.calendar_black} title={'Calendar'} />
        </div>
        <div className="hover:bg-nav -mx-2.5 py-2.5 cursor-pointer">
          <NavBarIconMenu src={SVG.setting_black} title={'Setting'} />
        </div>
        <div className="hover:bg-nav -mx-2.5 py-2.5 cursor-pointer" onClick={signOutFunc}>
          <NavBarIconMenu src={SVG.setting_black} title={'Sign out'} />
        </div>
      </div>
    </div>
  );
}
