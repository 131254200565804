import React from 'react';
import DetailBox from 'components/detail/DetailBox';

export default function OrganizationSummary({ orgData }) {
  const DETAIL_HEADER = [
    {
      detailRows: [
        {
          subTitle: 'Organization',
          className: {
            classNameContent: 'font-semibold '
          },
          content: `${orgData?.orgFullName || ''}`
        }
      ]
    },
    {
      detailRows: [
        {
          subTitle: 'Important',
          importantRow: true,
          content: 'Text - Do not Call',
          className: {
            classNameContent: 'font-bold text-error'
          }
        },
        {
          subTitle: 'Organization Time Zone',
          importantRow: true,
          content: `${orgData?.timeZone || ''}`,
          className: {
            classNameContent: 'text-error'
          }
        }
      ]
    }
  ];

  return (
    <div className="flex justify-between items-center p-0 gap-x-[95px]">
      <div className="flex-1 grid grid-cols-1 md:grid-cols-2">
        {DETAIL_HEADER.map((item) => (
          <div className="col-span-1">
            <DetailBox title={''} detailRows={item.detailRows} />
          </div>
        ))}
      </div>
    </div>
  );
}
