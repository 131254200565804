import { FC, Fragment, memo } from 'react';
import { NavLink } from 'react-router-dom';
import cn from "classnames";

type Props = {
  to: string;
  label: string;
};

const NavBarLink: FC<Props> = ({ to, label }) => {
  return (
    <Fragment>
      <li className="h-12">
        <NavLink
          to={to}
          className={({ isActive }) =>
            cn(
              'h-full px-4 flex items-center text-nav text-xs font-semibold font-inter hover:bg-gray-active',
              isActive ? 'bg-gray-active' : ''
            )
          }
        >
          {label}
        </NavLink>
      </li>
    </Fragment>
  );
};

NavBarLink.displayName = 'Nav.BarLink';

export default memo(NavBarLink);
