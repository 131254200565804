import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { API, Auth } from 'aws-amplify';
import Button from 'components/button';
import Form from 'components/form';
import { useNavigateSearch } from 'hooks';
import { useRegisterStep3Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { createNewCorp, createNewUser } from 'services';
import { IRegisterStep3FormValues } from 'types';
import { Auth as AuthLayout } from '../auth';

const RegisterStep3: FC = () => {
  const navigateSearch = useNavigateSearch();
  const registerForm = JSON.parse(sessionStorage.getItem('registerForm')) || null;

  useEffect(() => {
    if (!registerForm) {
      navigateSearch('/register', { step: '1' });
    }
  }, []);

  const form = useForm<IRegisterStep3FormValues>({
    defaultValues: {
      code: ''
    },
    resolver: FormResolver(useRegisterStep3Schema())
  });

  const onSubmit = async (values: IRegisterStep3FormValues) => {
    try {
      const response = await Auth.confirmSignUp(registerForm?.username, values?.code);
      if (response?.toLowerCase() !== 'success') return;
      console.log('response', response);
      const signInRes = await Auth.signIn(
        registerForm?.username.toLowerCase(),
        registerForm?.password
      );
      console.log('signInRes', signInRes);
      await createNewUser({
        primaryAccountId: registerForm?.attributes?.['custom:superId'].toLowerCase(),
        userName: registerForm?.username.toLowerCase(),
        userRole: registerForm?.attributes?.['custom:userRole'],
        accountStatus: 'REGISTERED',
        corporateName: registerForm?.attributes?.['custom:organization'].toLowerCase(),
        streetAddress: registerForm?.userBillingAddress?.streetAddress,
        addressLine2: registerForm?.userBillingAddress?.streetAddressLine2 || '',
        city: registerForm?.userBillingAddress?.city,
        stateOf: registerForm?.userBillingAddress?.stateOf,
        zipCode: registerForm?.userBillingAddress?.zipCode,
        country: registerForm?.userBillingAddress?.country,
        firstName: registerForm?.attributes?.['custom:firstName'],
        lastName: registerForm?.attributes?.['custom:lastName'],
        email: registerForm?.attributes?.['email'],
        affiliateId: null,
      });
      if (registerForm?.attributes?.['custom:userRole'].toLowerCase() !== 'user_admin') {
        // This deletes the temp user invitation from the unsecure contact db
        // await deleteUserInviteRecord();
      } else if (registerForm?.attributes?.['custom:userRole'].toLowerCase() === 'user_admin') {
        await createNewCorp({
          primaryAccountId: registerForm?.attributes?.['custom:superId'].toLowerCase(),
          corporateName: registerForm?.attributes?.['custom:organization'].toLowerCase(),
          streetAddress: registerForm?.userBillingAddress?.streetAddress,
          addressLine2: registerForm?.userBillingAddress?.streetAddressLine2 || '',
          city: registerForm?.userBillingAddress?.city,
          stateOf: registerForm?.userBillingAddress?.stateOf,
          zipCode: registerForm?.userBillingAddress?.zipCode,
          country: registerForm?.userBillingAddress?.country,
          corpContact: `${registerForm?.attributes?.['custom:firstName']} ${registerForm?.attributes?.['custom:lastName']}`,
          corpEmail: registerForm?.attributes?.['email']
        });
      }
    } catch (err) {
      console.log(err);
    }
    // navigateSearch('/register', { step: '4' });
  };

  return (
    <div className="max-w-[280px] w-full  h-full  flex flex-col mx-auto justify-center items-center ">
      <AuthLayout.Header
        icon={SVG.register_confirm}
        title="Confirm registration!"
        subtitle="Dignissim amet faucibus non diam nisi ridiculus risus eget. Sodales ipsum donec morbi non facilisis. Aliquam morbi est."
        canGoBack
      />

      <FormProvider {...form}>
        <form className="w-full">
          <Form.PhoneInput
            className="mt-[30px] w-full"
            name="code"
            inputProps={{ id: 'code', placeholder: 'Confirmation Code' }}
          />

          <Button.Primary
            className="mt-[30px] py-[12px]"
            label="Confirm"
            onClick={form.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(RegisterStep3);
