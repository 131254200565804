import { ErrorMessage } from '@hookform/error-message';
import { FC, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ISelectOption } from 'types';
import { ObjectUtils } from 'utils';
import { Input } from '../input';

type Props = {
  className?: string;
  name: string;
  label?: string;
  options: ISelectOption[];
  text?: boolean;
  note?: string;
  defaultValue?: ISelectOption;
};

const FormSelectInput: FC<Props> = ({ name, className, note, defaultValue, ...rest }) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: defaultValue?.value || ''
  });

  return (
    <div className={className}>
      <Input.Select
        {...rest}
        error={!ObjectUtils.isEmpty(ObjectUtils.get(formState.errors, name))}
        value={field?.value}
        onChangeValue={field?.onChange}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => <p className="text-error text-xs font-inter my-1">{message}</p>}
      />

      {!ObjectUtils.isEmpty(note) && (
        <p className="w-full text-input text-xs font-inter my-1">{note}</p>
      )}
    </div>
  );
};

FormSelectInput.displayName = 'Form.SelectInput';

export default memo(FormSelectInput);
