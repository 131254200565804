import { SVG } from 'assets/imagePath';
import { FC, Fragment, memo } from 'react';

type Props = {
  onClick?: () => void;
};

const NavBarMore: FC<Props> = ({ onClick }) => {
  return (
    <Fragment>
      <li className="h-12">
        <button className="h-full px-4 flex items-center hover:bg-gray-active" onClick={onClick}>
          <img src={SVG.more_horizontal_white} className="m-auto" alt="more" />
        </button>
      </li>
    </Fragment>
  );
};

NavBarMore.displayName = 'Nav.BarMore';

export default memo(NavBarMore);
