export const fakeColQna = [
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  },
  {
    question: 'Question 1',
    answer: 'Answer 1'
  }
];
