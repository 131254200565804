import { IAppRole, Role } from 'types';

export const CURRENT_ROLE: Role = 'SYSTEM_ADMIN';

export const APP_ROLE: IAppRole = {
  MRG: 'SYSTEM_ADMIN',
  CO: 'CODERS',
  DS: 'DOCS'
};

export const STORAGE = {
  APP_AUTH: 'MEDICAL_AUTH'
};

export const FAKE_USER = [
  {
    email: 'admin@gmail.com',
    password: '123456aA@',
    type: 'SYSTEM_ADMIN',
    shortType: 'MRG',
    full_name: 'Betty MacDonald'
  },
  {
    email: 'docs@gmail.com',
    password: '123456aA@',
    type: 'DOCS',
    shortType: 'DS',
    full_name: 'Mary Charles'
  },
  {
    email: 'coders@gmail.com',
    password: '123456aA@',
    type: 'CODERS',
    shortType: 'CO',
    full_name: 'Julie Medley'
  },
  { email: 'lead@gmail.com', password: '123456aA@', type: 'LEAD' }
];
