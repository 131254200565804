import RootRoutes from 'routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingModal from 'components/loading';

const App = () => {
  return (
    <>
      <RootRoutes />
      <ToastContainer theme="dark"/>
      <LoadingModal />
    </>
  );
};

export default App;
