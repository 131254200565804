import React from 'react';

interface TitleCoverProps {}

function TitleCover({}: TitleCoverProps) {
  return (
    <h1 className="text-white text-4xl mt-8 font-bold font-inter inline-block">
      Physician<span className="text-primary">Smart</span>® Notes
    </h1>
  );
}

export default TitleCover;
