import { SVG } from 'assets/imagePath';
import { Box } from 'components/box';
import { Clinician } from 'components/clinician';
import ClinicianFilter from 'components/clinician/ClinicianFilter';
import CommentTable from 'components/comments/CommentTable';
import Footer from 'components/footer/Footer';
import { HeaderApp } from 'components/header';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { getUserAPI } from 'services';

const ClinicianPage = () => {
  const [fetchClinician, loading, clinicianDetail] = useApiCall(getUserAPI);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const { clinicianId } = useParams();

  useEffect(() => {
    if (!clinicianId || !userInfo) return;
    fetchClinician({ primaryAccountId: userInfo?.['custom:superId'], userRecordId: clinicianId });
  }, [userInfo, clinicianId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading
    })
  }, [loading]);

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: `${[clinicianDetail?.organization]} - Details`,
          addLabel: 'Add Clinician',
          labelDetail: `Clinician Details - ${clinicianDetail?.initials}`
        }}
        onAdd={() => navigate(`/clinician/edit/new`)}
        onEdit={() => navigate(`/clinician/edit/${clinicianId}`)}
      />
      <div className="w-full flex flex-col flex-1 justify-center items-center p-2.5">
        <div className="flex justify-between w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(RouterPath.NOTES)}
          >
            <img className="flex-1" src={SVG.chevron_left} alt="left" />
            <p className="text-xs font-bold text-black">
              {[
                clinicianDetail?.firstName,
                clinicianDetail?.middleName,
                clinicianDetail?.lastName
              ].join(' ') || ''}{' '}
              - {clinicianDetail?.initials || ''}
            </p>
          </div>

          <p className="self-end text-sm text-right">Last Update: 23 hrs – 40 Min Ago</p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <Clinician.Filter />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full flex-1 gap-2.5">
            <Box.Standard className="h-auto py-[15px] px-[60px]">
              <Clinician.Summary clinicianData={clinicianDetail} />
            </Box.Standard>
            <Box.Standard className="h-full py-[30px] px-[60px]">
              <Clinician.Detail clinicianData={clinicianDetail} />
            </Box.Standard>
            <Box.Standard className="h-full p-[15px]">
              <CommentTable />
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default ClinicianPage;
