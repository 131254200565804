export const CONTENT_LIST = [
  {
    title: 'System Filters',
    check_list: [
      {
        label: 'Oldest First'
      },
      {
        label: 'Order of Arrival'
      },
      {
        label: 'Priority'
      },
      {
        label: 'Untouched Records'
      },
      {
        label: 'Touched Records'
      }
    ]
  },
  {
    title: 'Clinician Order',
    check_list: [
      {
        label: 'By Clinician',
        collapse: true
      },
      {
        label: 'By Lacation',
        collapse: true
      },
      {
        label: 'By Site',
        collapse: true
      },
      {
        label: 'By Organization',
        collapse: true
      }
    ]
  },
  {
    title: 'Staff Order',
    check_list: [
      {
        label: 'By Doc Spec'
      }
    ]
  },
  {
    title: 'Doc Specialist',
    check_list: [
      {
        label: 'DS – New'
      },
      {
        label: 'DS – ? Answered'
      },
      {
        label: 'DS – Overdue All'
      },
      {
        label: 'DS – 1 Hr Before'
      },
      {
        label: 'DS – 2 Hr Before'
      },
      {
        label: 'DS – 4 Hr Before'
      },
      {
        label: 'DS – 8 Hr Before'
      }
    ]
  }
];

export const fakeRow = [
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: false,

    important: 'Text - Do Not Call',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    important: '',
    message: 'Need By 3:00 pm'
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: true,
    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: true,

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: false,

    important: 'Instant Service',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: true,

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: false,

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'ANGRY',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    appt: false,
    psQ: 'ANGRY',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',

    psQ: 'SMILE',

    important: '',
    message: ''
  },
  {
    status_table: 'NEW',
    clinician: 'Welby, Marcus Jr.',
    deg: 'MD',
    org: 'WC',
    patiant: 'Langley, Robert L.',
    ds: 'MAC',
    co: 'JLM',
    dur: '2:32',
    psQ: 'SMILE',
    important: '',
    message: ''
  }
];
