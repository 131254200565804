import DetailBox from 'components/detail/DetailBox';

export interface IStaffDetailProps {}

export function StaffDetail(props: IStaffDetailProps) {
  const details = [
    {
      subTitle: 'First Name',
      content: '?'
    },
    {
      subTitle: 'Last Name',
      content: '?'
    },
    {
      subTitle: 'Initials',
      content: '?'
    },
    {
      subTitle: 'Nickname',
      content: '?'
    },
    {
      subTitle: 'Organization',
      content: '?'
    },
    {
      subTitle: 'Email',
      content: '?'
    },
    {
      subTitle: 'Cell phone',
      content: '?'
    },
    {
      subTitle: 'Degree',
      content: '?'
    },
    {
      subTitle: 'Staff Title',
      content: 'CO'
    },
    {
      subTitle: 'Specialty Experience',
      content: '?'
    }
  ];
  const assignments = [
    {
      subTitle: 'Clinician',
      content: '?'
    },
    {
      subTitle: 'Specialty Assignments',
      content: '?'
    },
    {
      subTitle: 'Department Assignments',
      content: '?'
    },
    {
      subTitle: 'Location Assignments',
      content: '?'
    },
    {
      subTitle: 'Organization Assignments',
      content: '?'
    },
    {
      subTitle: 'Primary TR Assignments',
      content: '?'
    },
    {
      subTitle: 'Backup TR Assignments',
      content: '?'
    },
    {
      subTitle: 'General TR Work Assignments',
      content: '?'
    }
  ];

  return (
    <div className="w-full flex gap-x-[30px]">
      <div className="flex flex-col w-full">
        <DetailBox title="Details" detailRows={details} />
      </div>
      <div className="flex flex-col w-full">
        <DetailBox title="Assignments" detailRows={assignments} />
      </div>
    </div>
  );
}
